import { useDispatch, useSelector } from 'react-redux';
import { useCallback } from 'react';
import * as React from 'react';
import reactHtmlParser from 'react-html-parser';
import { Divider } from '@headspace/web-ui-components';
import { distanceInWords, parseDateUtc } from '../../../../../../utils/dates';
import env from '../../../../../../config/env';
import { Input } from '../../../../../../shared-components/Input';
import { Container } from '../../../../../../shared-components/container/Container';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import { isZeroInput } from '../../../../utils/manage-organization';
import {
  reviewInputSubtextStyles,
  contractContainerStyle,
  rowStyle,
} from './styles';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import {
  getEditCurrentContractFormState,
  hasEditCurrentContractFormStateErrors,
  getCurrentContractChangedStatus,
} from '../../../../../../state/selectors/organization/currentContractSelector/currentContractSelector';
import {
  handleChange as handleChangeAction,
  toggleEditPanelView,
  updateImplicitState,
  saveOrganizationContract,
  cancelOrganizationChanges,
} from '../../../../../../state/actions/organizationActions';
import {
  ORGANIZATION_CURRENT_CONTRACT_DETAILS,
  VIEW_PANEL_COUNT,
  CURRENT,
  CONTRACT_STATUS,
  PRIMARY_PRODUCT,
} from '../../../../constants';
import { PrimaryProductInput } from '../shared-components/PrimaryProductInput/PrimaryProductInput';
import { getParentOrgId } from '../../../../../../state/selectors/organizationSelectors';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { useRolloverBugBash } from '../../../../../../featureFlags/rolloverBugBash';
import { DependentsInputGroup } from '../../../../../../shared-components/DependentsInputGroup';
import { AddOnOptionsInput } from '../../../../../../shared-components/AddOnInput/AddOnOptionsInput';
import { Contract } from '../../../../types';
import { saveOrganizationBasic } from '../../../../../../state/actions/organizationActions/saveOrganizationBasic';

const availableVouchers = env.PAYMENT_TYPE_VOUCHERS;
const locale = 'organizationSettings.contract.';

const CurrentContractPanelComponent: React.FC = () => {
  const term = CURRENT;
  const isCSM = useSelector(getIsCSM);
  const { translate } = useTranslate();
  // This could be done by a helper function that formats the formState into a shape that the BE accepts
  const {
    current_contract_clinical_coverage_type,
    current_contract_eligibility_file_based_dependents,
    current_contract_end_date,
    current_contract_end_dateError,
    current_contract_family_members_per_employee,
    current_contract_family_members_per_employeeError,
    current_contract_id,
    current_contract_invite_based_dependents,
    current_contract_is_pilot,
    current_contract_number_of_family_members_seats,
    current_contract_number_of_family_members_seatsError,
    current_contract_opportunity_id,
    current_contract_opportunity_idError,
    current_contract_primary_product,
    current_contract_payment_type,
    current_contract_seats,
    current_contract_seatsError,
    current_contract_start_date,
    current_contract_start_dateError,
    current_contract_status,
    current_contract_voucher,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
  } = useSelector(getEditCurrentContractFormState);

  const hasParentOrgId = !!useSelector(getParentOrgId);
  const isPanelInView = viewPanels[ORGANIZATION_CURRENT_CONTRACT_DETAILS];
  const contract: Contract = {
    clinicalCoverageType: current_contract_clinical_coverage_type,
    elfDependentsEnabled: current_contract_eligibility_file_based_dependents,
    end_date: current_contract_end_date,
    family_members_per_employee: current_contract_family_members_per_employee,
    fusionEnabled: current_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    id: current_contract_id,
    is_pilot: current_contract_is_pilot,
    number_of_family_members_seats: current_contract_number_of_family_members_seats,
    opportunity_id: current_contract_opportunity_id,
    payment_type: current_contract_payment_type,
    primary_product: current_contract_primary_product,
    seats: current_contract_seats,
    start_date: current_contract_start_date,
    term,
    voucher: current_contract_voucher,
  };
  const hasActiveContract = current_contract_status === CONTRACT_STATUS.ACTIVE;
  const isEditable =
    hasActiveContract &&
    isCSM &&
    viewPanels.indexOf(false) < 0 &&
    !hasParentOrgId;

  const didCurrentContractStateChange = useSelector(
    getCurrentContractChangedStatus,
  );
  const hasContractFormErrors = useSelector(
    hasEditCurrentContractFormStateErrors,
  );
  const isRolloverBugBashEnabled = useRolloverBugBash();

  const dispatch = useDispatch();

  const handleToggleView = useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const handleError = useCallback(
    (event, overrideField: string | undefined = undefined) => {
      if (overrideField) {
        event.overrideValidationField = event.overrideValidationField || {};
        event.overrideValidationField[overrideField] = true;
      }
      dispatch(updateImplicitState(event));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (
      event,
      overrideField: string | undefined = undefined,
      dependantInputType = '',
    ) => {
      if (overrideField) {
        event.overrideValidationField = event.overrideValidationField || {};
        event.overrideValidationField[overrideField] = true;
      }
      dispatch(handleChangeAction(event, undefined, dependantInputType));
    },

    [dispatch],
  );

  const handleSaveContract = useCallback(() => {
    dispatch(saveOrganizationContract(contract));
    dispatch(saveOrganizationBasic());
  }, [contract, dispatch]);

  const handleCancel = useCallback(() => {
    dispatch(cancelOrganizationChanges());
  }, [dispatch]);

  return (
    <Container>
      <SettingsPanel
        title={translate(`${locale}${term}Title`)}
        subtitle={
          hasParentOrgId
            ? translate('organizationSettings.orgHierarchy.setByParent')
            : undefined
        }
        view={isPanelInView}
        toggleView={handleToggleView}
        useEditCta={!current_contract_id}
        editCtaLabel={reactHtmlParser(
          translate(`${locale}addContractCta`, { term }),
        )}
        cancelChanges={handleCancel}
        saveChanges={handleSaveContract}
        index={ORGANIZATION_CURRENT_CONTRACT_DETAILS}
        displayEditButton={isEditable}
        settingsChanged={didCurrentContractStateChange}
        hasErrors={hasContractFormErrors}
        dataTestId="organization-current-contract-details"
      >
        {(current_contract_id || !isPanelInView) && (
          <div css={contractContainerStyle}>
            <div css={rowStyle}>
              <PrimaryProductInput
                contractStateTerm={term}
                showReview={isPanelInView}
                onChange={handleChange}
                disabled={true}
              />

              {isCSM && (
                <Input
                  type={isPanelInView ? 'review' : 'text'}
                  data-testid={`${term}_contract_opportunity_id`}
                  name={`${term}_contract_opportunity_id`}
                  placeholder={translate(`${locale}opportunityIdPlaceholder`)}
                  label={translate(`${locale}opportunityIdLabel`)}
                  value={current_contract_opportunity_id}
                  onChange={handleChange}
                  onBlur={handleError}
                  hasError={current_contract_opportunity_idError?.error}
                  errorMessage={current_contract_opportunity_idError?.message}
                  maxLength="18"
                />
              )}
            </div>

            <Divider />
            <div css={rowStyle}>
              <div>
                <Input
                  type={isPanelInView ? 'review' : 'date'}
                  name={`${term}_contract_start_date`}
                  data-testid={`${term}_contract_start_date`}
                  placeholder={translate(`${locale}datePlaceholder`)}
                  label={translate(`${locale}startLabel`)}
                  value={
                    isPanelInView
                      ? `${current_contract_start_date} ( UTC )`
                      : current_contract_start_date
                  }
                  onChange={(e) =>
                    handleChange(e, undefined, `${term}_contract_end_date`)
                  }
                  onBlur={handleError}
                  hasError={current_contract_start_dateError?.error}
                  errorMessage={current_contract_start_dateError?.message}
                  subtext={
                    Date.parse(current_contract_start_date)
                      ? distanceInWords(
                          new Date(),
                          parseDateUtc(current_contract_start_date),
                          true,
                        )
                      : translate('validationErrors.NOT_A_DATE')
                  }
                  customLabelStyles={{ marginTop: '0px' }}
                />
                {!isPanelInView && (
                  <div css={reviewInputSubtextStyles}>
                    Contract start and end date are in UTC
                  </div>
                )}
              </div>
              <div>
                <Input
                  type={isPanelInView ? 'review' : 'date'}
                  name={`${term}_contract_end_date`}
                  placeholder={translate(`${locale}datePlaceholder`)}
                  label={translate(`${locale}endLabel`)}
                  value={
                    isPanelInView
                      ? `${current_contract_end_date} ( UTC )`
                      : current_contract_end_date
                  }
                  onChange={(event) =>
                    handleChange(
                      event,
                      isRolloverBugBashEnabled ? 'endDate' : undefined,
                      `${term}_contract_start_date`,
                    )
                  }
                  onBlur={(event) =>
                    handleError(
                      event,
                      isRolloverBugBashEnabled ? 'endDate' : undefined,
                    )
                  }
                  hasError={current_contract_end_dateError?.error}
                  errorMessage={current_contract_end_dateError?.message}
                  data-testid={`${term}_contract_end_date`}
                  subtext={
                    Date.parse(current_contract_end_date)
                      ? distanceInWords(
                          new Date(),
                          parseDateUtc(current_contract_end_date),
                          true,
                        )
                      : translate('validationErrors.NOT_A_DATE')
                  }
                  customLabelStyles={{ marginTop: '0px' }}
                />
              </div>
            </div>
            <Divider />
            <div css={rowStyle}>
              <Input
                type={isPanelInView ? 'review' : 'number'}
                name={`${term}_contract_seats`}
                placeholder={translate(`${locale}seatsPlaceholder`)}
                label={translate(`${locale}seatsLabel`)}
                value={current_contract_seats}
                onChange={handleChange}
                onBlur={handleError}
                hasError={current_contract_seatsError?.error}
                errorMessage={current_contract_seatsError.message}
                data-testid={`${term}_contract_seats`}
                customLabelStyles={{ marginTop: '0px' }}
              />
              <DependentsInputGroup
                name="dependent-setting"
                inviteBasedDependents={current_contract_invite_based_dependents}
                eligibilityFileBasedDependents={
                  current_contract_eligibility_file_based_dependents
                }
                isViewMode={isPanelInView}
                isDisabled={true}
                contract={term}
                customLabelStyles={{ marginTop: '0px' }}
              />
            </div>
            <div data-testid="family-members-section" css={rowStyle}>
              {!isZeroInput(current_contract_family_members_per_employee) && (
                <Input
                  type="review"
                  name={`${term}_contract_family_members_per_employee`}
                  label={translate(`${locale}familyMembersPerEmployeeLabel`)}
                  value={current_contract_family_members_per_employee}
                  data-testid={`${term}_contract_family_members_per_employee`}
                  hasError={
                    current_contract_family_members_per_employeeError.error
                  }
                  errorMessage={
                    current_contract_family_members_per_employeeError.message
                  }
                />
              )}
              {!isZeroInput(current_contract_family_members_per_employee) && (
                <Input
                  type="review"
                  name={`${term}_contract_number_of_family_members_seats`}
                  label={translate(`${locale}familyMembersSeatsLabel`)}
                  value={current_contract_number_of_family_members_seats}
                  data-testid="contract-number-of-family-members-seats"
                  hasError={
                    current_contract_number_of_family_members_seatsError.error
                  }
                  errorMessage={
                    current_contract_number_of_family_members_seatsError.message
                  }
                />
              )}
            </div>
            <Divider />
            <div css={rowStyle}>
              {isCSM ? (
                <>
                  <Input
                    type={isPanelInView ? 'review' : 'select'}
                    name={`${term}_contract_payment_type`}
                    label={translate(`${locale}paymentLabel`)}
                    value={
                      isPanelInView
                        ? (translate(
                            `${locale}paymentOptions.${current_contract_payment_type}`,
                          ) as string)
                        : current_contract_payment_type
                    }
                    onChange={handleChange}
                    options={[
                      {
                        title: translate(`${locale}paymentOptions.employerPay`),
                        value: '',
                      },
                      {
                        title: translate(`${locale}paymentOptions.partial`),
                        value: 'partial',
                      },
                    ]}
                    customLabelStyles={{ marginTop: '0px' }}
                  />
                </>
              ) : null}
              <Input
                type={isPanelInView ? 'review' : 'checkbox'}
                name={`${term}_contract_is_pilot`}
                data-testid={`${term}_contract_is_pilot`}
                label={translate(`${locale}pilotLabel`)}
                value={
                  isPanelInView
                    ? translate(current_contract_is_pilot ? 'yes' : 'no')
                    : current_contract_is_pilot
                }
                onChange={handleChange}
                customLabelStyles={{ marginTop: '0px' }}
              />
            </div>
            {isCSM && current_contract_payment_type ? (
              <div css={rowStyle}>
                <Input
                  type={isPanelInView ? 'review' : 'select'}
                  name={`${term}_contract_voucher`}
                  label={translate(`${locale}voucherCode`)}
                  value={current_contract_voucher}
                  onChange={handleChange}
                  data-testid="contract_voucher"
                  options={availableVouchers.map((currentVoucher: string) => {
                    return { value: currentVoucher };
                  })}
                />
              </div>
            ) : null}
            <AddOnOptionsInput
              term="current"
              value={current_contract_clinical_coverage_type}
              onChange={handleChange}
              disable={!isCSM || isPanelInView || !contract.fusionEnabled}
            />
          </div>
        )}
      </SettingsPanel>
    </Container>
  );
};

export const CurrentContractPanel = CurrentContractPanelComponent;
