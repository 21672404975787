import { useCallback, useMemo } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@headspace/web-ui-components';
import { Container } from '../../../../../../shared-components/container/Container';
import { Input } from '../../../../../../shared-components/Input';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import {
  VIEW_PANEL_COUNT,
  EAP_SERVICE_TYPES,
  ORGANIZATION_SUPPLEMENTAL_DETAILS,
} from '../../../../constants';
import {
  getOrgManagement,
  getSettingsChangedStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import {
  cancelOrganizationChanges,
  handleChange as handleChangeAction,
  updateImplicitState,
  toggleEditPanelView,
} from '../../../../../../state/actions/organizationActions';
import {
  TranslateFn,
  useTranslate,
} from '../../../../../../hooks/useTranslate';
import {
  CLINICAL_COVERAGE_TYPE,
  CLINICAL_COVERAGE_PROVIDERS,
} from '../../../../../../types/organization';
import {
  contractContainerStyle,
  rowStyle,
} from '../CurrentContractPanel/styles';
import { saveOrganizationBasic } from '../../../../../../state/actions/organizationActions/saveOrganizationBasic';

export const SupplementalPanel: React.FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const {
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    externalOrgId,
    externalOrgIdError,
    phoneNumber,
    phoneNumberError,
    provider,
    serviceType,
    coverageTotal,
    current_contract_clinical_coverage_type,
    current_contract_end_date,
    future_contract_clinical_coverage_type,
    future_contract_start_date,
    parentOrg,
    childOrgs,
  } = useSelector(getOrgManagement);
  const settingsChanged = useSelector(getSettingsChangedStatus);
  const title = translate('organizationSettings.supplementalDetails.title');

  const isParentOrgClinicallyCovered = useMemo((): boolean => {
    if (!parentOrg) return false;
    const { contracts } = parentOrg;
    return contracts.some(
      (contract) =>
        contract.clinicalCoverageType !== CLINICAL_COVERAGE_TYPE.NONE,
    );
  }, [parentOrg]);

  const contractHasClinicalCoverage = useMemo(() => {
    return (
      isParentOrgClinicallyCovered ||
      [
        current_contract_clinical_coverage_type,
        future_contract_clinical_coverage_type,
      ].some((type) => type !== CLINICAL_COVERAGE_TYPE.NONE)
    );
  }, [
    isParentOrgClinicallyCovered,
    current_contract_clinical_coverage_type,
    future_contract_clinical_coverage_type,
  ]);

  const isIntlTherapyCoverage = useMemo(() => {
    const coverageTypes = parentOrg
      ? parentOrg.contracts.map((contract) => contract.clinicalCoverageType)
      : [
          current_contract_clinical_coverage_type,
          future_contract_clinical_coverage_type,
        ];
    return coverageTypes.includes(CLINICAL_COVERAGE_TYPE.INTL_THERAPY);
  }, [
    parentOrg,
    current_contract_clinical_coverage_type,
    future_contract_clinical_coverage_type,
  ]);

  const hasErrors = externalOrgIdError.error || phoneNumberError.error;
  const isPanelInView = viewPanels[ORGANIZATION_SUPPLEMENTAL_DETAILS];
  const suppressValidations = true; // this allows for IMs to do incomplete setup, or add in data over time as it becomes available to them,
  // and to fully control the format of the phone number
  const handleChange = useCallback(
    (e) => dispatch(handleChangeAction(e, suppressValidations)),
    [dispatch, suppressValidations],
  );

  const handleError = useCallback(
    (e) => {
      dispatch(updateImplicitState(e, suppressValidations));
    },
    [dispatch, suppressValidations],
  );

  const handleCancel = useCallback(
    () => dispatch(cancelOrganizationChanges()),
    [dispatch],
  );

  const handleSaveChanges = useCallback(
    () => dispatch(saveOrganizationBasic()),
    [dispatch],
  );

  const handleToggleView = React.useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  const subtitle = getSubtitleKey(
    current_contract_clinical_coverage_type,
    current_contract_end_date,
    future_contract_clinical_coverage_type,
    future_contract_start_date,
    translate,
    contractHasClinicalCoverage,
  );

  const externalOrgIdLabel = translate(
    'organizationSettings.basic.externalOrgIdLabel',
  );
  const externalOrgIdPlaceholder = translate(
    'organizationSettings.basic.externalOrgIdPlaceholder',
  );
  const phoneNumberLabel = translate(
    'organizationSettings.basic.phoneNumberLabel',
  );
  const coverageTotalLabel = translate(
    'organizationSettings.basic.coverageTotalLabel',
  );
  const providerLabel = translate('organizationSettings.basic.provider');
  const serviceTypeLabel = translate('organizationSettings.basic.serviceType');
  const serviceTypeValue = serviceType
    ? translate(
        `organizationSettings.basic.serviceType.${serviceType.toLocaleLowerCase()}`,
      )
    : '';

  const serviceTypeOptions = useMemo(() => {
    const options = [
      {
        title: translate('organizationSettings.basic.serviceType.therapy'),
        value: EAP_SERVICE_TYPES.THERAPY,
      },
    ];
    if (!isIntlTherapyCoverage) {
      options.push(
        {
          title: translate('organizationSettings.basic.serviceType.psychiatry'),
          value: EAP_SERVICE_TYPES.PSYCHIATRY,
        },
        {
          title: translate(
            'organizationSettings.basic.serviceType.psychiatry_therapy',
          ),
          value: EAP_SERVICE_TYPES.PSYCHIATRY_THERAPY,
        },
      );
    }
    return options;
  }, [isIntlTherapyCoverage, translate]);

  const providerOptions = useMemo(() => {
    const options = [
      {
        title: CLINICAL_COVERAGE_PROVIDERS.WPO,
        value: CLINICAL_COVERAGE_PROVIDERS.WPO,
      },
    ];
    if (!isIntlTherapyCoverage) {
      options.push({
        title: CLINICAL_COVERAGE_PROVIDERS.CCA,
        value: CLINICAL_COVERAGE_PROVIDERS.CCA,
      });
    }
    return options;
  }, [isIntlTherapyCoverage]);

  if (
    !contractHasClinicalCoverage ||
    (parentOrg === null && childOrgs.length > 0) ||
    (parentOrg !== null && !isParentOrgClinicallyCovered)
  ) {
    return null;
  }

  return (
    <Container>
      <SettingsPanel
        dataTestId="supplemental-information-panel"
        title={title}
        subtitle={subtitle}
        view={isPanelInView}
        toggleView={handleToggleView}
        cancelChanges={handleCancel}
        saveChanges={handleSaveChanges}
        index={ORGANIZATION_SUPPLEMENTAL_DETAILS}
        displayEditButton={viewPanels.indexOf(false) < 0}
        settingsChanged={settingsChanged}
        hasErrors={hasErrors}
      >
        <div css={contractContainerStyle}>
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'text'}
              name="externalOrgId"
              placeholder={externalOrgIdPlaceholder}
              label={externalOrgIdLabel}
              value={externalOrgId}
              onChange={handleChange}
              onBlur={handleError}
              hasError={externalOrgIdError.error}
              errorMessage={externalOrgIdError.message}
              data-testid="externalOrgId"
            />
            <Input
              type={isPanelInView ? 'review' : 'text'}
              name="phoneNumber"
              placeholder="000-000-0000"
              label={phoneNumberLabel}
              value={phoneNumber}
              onChange={handleChange}
              onBlur={handleError}
              hasError={phoneNumberError.error}
              errorMessage={phoneNumberError.message}
              data-testid="phoneNumber"
            />
          </div>
          <Divider />
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'select'}
              name="serviceType"
              label={serviceTypeLabel}
              value={isPanelInView ? serviceTypeValue : serviceType}
              onChange={handleChange}
              data-testid="serviceType"
              options={serviceTypeOptions}
            />
            <Input
              type={isPanelInView ? 'review' : 'select'}
              name="provider"
              label={providerLabel}
              value={provider}
              onChange={handleChange}
              data-testid="provider"
              options={providerOptions}
            />
          </div>
          <Divider />
          <div css={rowStyle}>
            <Input
              type={isPanelInView ? 'review' : 'number'}
              name="coverageTotal"
              placeholder="0"
              label={coverageTotalLabel}
              value={coverageTotal}
              onChange={handleChange}
              onBlur={handleError}
              data-testid="coverageTotal"
            />
          </div>
        </div>
      </SettingsPanel>
    </Container>
  );
};

const getSubtitleKey = (
  currentContractClinicalCoverageType: CLINICAL_COVERAGE_TYPE,
  currentContractEndDate: string,
  futureContractClinicalCoverageType: CLINICAL_COVERAGE_TYPE,
  futureContractStartDate: string,
  translate: TranslateFn,
  contractHasClinicalCoverage,
): string => {
  if (contractHasClinicalCoverage) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.default',
    );
  }
  if (
    currentContractClinicalCoverageType !== CLINICAL_COVERAGE_TYPE.NONE &&
    (!futureContractClinicalCoverageType ||
      futureContractClinicalCoverageType === CLINICAL_COVERAGE_TYPE.NONE)
  ) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.current',
      { currentContractEndDate },
    );
  }
  if (
    (!currentContractClinicalCoverageType ||
      currentContractClinicalCoverageType === CLINICAL_COVERAGE_TYPE.NONE) &&
    futureContractClinicalCoverageType !== CLINICAL_COVERAGE_TYPE.NONE
  ) {
    return translate(
      'organizationSettings.supplementalDetails.eap.subtitle.future',
      { futureContractStartDate },
    );
  }

  return '';
};
