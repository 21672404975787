import { useCallback, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { css } from '@emotion/react';
import { SpacingInRemBySize, White } from '@headspace/web-ui-components';
import {
  Dropdown,
  DropdownItem,
} from '../../../shared-components/dropdowns/Dropdown';
import { MainHeader } from '../../../shared-components/MainHeader';
import { userLogout } from '../../../state/actions/userActions';
import { useHsOktaSSO } from '../../../featureFlags/useHsOktaSSO';
import { useTranslate } from '../../../hooks/useTranslate';
import {
  isCSM as getIsCSM,
  getInfo,
} from '../../../state/selectors/userSelectors';

const headerStyle = css({
  background: White['000'],
  padding: `0 ${SpacingInRemBySize.L}`,
});

export const Header: FC = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const isCSM = useSelector(getIsCSM);
  const { name, orgIds } = useSelector(getInfo);
  const isHSOktaEnabled = useHsOktaSSO();
  const logout = useCallback(() => {
    dispatch(userLogout(isHSOktaEnabled));
  }, [dispatch, isHSOktaEnabled]);

  const middleLinks = isCSM
    ? [{ name: translate('organizations.title'), url: '/orgs' }]
    : orgIds && orgIds.length > 1
    ? [{ name: translate('organizations.title'), url: '/admin/orgs/' }]
    : [];

  return (
    <header css={headerStyle}>
      <MainHeader
        middleLinks={middleLinks}
        renderDropdown={() => (
          <Dropdown position="right" text={name}>
            <DropdownItem onClick={logout}>
              {translate('dashboardHeader.signOut')}
            </DropdownItem>
          </Dropdown>
        )}
        logoAriaLabel={translate('dashboardHeader.logoAriaLabel')}
      />
    </header>
  );
};
