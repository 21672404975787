import { useGateValue } from '@statsig/react-bindings';
import { StatsigClient } from '@statsig/js-client';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/hs-upload-child-elf
// Feature flag to enable elf upload from child org
const HS_UPLOAD_CHILD_ELF = 'hs-upload-child-elf';

export const useHsUploadChildElf = () => useGateValue(HS_UPLOAD_CHILD_ELF);

/**
 *  Since we can't use hooks in functions, this approach is needed to check the
 *  feature flag in from a ts/js function.
 *  https://reactjs.org/docs/hooks-rules.html#only-call-hooks-from-react-functions
 */
export const checkIsHsUploadChildElf = () =>
  StatsigClient.instance().checkGate(HS_UPLOAD_CHILD_ELF);
