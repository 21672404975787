import { FC, useCallback, useState } from 'react';

import {
  Green,
  Orange,
  Purple,
  Red,
  Tag,
  White,
} from '@headspace/web-ui-components';
import { BasicTableCell } from '../../../../../../../shared-components/tables/BasicTable/BasicTableCell';
import { BasicTableRow } from '../../../../../../../shared-components/tables/BasicTable/BasicTableRow';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { makeFriendlyDate } from '../../../../../../../utils/dates';
import { ReportModal } from './ReportModal';
import { HollowButton } from '../../../../../../../shared-components/buttons/HollowButton';
import {
  EligibilityFile,
  EligibilityFileStatus,
} from '../../../../../../../types/organization';
import { eligibilityCellStyle, eligibilityFilenameCellStyle } from '../styles';
import { EligibilityFileTag } from './EligibilityFileTag';

interface EligibilityFileRowProps {
  eligibilityFile: EligibilityFile;
}

const statusColorMap = {
  [EligibilityFileStatus.CANCELLED]: Orange['200'],
  [EligibilityFileStatus.FAILED]: Red['300'],
  [EligibilityFileStatus.PROCESSED]: Green['200'],
  [EligibilityFileStatus.PROCESSING]: Green['100'],
  [EligibilityFileStatus.UPLOADED]: Green['100'],
  [EligibilityFileStatus.VALIDATING]: Green['100'],
  [EligibilityFileStatus.VALIDATION_FAILED]: Red['300'],
};

export const EligibiltyFileRow: FC<EligibilityFileRowProps> = ({
  eligibilityFile,
}) => {
  const { translate } = useTranslate();
  const [showModal, setShowModal] = useState(false);
  const {
    createdAt,
    errors,
    filename,
    status,
    errorCsvUrl,
    totalAdded,
    totalProcessed,
    totalRemoved,
    dryRun,
  } = eligibilityFile;
  const dateCreated = makeFriendlyDate(createdAt);
  // errors are encoded with a `<br>` to indicate a newline
  const errorList: string[] = errors?.length ? errors?.split('<br>') : [];

  const openModal = useCallback(() => {
    setShowModal(true);
  }, [setShowModal]);
  const closeModal = useCallback(() => {
    setShowModal(false);
  }, [setShowModal]);

  return (
    <>
      <BasicTableRow key={createdAt}>
        <BasicTableCell key="status" cellStyle={eligibilityCellStyle}>
          <EligibilityFileTag
            color={statusColorMap[status]}
            copy={translate(
              `organizationSettings.eligibility.processingStatus.${status}`,
            )}
          />
        </BasicTableCell>
        <BasicTableCell key="test" cellStyle={eligibilityCellStyle}>
          {dryRun ? (
            <EligibilityFileTag
              color={Purple['200']}
              copy={translate(`organizationSettings.eligibility.test`)}
            />
          ) : (
            <EligibilityFileTag
              color={Green['200']}
              copy={translate(`organizationSettings.eligibility.production`)}
            />
          )}
        </BasicTableCell>
        <BasicTableCell key="filename" cellStyle={eligibilityFilenameCellStyle}>
          {filename}
        </BasicTableCell>
        <BasicTableCell key="date" cellStyle={eligibilityCellStyle}>
          {dateCreated}
        </BasicTableCell>
        <BasicTableCell key="report" cellStyle={eligibilityCellStyle}>
          <HollowButton
            dataTestId={`view-report-${createdAt}`}
            onClick={openModal}
          >
            {translate('organizationSettings.eligibility.viewReport')}
          </HollowButton>
        </BasicTableCell>
      </BasicTableRow>
      {showModal ? (
        <ReportModal
          handleClose={closeModal}
          status={status}
          errors={errorList}
          errorCsvUrl={errorCsvUrl}
          totalAdded={totalAdded}
          totalRemoved={totalRemoved}
          totalProcessed={totalProcessed}
        />
      ) : null}
    </>
  );
};
