import { css, SerializedStyles } from '@emotion/react';
import * as React from 'react';
import { SpacingInRemBySize } from '@headspace/web-ui-components';

const tableCellStyle = css({
  ' *': {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    wordBreak: 'break-word',
  },
  ':last-of-type': {
    marginRight: 0,
  },
  alignItems: 'center',
  display: 'flex',
  flexBasis: 0,
  flexDirection: 'row',
  flexGrow: 1,
  justifyContent: 'space-between',
  marginRight: SpacingInRemBySize.XL,
  minWidth: 0,
});

export interface BasicTableCellProps {
  children?: React.ReactNode;
  className?: string;
  rest?: string;
  dataTestId?: string;
  cellStyle?: SerializedStyles;
}

export const BasicTableCell: React.FC<BasicTableCellProps> = ({
  children,
  className,
  dataTestId,
  cellStyle,
  ...rest
}) => {
  return (
    <div
      className={`${className} basicTableCell`}
      css={css(tableCellStyle, cellStyle)}
      data-testId={dataTestId}
    >
      <div {...rest}>{children}</div>
    </div>
  );
};
