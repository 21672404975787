import { StatsigClient } from '@statsig/js-client';

// https://console.statsig.com/nnIvOXIzR6I6Dz4VUPfDI/gates/b2b_dashboard_fusion_scheduler_eligibility
// Feature flag to enable age and country validation for Coaching as part of Fusion Project
const KEY = 'b2b_dashboard_fusion_scheduler_eligibility';

/**
 *  Since we can't use hooks in functions, this approach is needed to check the
 *  feature flag in from a ts/js function.
 *  https://reactjs.org/docs/hooks-rules.html#only-call-hooks-from-react-functions
 */
export const checkFusionCoachingEligibility = () =>
  StatsigClient.instance().checkGate(KEY);
