import { css } from '@emotion/react';
import { Tag, White, BrandColor } from '@headspace/web-ui-components';
import { FC } from 'react';

// Fixing some weird height issues
const wrapperCss = css({
  '> div': { height: 'auto' },
  display: 'flex',
  alignItems: 'center',
});

interface EligibilityFileTagProps {
  color: BrandColor;
  copy: string;
}

export const EligibilityFileTag: FC<EligibilityFileTagProps> = ({
  color,
  copy,
}) => (
  <div css={wrapperCss}>
    <Tag backgroundColor={color} fontColor={White['000']}>
      {copy}
    </Tag>
  </div>
);
