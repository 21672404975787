import { Subscription } from '@headspace/web-payments';
import {
  OrganizationActionsTypes,
  HydrateAction,
} from '../../types/organization';
import { resetError, resetProcessing } from '../../../utils/messageDefaults';
import { VIEW_PANEL_COUNT } from '../../../apps/dashboard/constants';
import {
  futureContractElfBasedDependents,
  futureContractInviteBasedDependents,
  isZeroInput,
} from '../../../apps/dashboard/utils/manage-organization';
import { makeFriendlyDate } from '../../../utils/dates';
import { handleEmptyArray, handleNullString } from '../../helpers';
import {
  organizationOfferingTypesToPrimaryProduct,
  parseOrganizationContractDependentValuesBasedOnInputType,
} from '../../utils/dataParsers';
import {
  CLINICAL_COVERAGE_TYPE,
  OfferingType,
  Organization,
  SubscriptionStatus,
} from '../../../types/organization';
import { CountryCodes } from '../../../constants/country';
import { translate } from '../../../i18n/utils/translate';

const defaultContract = {
  clinicalCoverageType: CLINICAL_COVERAGE_TYPE.NONE,
  dependentSeats: '',
  dependentsPerMember: '0',
  elfDependentsEnabled: false,
  endDate: '',
  fusionEnabled: false,
  id: '',
  isPilot: false,
  offerings: [OfferingType.HEADSPACE],
  opportunityId: '',
  partialPayVoucher: '',
  seats: '',
  startDate: '',
  status: '',
};

export class Contract {
  id?: string;

  opportunityId?: string;

  seats?: string;

  startDate?: string;

  endDate?: string;

  isPilot?: boolean;

  partialPayVoucher?: string;

  status: string;

  dependentsPerMember: string;

  dependentSeats: string;

  elfDependentsEnabled: boolean;

  offerings: OfferingType[];

  /**
   * @deprecated - Use primaryProduct instead
   */
  fusionEnabled: boolean;

  clinicalCoverageType?: CLINICAL_COVERAGE_TYPE;

  constructor({
    id,
    opportunityId,
    seats,
    startDate,
    endDate,
    fusionEnabled,
    isPilot,
    partialPayVoucher,
    status,
    dependentsPerMember,
    dependentSeats,
    elfDependentsEnabled,
    offerings,
    clinicalCoverageType,
  } = defaultContract) {
    this.id = id || '';
    this.opportunityId = handleNullString(opportunityId) || '';
    this.seats = seats || '';
    this.startDate = makeFriendlyDate(startDate) || '';
    this.endDate = makeFriendlyDate(endDate) || '';
    this.isPilot = isPilot || false;
    this.partialPayVoucher = handleNullString(partialPayVoucher) || '';
    this.status = status;
    this.clinicalCoverageType = clinicalCoverageType;

    const dependentValues = parseOrganizationContractDependentValuesBasedOnInputType(
      dependentsPerMember,
      dependentSeats,
    );
    this.dependentsPerMember = dependentValues.dependentsPerMember;
    this.dependentSeats = dependentValues.dependentSeats;
    this.elfDependentsEnabled = elfDependentsEnabled;
    this.offerings = offerings || [OfferingType.HEADSPACE];
    this.fusionEnabled = fusionEnabled;
  }
}

type OrgUndefinedInitialStateProps =
  | 'gingerBundlingEnabled'
  | 'gingerId'
  | 'HeadspaceHealtId'
  | 'hasSeats';

/**
 * hydrate is used to initialize the the organization state for the Organization Settings page.
 * If you are looking to change and default state values you should update the values here.
 */
export const hydrate = (
  org: Omit<Organization, OrgUndefinedInitialStateProps> &
    Partial<Pick<Organization, OrgUndefinedInitialStateProps>>,
): HydrateAction => {
  const { contracts } = org;
  let currentContract: Contract;
  const activeContract = contracts.find(
    (contract: Subscription) => contract.status === SubscriptionStatus.ACTIVE,
  );
  if (activeContract) {
    currentContract = new Contract(activeContract);
  } else {
    currentContract = new Contract();
  }

  let futureContract: Contract;
  const inactiveContract = contracts.find(
    (contract: Subscription) => contract.status === SubscriptionStatus.INACTIVE,
  );
  if (inactiveContract) {
    futureContract = new Contract(inactiveContract);
  } else if (activeContract) {
    // default the startDate of the future contract to the endDate of the active contract
    futureContract = new Contract({
      ...defaultContract,
      startDate: activeContract.endDate,
    });
  } else {
    futureContract = new Contract();
  }

  const orgCountry = CountryCodes.includes(org.country)
    ? org.country
    : undefined;

  return {
    payload: {
      add_domainError: resetError(),
      adminReviewView: org.adminReviewView || true,
      admin_emailError: resetError(),
      admin_nameError: resetError(),
      admin_titleError: resetError(),
      body: handleNullString(org.enrollment.body),
      bodyError: resetError(),
      cachedOrg: { ...org, currentContract, futureContract },
      campaignDisplayName: handleNullString(org.enrollment.campaignDisplayName),
      campaignDisplayNameError: resetError(),
      campaignLaunchDate: handleNullString(org.enrollment.campaignLaunchDate),
      campaignLaunchDateError: resetError(),
      childOrgs: org.childOrgs || [],
      coverageTotal: org.coverageTotal ?? 0,
      coverageTotalError: resetError(),
      csvDelimiter: org.csvDelimiter,
      current_contract_clinical_coverage_type:
        currentContract.clinicalCoverageType,
      current_contract_eligibility_file_based_dependents:
        currentContract.elfDependentsEnabled,
      current_contract_end_date: currentContract.endDate,
      current_contract_end_dateError: resetError(),
      current_contract_family_members_per_employee:
        currentContract.dependentsPerMember,
      current_contract_family_members_per_employeeError: resetError(),
      current_contract_id: currentContract.id,
      current_contract_invite_based_dependents: !isZeroInput(
        currentContract.dependentSeats,
      ),
      current_contract_is_pilot: currentContract.isPilot,
      current_contract_number_of_family_members_seats:
        currentContract.dependentSeats,
      current_contract_number_of_family_members_seatsError: resetError(),
      current_contract_opportunity_id: currentContract.opportunityId,
      current_contract_opportunity_idError: resetError(),
      current_contract_payment_type: currentContract.partialPayVoucher
        ? 'partial'
        : '',
      current_contract_primary_product: organizationOfferingTypesToPrimaryProduct(
        currentContract.offerings,
        currentContract.fusionEnabled,
      ),
      current_contract_seats: currentContract.seats,
      current_contract_seatsError: resetError(),
      current_contract_start_date: currentContract.startDate,
      current_contract_start_dateError: resetError(),
      current_contract_status: currentContract.status,
      current_contract_voucher: currentContract.partialPayVoucher,
      dependentEnrollmentFlowDisplayName:
        org.enrollment.dependentEnrollmentFlowDisplayName,
      eligibilityFile: null,
      eligibilityFileError: resetError(),
      eligibilityFileFormatType: org.eligibilityFileFormatType,
      eligibilityFileName: '',
      eligibilityFileProcessingMessage: resetProcessing(),
      eligibilityFiles: handleEmptyArray(org.elFiles || []),
      externalOrgId: org.externalOrgId,
      externalOrgIdError: resetError(),
      future_contract_clinical_coverage_type:
        futureContract.clinicalCoverageType,
      future_contract_eligibility_file_based_dependents: futureContractElfBasedDependents(
        inactiveContract,
        currentContract,
      ),
      future_contract_end_date: futureContract.endDate,
      future_contract_end_dateError: resetError(),
      future_contract_family_members_per_employee:
        futureContract.dependentsPerMember,
      future_contract_id: futureContract.id,
      future_contract_invite_based_dependents: futureContractInviteBasedDependents(
        inactiveContract,
        currentContract,
      ),
      future_contract_is_pilot: futureContract.isPilot,
      future_contract_number_of_family_members_seats:
        futureContract.dependentSeats,
      future_contract_number_of_family_members_seatsError: resetError(),
      future_contract_opportunity_id: futureContract.opportunityId,
      future_contract_opportunity_idError: resetError(),
      future_contract_payment_type: futureContract.partialPayVoucher
        ? 'partial'
        : '',
      future_contract_primary_product: futureContract.id
        ? organizationOfferingTypesToPrimaryProduct(
            futureContract.offerings,
            futureContract.fusionEnabled,
          )
        : '',
      future_contract_primary_productErrors: resetError(),
      future_contract_seats: futureContract.seats,
      future_contract_seatsError: resetError(),
      future_contract_start_date: futureContract.startDate,
      future_contract_start_dateError: resetError(),
      future_contract_status: futureContract.status,
      future_contract_voucher: futureContract.partialPayVoucher,
      gingerBundlingEnabled: org.gingerBundlingEnabled,
      gingerId: org.gingerId,
      headline: handleNullString(org.enrollment.headline),
      headlineError: resetError(),
      headspaceHealthId: org.headspaceHealthId,
      healthHub: org.healthHub,
      healthHubExternalId: org.healthHubExternalId,
      learn_more_url_path: handleNullString(org.enrollment.learnMoreUrl),
      learn_more_url_pathError: resetError(),
      logo: handleNullString(org.logo),
      logoFile: null,
      logoFileError: resetError(),
      logoFileName: '',
      logoFilePreview: '',
      memberSsoEnabled: org.isMemberSsoEnabled,
      mfaEnabled: org.mfaEnabled,
      no_promo_emails: org.noPromoEmails || false,
      orgCountry,
      orgCountrySearch:
        org.orgCountrySearch ?? orgCountry
          ? translate(`country.${org.country}`)
          : '',
      orgId: org.id,
      orgMappingKey: org.orgMappingKey || null,
      orgMappingValue: org.orgMappingValue || null,
      orgMappingValueError: resetError(),
      org_admins: org.admins,
      org_name: handleNullString(org.name),
      org_nameError: resetError(),
      org_salesforce_id: handleNullString(org.salesforceId),
      org_salesforce_idError: resetError(),
      org_type: handleNullString(org.type) || 'B2B',
      overflow_url: handleNullString(org.enrollment.overflowUrl),
      overflow_urlError: resetError(),
      overlappedCareId: org.overlappedCareId ?? null,
      parentOrg: org.parentOrg || null,
      parentOrgId: org.parentOrgId || null,
      parentOrgIdError: resetError(),
      parentOrgName: org.parentOrgName || null,
      phoneNumber: org.phoneNumber,
      phoneNumberError: resetError(),
      pii_enrollment_enabled: org.dependentsPiiEnrollmentEnabled,
      provider: org.provider,
      restrictBy: handleNullString(org.enrollment.restrictBy),
      restrictedDomains: handleEmptyArray(org.enrollment.restrictedDomains),
      rootOrgId: org.rootOrgId || null,
      serviceType: org.serviceType,
      slug: org.enrollment.slug,
      slugError: resetError(),
      sso_enabled: org.ssoEnabled || false,
      uploadElfAtChildEnabled: org.uploadElfAtChildEnabled,
      useSingleEnrollmentLink: org.useSingleEnrollmentLink,
      viewPanels: new Array(VIEW_PANEL_COUNT).fill(true),
      workIdFieldPlaceholderText: handleNullString(
        org.enrollment.workIdFieldPlaceholderText,
      ),
      workIdFieldPlaceholderTextError: resetError(),
    },
    type: OrganizationActionsTypes.HYDRATE,
  };
};
