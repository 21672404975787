export enum CLINICAL_COVERAGE_PROVIDERS {
  WPO = 'WPO',
  CCA = 'CCA',
}

export enum CLINICAL_COVERAGE_TYPE {
  NONE = 'NONE',
  EAP_V2 = 'EAP_2.0',
  INTL_THERAPY = 'INTL_THERAPY',
}

export interface OrgMemberSummaryResponse {
  firstName: string | null;
  lastName: string | null;
  organization: {
    name: string;
    logo: string | null;
    country: string | null;
  };
  clinicalCoverage: {
    clinicalCoverageType: CLINICAL_COVERAGE_TYPE;
    provider: CLINICAL_COVERAGE_PROVIDERS;
    phoneNumber: string;
  } | null;
}
export interface ClinicalIntakeRequestResponse {
  provider: CLINICAL_COVERAGE_PROVIDERS;
  createdAt: Date;
  updatedAt: Date;
  deletedAt: Date | null;
}
