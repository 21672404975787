import React, { useEffect, useState, useCallback, useRef } from 'react';
import { connect, useSelector } from 'react-redux';
import {
  Route,
  useLocation,
  useNavigate,
  Navigate,
  Routes,
} from 'react-router-dom';
import { Orange } from '@headspace/web-ui-components';
import queryString from 'query-string';
import AuthClass, { AUTH_REDIRECT_PARAMS, parseHash } from '../../../auth/Auth';
import * as UserActions from '../../../state/actions/userActions';
import { Panel } from '../../../shared-components/Panel';
import { Icon } from '../../../shared-components/Icon';
import { ResetPasswordForm } from './ResetPasswordForm';
import { LoginForm } from './LoginForm';
import * as Validations from '../../../utils/validations';
import * as UserSelectors from '../../../state/selectors/userSelectors';
import { RootState, ThunkDispatch } from '../../../state/types';
import {
  LoginOwnProps,
  LoginProps,
  LoginReduxDispatchProps,
  LoginReduxStateProps,
} from './constants';
import { centerColumn, flex1, marginBottomXlarge, panelStyles } from './styles';
import { getConnection } from './utils';
import {
  alignItemsCenter,
  columnFlex,
  justifyContentCenter,
} from '../styles/flex';
import { Loading, LoginHeader } from './LoginComponents';
import { DashboardPathname } from '../../../utils/constants';
import { useHsOktaSSO } from '../../../featureFlags/useHsOktaSSO';
import { IMG_CDN_URL_LOGO } from '../constants';

const validations = {
  email: [Validations.isNotBlank, Validations.isEmail],
  password: [Validations.isNotBlank, Validations.isDecentPassword],
};

/**
 * @deprecated
 * @christopher.sancho@headspace.com
 * TODO: Remove Login component. Moving to universal login.
 * Remove after auth0-spa-js is deployed and integration tests are updated.
 */
export const LoginComponent: React.FC<LoginProps> = ({
  authorize,
  globalError,
  loggingOut,
  login,
  loginV2,
  logout,
  oauthCallback,
  processUser,
  processUserV2,
  processIDPUser,
  resetLoginError,
  setLoginError,
  user,
}) => {
  const authRef = useRef<AuthClass>(new AuthClass());
  const [email, setEmail] = useState('');
  const [emailValidated, setEmailValidated] = useState(false);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState('');
  const [resetSuccess, setResetSuccess] = useState(false);
  const isHSOktaSSOEnabled = useHsOktaSSO();
  const { search } = useLocation();
  const navigate = useNavigate();
  const isLoggingIn = useSelector(UserSelectors.isLoggingIn);
  const globalErrorMessage = Validations.getErrorMesssage(
    globalError && [globalError],
  );

  // hanlde initial mount for un-authenticated users
  useEffect(() => {
    if (isHSOktaSSOEnabled && !isLoggingIn && !oauthCallback) {
      loginV2();
    }
  }, [isHSOktaSSOEnabled, isLoggingIn, oauthCallback, loginV2]);

  // hanlde universal login on mount
  useEffect(() => {
    const {
      code: auth0RedirectCode,
      error: auth0RedirectError,
      state: auth0RedirectState,
    } = queryString.parse(search);
    // not including home realm tile logins
    const successFullAuth0Login =
      auth0RedirectCode && auth0RedirectState && !auth0RedirectError;
    // skip if feature flag is false(hs okta signin is disabled)

    if (isHSOktaSSOEnabled && successFullAuth0Login) {
      processUserV2();
    }

    if (auth0RedirectError || globalErrorMessage === 'access_denied') {
      logout(isHSOktaSSOEnabled);
    }
  }, [
    isHSOktaSSOEnabled,
    setLoginError,
    processUser,
    search,
    processUserV2,
    globalErrorMessage,
    logout,
  ]);

  // handle pre-univeral login on mount
  useEffect(() => {
    const { access_token, error } = parseHash();
    const loginError = error;

    if (loginError && !isHSOktaSSOEnabled) {
      setLoginError(new Error(loginError));
    }
    if (access_token && !loginError && !isHSOktaSSOEnabled) {
      processUser(access_token);
    }
  }, [isHSOktaSSOEnabled, setLoginError, processUser]);

  // handle idp login & univeral login errors
  useEffect(() => {
    const {
      code: auth0RedirectCode,
      connection: auth0RedirectConnection,
      state: auth0RedirectState,
    } = queryString.parse(search);
    const successFulIdpLogin =
      auth0RedirectCode && !auth0RedirectState && auth0RedirectConnection;

    if (successFulIdpLogin && !user?.hasFetchedUser && !user.error) {
      processIDPUser(auth0RedirectConnection);
    }
  }, [
    search,
    globalErrorMessage,
    isHSOktaSSOEnabled,
    user.hasFetchedUser,
    user.error,
    processUserV2,
    processIDPUser,
    user,
    logout,
  ]);

  // handlers
  const onLoginSubmit = useCallback(
    (e: React.KeyboardEvent | React.MouseEvent) => {
      e.preventDefault();
      const connection = getConnection(email);
      resetLoginError();
      if (emailValidated) {
        login(email, password);
      }
      // there was an error here for checking just the truthiness of "connection"
      // needs more investigation. because getConnection(email) defaults to returning an empty string
      else if (connection !== '') {
        authorize(connection);
      } else {
        setEmailValidated(true);
        setErrors({});
      }
    },
    [authorize, email, emailValidated, login, password, resetLoginError],
  );

  const onEditEmail = useCallback(() => {
    resetLoginError();
    setEmailValidated(false);
    setErrors({});
  }, [resetLoginError]);
  const onResetSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      Validations.validateAll(
        { connect, email, emailValidated, errors, password, resetSuccess },
        { email: validations.email },
      )
        .then((errorResp: Validations.ErrorResp) => {
          if (Validations.hasErrors(errorResp)) {
            setErrors(errorResp);
            return false;
          }
          return authRef.current.resetPassword(email);
        })
        .then((success): void => {
          if (success) {
            setResetSuccess(true);
          }
        })
        .catch((err) => {
          // set global error
          const errorMessage = err.error_description || err.message;
          setErrors({ message: [new Error(errorMessage)] });
        });
    },
    [email, emailValidated, errors, password, resetSuccess],
  );

  useEffect(() => {
    const validate = async () => {
      const errorResp = await Validations.validateAll(
        {
          connect,
          email,
          emailValidated,
          password,
          resetSuccess,
        },
        validations,
      );

      setErrors(errorResp);
    };

    validate().catch((error: any) => {
      const errorMessage = error.error_description || error.message;
      setErrors(new Error(errorMessage));
    });
  }, [email, emailValidated, password, resetSuccess]);
  const onChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    const {
      target: { value, name },
    } = e;
    if (name === 'email') {
      setEmail(value);
    } else if (name === 'password') {
      setPassword(value);
    }
  }, []);
  const transitionToLogin = useCallback(() => {
    setErrors({});
    setResetSuccess(false);
    navigate(DashboardPathname.LOGIN);
  }, [navigate]);

  const transitionToLoginReset = useCallback(() => {
    setErrors({});
    setResetSuccess(false);
    navigate(DashboardPathname.LOGIN_RESET);
  }, [navigate]);

  if (loggingOut) {
    logout(isHSOktaSSOEnabled);
    return <Navigate to={DashboardPathname.LOGIN} replace={true} />;
  }

  const { error } = parseHash();
  if (error) {
    setLoginError(new Error(error));
    return <Navigate to={DashboardPathname.LOGIN} replace={true} />;
  }

  // User is logged in and user info were fetched
  if (user?.hasFetchedUser) {
    const {
      isCSM,
      info: { orgIds },
    } = user;

    const params = new URLSearchParams(window.location.search);
    const redirectURLCallback =
      params.get(AUTH_REDIRECT_PARAMS.REDIRECT_URL_CALLBACK) ?? '';
    const callbackURL = decodeURIComponent(redirectURLCallback);
    const updatedCallbackURL = callbackURL ? new URL(callbackURL) : null;

    if (updatedCallbackURL) {
      return <Navigate to={updatedCallbackURL.pathname} replace={true} />;
    }

    if (orgIds && orgIds.length > 1) {
      return <Navigate to={DashboardPathname.ADMIN_ORG_LIST} replace={true} />;
    }

    if (isCSM) {
      return <Navigate to={DashboardPathname.ORG_LIST} replace={true} />;
    }

    const [id] = orgIds || [];

    if (id) {
      return <Navigate to={`/orgs/${id}/members`} replace={true} />;
    }
    return <Navigate to={DashboardPathname.ADMIN_ORG_LIST} replace={true} />;
  }

  // If SSO is enabled only show a loading screen
  if (isHSOktaSSOEnabled) {
    return <Loading />;
  }
  // OAuth redirect, logging the user in
  if (oauthCallback) {
    if (!user.error) {
      return <Loading />;
    }
    return <Navigate to={DashboardPathname.LOGIN} replace={true} />;
  }

  return (
    <div css={[columnFlex, flex1, justifyContentCenter]}>
      <div css={[columnFlex, alignItemsCenter]}>
        <div css={marginBottomXlarge}>
          <Icon
            css={marginBottomXlarge}
            width={340}
            height={90}
            fill={Orange[200]}
            src={`${IMG_CDN_URL_LOGO}/new-logo.svg`}
            dataTestid="login-logo"
          />
        </div>
        <Routes>
          <Route path={DashboardPathname.LOGIN_RESET} element={null} />
          <Route path="*" element={<LoginHeader />} />
        </Routes>

        <Panel css={[panelStyles, centerColumn]}>
          <Routes>
            <Route
              path={DashboardPathname.LOGIN_RESET}
              element={
                <ResetPasswordForm
                  errors={errors}
                  email={email}
                  resetSuccess={resetSuccess}
                  onChange={onChange}
                  onSubmit={onResetSubmit}
                  onBackToLogin={transitionToLogin}
                />
              }
            />
            <Route
              path="*"
              element={
                <LoginForm
                  errors={errors}
                  email={email}
                  password={password}
                  globalError={globalError}
                  emailValidated={emailValidated}
                  onChange={onChange}
                  onSubmit={onLoginSubmit}
                  onResetPassword={transitionToLoginReset}
                  onEditEmail={onEditEmail}
                />
              }
            />
          </Routes>
        </Panel>
      </div>
    </div>
  );
};

export const mapStateToProps = (state: RootState): LoginReduxStateProps => ({
  globalError: UserSelectors.getError(state),
  user: state.user,
});

export const mapDispatchToProps = (
  dispatch: ThunkDispatch,
): LoginReduxDispatchProps => ({
  authorize: (connection: string) => {
    dispatch(UserActions.userAuthorize(connection));
  },
  login: (email: string, password: string) =>
    dispatch(UserActions.userLogin(email, password)),
  loginV2: () => dispatch(UserActions.userLoginV2()),
  logout: (featureFlag) => dispatch(UserActions.userLogout(featureFlag)),
  processIDPUser: (connection: string) =>
    dispatch(UserActions.userProcessIDP(connection)),
  processUser: (accessToken: string) =>
    dispatch(UserActions.userProcess(accessToken)),
  processUserV2: () => dispatch(UserActions.userProcessV2()),
  resetLoginError: () => dispatch(UserActions.userLoginErrorReset()),
  setLoginError: (error) => dispatch(UserActions.userLoginError(error)),
});

export const Login = connect<
  LoginReduxStateProps,
  LoginReduxDispatchProps,
  LoginOwnProps,
  RootState
>(
  mapStateToProps,
  mapDispatchToProps,
)(LoginComponent);
