import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {
  CURRENT,
  MAX_ENROLLMENT_PAGE_BODY_LENGTH,
} from '../apps/dashboard/constants';
import { CountryCode, CountryCodes } from '../constants/country';
import { getOrganizationByAttributes, getOrganizationById } from '../rest';
import { B_IN_MB, LOGO_IMAGE_TYPES } from './constants';
import { isToday } from './dates';
import { Organization } from '../types/organization';
import { CLINICAL_COVERAGE_PROVIDERS } from '../apps/headspaceHub/constants/eapMemberDetails';

// https://day.js.org/docs/en/plugin/custom-parse-format
dayjs.extend(customParseFormat);

export const urlSafe = new RegExp(/[^a-z0-9-_$+!'(),,\*\.\/]/g);
export const slugSafe = new RegExp(/[^a-z0-9-_]/g);
export const domainSafe = new RegExp(/[^a-z0-9-_\.]/g);

export type Validation = (value: any) => Promise<undefined | Error>;
export type ComparisonValidation<T> = (
  value1: T,
  value2: T,
  context?: any,
) => Promise<undefined | Error>;
export interface OverrideValidationFields {
  endDate?: boolean;
}
export type ComparisonValidationWithOverride<
  T
> = ComparisonValidation<T> extends (...a: infer U) => infer R
  ? (...a: [...U, OverrideValidationFields?]) => R
  : never;
export type QueryValidation = (
  key: string,
  value: string,
  context?: any,
) => Promise<undefined | Error>;
export type ValidationHash = { [key: string]: Validation[] };
export type PossibleErrors = Error[];
export interface ErrorResp {
  [key: string]: PossibleErrors;
}

const EMAIL_PATTERN = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@[^<>()\[\]\\.,;:\s@"-]((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]*\.)+[a-zA-Z]{2,}))$/;
const SALESFORCE_ACCOUNT_NAIVE_PATTERN = /^001\w{15}$/;
const SALESFORCE_OPPORTUNITY_NAIVE_PATTERN = /^006\w{15}$/;
const URL_PATTERN = /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)[a-z0-9-_]+([\-\.]{1}[a-z0-9-_]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/;
const SLUG_PATTERN = /^[a-z0-9-_]*$/;
const DOMAIN_PATTERN = /^(?:[a-z0-9](?:[a-z0-9-]{0,61}[a-z0-9])?\.)+[a-z0-9][a-z0-9-]{0,61}[a-z0-9]$/;
const DATE_PATTERN = /^(((0[13-9]|1[012])[-/]?(0[1-9]|[12][0-9]|30)|(0[13578]|1[02])[-/]?31|02[-/]?(0[1-9]|1[0-9]|2[0-8]))[-/]?[0-9]{4}|02[-/]?29[-/]?([0-9]{2}(([2468][048]|[02468][48])|[13579][26])|([13579][26]|[02468][048]|0[0-9]|1[0-6])00))$/;
const CSV_FILE_TYPES = [
  'application/excel',
  'application/csv',
  'application/vnd.msexcel',
  'application/vnd.ms-excel',
  'text/comma-separated-values',
  'text/csv',
];

// x is Error is required for type guards to work with filter
export const isError = (x: any): x is Error => !!x;
export const blankError = new Error('blankError');
export const emailError = new Error('emailError');
export const domainError = new Error('domainError');
export const passwordError = new Error('passwordError');
export const seatsMinError = new Error('seatsMinError');
export const seatsNumError = new Error('seatsNumError');
export const dependentSeatsError = new Error('dependentSeatsError');
export const salesforceAccountIdError = new Error('salesforceAccountIdError');
export const salesforceOpportunityIdError = new Error(
  'salesforceOpportunityIdError',
);
export const bodyError = new Error('bodyError');
export const dateError = new Error('dateError');
export const campaignDisplayNameError = new Error('campaignDisplayNameError');
export const campaignLaunchDateError = new Error('campaignLaunchDateError');
export const campaignLaunchDateAfterError = new Error(
  'campaignLaunchDateAfterError',
);
export const campaignLaunchDatePastError = new Error(
  'campaignLaunchDatePastError',
);
export const endDateError = new Error('endDateError');
export const contractDateError = new Error('contractDateError');
export const currentDateError = new Error('currentDateError');
export const futureDateError = new Error('futureDateError');
export const expiredDateError = new Error('expiredDateError');
export const logoResolutionError = new Error('logoResolutionError');
export const logoSizeError = new Error('logoSizeError');
export const logoTypeError = new Error('logoTypeError');
export const uniqueValueError = new Error('uniqueValueError');
export const slugCharacterError = new Error('slugCharacterError');
export const overflowUrlError = new Error('overflowUrlError');
export const mustBeginWithHttp = new Error('mustBeginWithHttp');
export const csvTypeError = new Error('csvTypeError');
export const startDateInPastError = new Error('startDateInPastError');
export const gingerIdError = new Error('gingerIdError');
export const orgCustomMappingValueError = new Error(
  'orgCustomMappingValueError',
);
export const orgMappingValueError = new Error('orgMappingValueError');
export const numericValueError = new Error('numericValueError');
export const futureUnifiedContractError = new Error(
  'futureUnifiedContractError',
);
export const futureFusionContractError = new Error('futureFusionContractError');
export const inactiveOrgErrorMessage = new Error('inactiveOrgErrorMessage');
export const nonMatchingEnrollmentTypesErrorMessage = new Error(
  'nonMatchingEnrollmentTypesErrorMessage',
);
export const noOrganizationError = new Error('noOrg');
export const notGingerToFusionReadyError = new Error(
  'notGingerToFusionReadyError',
);
export const phoneNumberError = new Error('phoneNumberError');
export const externalOrgIdError = new Error('externalOrgIDError');
export const coverageTotalError = new Error('coverageTotalError');
export const downgradedContractError = new Error('downgradedContractError');

export const validateValue = (
  value: any,
  validations: Validation[],
): Promise<PossibleErrors> => {
  return Promise.all(validations.map((fn: Validation) => fn(value))).then(
    (errors: (Error | undefined)[]) => {
      return errors.filter(isError);
    },
  );
};

export const validateAll = (
  state: any,
  validations: ValidationHash,
): Promise<ErrorResp> => {
  const keys = Object.keys(validations);
  return Promise.all(
    keys.map((key: string) => {
      return validateValue(state[key], validations[key]);
    }),
  ).then((list: PossibleErrors[]) => {
    // this actually generates errors
    // since catch only allows one error
    return list.reduce((accum: ErrorResp, value: PossibleErrors, i) => {
      if (value.length) {
        accum[keys[i]] = value;
      }
      return accum;
    }, {});
  });
};

export const hasErrors = (errors: ErrorResp): boolean => {
  return Object.keys(errors).reduce((hasError: boolean, key: string) => {
    if (errors[key].length) {
      return true;
    }
    return hasError;
  }, false);
};

export const getErrorMesssage = (
  errors: void | PossibleErrors,
): string | undefined => {
  if (!errors) {
    return undefined;
  }
  // need to create new array to avoid mutations
  const error = [...errors].shift();
  return error ? error.message : undefined;
};

export const getFileType = (type: string): string => {
  return type.split('/')[1];
};

export const isAvailableUniqueOrgValue: QueryValidation = (key, value, id) => {
  return new Promise((resolve) => {
    getOrganizationByAttributes(key, value).then((org?: Organization) => {
      const valid =
        org === undefined || (org.id !== undefined && org.id === id);

      resolve(valid ? undefined : uniqueValueError);
    });
  });
};

export const isAvailableOrgMappingValue: QueryValidation = async (
  _key,
  value,
  [orgId, parentId],
) => {
  const org: Organization = await getOrganizationById(parentId);
  const isTaken = org?.childOrgs
    ?.filter((childOrg) => childOrg.orgId !== orgId)
    ?.map((childOrg) => childOrg.orgMappingValue)
    ?.filter((v) => v !== null && v !== undefined)
    .map((v) => v.toLowerCase())
    .includes(value.toLowerCase());

  return isTaken ? uniqueValueError : undefined;
};

export const isNotBlank: Validation = (value) => {
  return new Promise((resolve) => {
    if (
      typeof value === 'object' ||
      (typeof value === 'string' && value.trim())
    ) {
      resolve(undefined);
      return;
    }
    resolve(blankError);
  });
};

export const isReasonableSalesforceAccountId: Validation = (value) => {
  return new Promise((resolve) => {
    if (SALESFORCE_ACCOUNT_NAIVE_PATTERN.test(value)) {
      resolve(undefined);
      return;
    }
    resolve(salesforceAccountIdError);
  });
};

export const isReasonableSalesforceOpportunityId: Validation = (value) => {
  return new Promise((resolve) => {
    if (SALESFORCE_OPPORTUNITY_NAIVE_PATTERN.test(value)) {
      resolve(undefined);
      return;
    }
    resolve(salesforceOpportunityIdError);
  });
};

export const emailCheck = (email: string): boolean => {
  return EMAIL_PATTERN.test(email);
};

export const domainCheck = (domain: string): boolean => {
  return DOMAIN_PATTERN.test(domain);
};

export const urlCheck = (url: string): boolean => {
  return URL_PATTERN.test(url);
};

export const slugCheck = (slug: string): boolean => {
  return SLUG_PATTERN.test(slug);
};

export const dateCheck = (date: string): boolean => {
  return DATE_PATTERN.test(date);
};

export const isEmail: Validation = (value) => {
  return new Promise((resolve): void => {
    if (emailCheck(value)) {
      resolve(undefined);
      return;
    }
    resolve(emailError);
  });
};

export const emailDomainsCheck = (
  email: string,
  domains: string[],
): boolean => {
  const emailDomain = email.split('@').pop() as string;

  return domains.includes(emailDomain);
};

export const emailDomainCheck = (email: string, domain: string): boolean => {
  return (
    emailCheck(email) &&
    !!email.match(new RegExp(`^[A-Za-z0-9._%+-]+@${domain}$`))
  );
};

export const isEmailDomain: Validation = (value) => {
  const { email, domain } = value;
  return new Promise((resolve) => {
    if (emailDomainCheck(email, domain)) {
      resolve(undefined);
      return;
    }
    resolve(emailError);
  });
};

export const isDecentPassword: Validation = (value) => {
  return new Promise((resolve) => {
    if (value.length >= 8) {
      resolve(undefined);
      return;
    }
    resolve(passwordError);
  });
};

const isStringRepresentInteger = (value: string): boolean => {
  const seats = parseInt(value, 10);
  return parseFloat(value) === seats;
};

export const isValidOrgId = (value: string) => /^\d+$/.test(value);

export const isValidSeatCount: Validation = (value) => {
  return new Promise((resolve) => {
    const seats = parseInt(value, 10);

    if (!isStringRepresentInteger(value)) {
      resolve(seatsNumError);
      return;
    }
    if (seats > 0) {
      resolve(undefined);
      return;
    }

    resolve(seatsMinError);
  });
};

export const isValidDependentPerMemberCount: Validation = (
  dependentSeats: number,
) => {
  return new Promise((resolve) => {
    // if it's a unified org, the dependent seat count min must stay in sync with Ginger's
    if (dependentSeats > 0 && dependentSeats < 4) {
      resolve(dependentSeatsError);
      return;
    }

    resolve(undefined);
  });
};

export const isValidDate: Validation = (value) => {
  return new Promise((resolve) => {
    if (dateCheck(value)) {
      resolve(undefined);
      return;
    }
    resolve(dateError);
  });
};

export const isValidDateSequence: ComparisonValidation<string> = (
  startDate,
  endDate,
) => {
  return new Promise((resolve) => {
    if (new Date(startDate) < new Date(endDate)) {
      resolve(undefined);
      return;
    }
    resolve(endDateError);
  });
};

export const isValidContractSpan: ComparisonValidation<string> = (
  activeEndDate: string,
  nextStartDate: string,
) => {
  return new Promise((resolve) => {
    if (new Date(activeEndDate) <= new Date(nextStartDate)) {
      resolve(undefined);
      return;
    }
    resolve(contractDateError);
  });
};

export const isValidStartDate: Validation = (contractStartDate: string) => {
  return new Promise((resolve) => {
    const startDate = new Date(contractStartDate);
    const today = new Date();
    today.setHours(0, 0, 0, 0);
    return resolve(startDate >= today ? undefined : startDateInPastError);
  });
};

export const isValidContractTerm: ComparisonValidationWithOverride<string> = (
  contractStartDate: string,
  contractEndDate: string,
  term: string,
  overrideValidationField?: OverrideValidationFields,
) => {
  return new Promise((resolve) => {
    const referenceDate = new Date();
    const contractNewStartDate = new Date(contractStartDate);
    const contractNewEndDate = new Date(contractEndDate);

    if (term === CURRENT) {
      const isStartDateBeforeEqReference =
        contractNewStartDate <= referenceDate;
      const isEndDateAfterEqReference =
        overrideValidationField?.endDate ?? referenceDate <= contractNewEndDate;
      const validationResult =
        isStartDateBeforeEqReference && isEndDateAfterEqReference
          ? undefined
          : currentDateError;

      resolve(validationResult);
      return;
    }

    if (term === 'any') {
      if (contractNewEndDate >= referenceDate) {
        resolve(undefined);
        return;
      }
      resolve(expiredDateError);
      return;
    }

    if (contractNewStartDate >= referenceDate) {
      resolve(undefined);
      return;
    }
    resolve(futureDateError);
  });
};

export const isValidLogoSize: Validation = (value: File) => {
  const sizeInMB = value.size / B_IN_MB;
  return new Promise((resolve) => {
    if (sizeInMB <= 2) {
      resolve(undefined);
      return;
    }
    resolve(logoSizeError);
  });
};

export const isValidLogoType: Validation = (value: File) => {
  return new Promise((resolve) => {
    if (LOGO_IMAGE_TYPES.includes(value.type)) {
      resolve(undefined);
      return;
    }
    resolve(logoTypeError);
  });
};

export const isValidBodyLength: Validation = (value) => {
  return new Promise((resolve) => {
    if (value.length <= MAX_ENROLLMENT_PAGE_BODY_LENGTH) {
      resolve(undefined);
      return;
    }
    resolve(bodyError);
  });
};

export const isValidLogoResolution: Validation = (value: File) => {
  return new Promise((resolve) => {
    const image = new Image();
    image.onload = (event: any) => {
      if (event && event.target) {
        if (event.target.width >= 160 && event.target.height >= 160) {
          resolve(undefined);
        }
        resolve(logoResolutionError);
      } else {
        resolve(undefined);
      }
    };
    image.src = window.URL.createObjectURL(value);
    resolve(undefined);
  });
};

export const isValidEligibilityFile: Validation = (value) => {
  return new Promise((resolve) => {
    if (CSV_FILE_TYPES.includes(value.type)) {
      resolve(undefined);
    }
    resolve(csvTypeError);
  });
};

export const isReasonableUrl: Validation = (value) => {
  return new Promise((resolve) => {
    if (urlCheck(value)) {
      resolve(undefined);
      return;
    }
    if (!value.includes('http')) {
      resolve(mustBeginWithHttp);
    } else {
      resolve(overflowUrlError);
    }
  });
};

export const isReasonableDomain: Validation = (value) => {
  return new Promise((resolve) => {
    if (domainCheck(value)) {
      resolve(undefined);
      return;
    }
    resolve(domainError);
  });
};

export const isAcceptableSlug: Validation = (value) => {
  return new Promise((resolve) => {
    if (slugCheck(value)) {
      resolve(undefined);
      return;
    }
    resolve(slugCharacterError);
  });
};

export const removeDoubleQuoteAndBackslash = (
  str: string | undefined,
): string | undefined => (str ? str.replace(/["\\]/gm, '') : str);

export const addSpaceOnTrailingDoubleQuote = (
  str: string | undefined,
): string | undefined =>
  typeof str === 'string' ? str.replace(/"$/gm, '" ') : str;

export const isValidGingerId = async (
  value: string,
  orgId,
): Promise<undefined | Error> => {
  const result = await Promise.all([
    isValueOfLength(value, 255, gingerIdError),
    isAvailableUniqueOrgValue('gingerId', value, orgId),
    isValidNumericValue(value),
  ]);
  return result.reduce((acc, value) => value ?? acc, undefined);
};

export const isValueOfLength = (
  value: string,
  length: number,
  error: Error,
): Promise<undefined | Error> =>
  new Promise((resolve) => {
    const trimmedValue = value.trim();
    if (trimmedValue.length <= length) {
      resolve(undefined);
      return;
    }
    resolve(error);
  });

export const isValidNumericValue: Validation = (value) => {
  return new Promise((resolve) => {
    const numericRegex = /^\d+$/;
    const isAllNumeric = numericRegex.test(value);

    if (isAllNumeric) {
      resolve(undefined);
      return;
    }

    resolve(numericValueError);
  });
};

export const isValidOrgMappingValue: QueryValidation = (mappingKey, value) => {
  return new Promise((resolve) => {
    if (mappingKey && !value.trim()) {
      return resolve(blankError);
    }

    if (
      mappingKey.toLowerCase() === 'country' &&
      !CountryCodes.includes(value as CountryCode)
    ) {
      return resolve(orgMappingValueError);
    }

    return resolve(undefined);
  });
};

export const isValidCampaignDisplayName: Validation = (value) => {
  return new Promise((resolve) => {
    if (value.length <= 30) {
      resolve(undefined);
      return;
    }
    resolve(campaignDisplayNameError);
  });
};

export const isValidCampaignLaunchDate = (
  campaignLaunchDate: string,
  contractStartDate: string,
  contractEndDate: string,
  currentDate: Date,
): Promise<undefined | Error> => {
  return new Promise((resolve) => {
    const launchDate = new Date(campaignLaunchDate);
    const startDate = new Date(contractStartDate);
    const endDate = new Date(contractEndDate);

    let error;
    if (launchDate > endDate) {
      error = campaignLaunchDateAfterError;
    } else if (launchDate < startDate) {
      error = campaignLaunchDateError;
    } else if (launchDate < currentDate && !isToday(launchDate)) {
      error = campaignLaunchDatePastError;
    }
    resolve(error);
  });
};

export const orgHasActiveContract = (org: Organization) =>
  org.contracts.filter((subscription) => subscription.status === 'ACTIVE')
    .length > 0;

export const orgHasFutureGingerToFusionUpgrade = (org: Organization) =>
  !!org.gingerId && !orgHasActiveContract(org);

export const checkEnrollmentTypesMatch: ComparisonValidation<
  string | null | undefined
> = (parentEnrollment, childEnrollment) => {
  return new Promise((resolve) => {
    if (!childEnrollment || parentEnrollment === childEnrollment) {
      resolve(undefined);
      return;
    }
    resolve(nonMatchingEnrollmentTypesErrorMessage);
  });
};

// Basic validation. Full validation will happen on the backend
const DOB_REGEX = /([01]\d)\/(\d{2})\/((?:19|20)\d{2})/;
const PHONE_NUMBER_REGEX = /^[\s\d\(\)+-]+$/;
const EAP_PHONE_NUMBER_REGEX_CCA = /^\s*(?:\+?(\d{1,3}))[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{4})(?: *[x/#]{1}(\d+))?\s*$/;
const EAP_PHONE_NUMBER_REGEX_WPO = /^\s*(?:\+?(\d{1,3}))[- (]*(\d{3})[- )]*(\d{3})[- ]*(\d{3,4})(?: *[x/#]{1}(\d+))?\s*$/;
/**
 * @param dob mm/dd/yyyy
 * @returns
 */
export const isValidDob = (dob: string) => {
  if (!DOB_REGEX.test(dob)) return false;
  const [_, mm, dd, yyyy] = dob.match(DOB_REGEX) ?? [];

  const parsedDob = dayjs(`${yyyy}-${mm}-${dd}`, 'YYYY-MM-DD', true);

  return dayjs().isAfter(parsedDob) && parsedDob.isValid();
};

export const isValidPhoneNumber: Validation = (phoneNumber: string) => {
  return new Promise((resolve) => {
    if (PHONE_NUMBER_REGEX.test(phoneNumber)) {
      resolve(undefined);
      return;
    }

    resolve(phoneNumberError);
  });
};

export const isValidEapPhoneNumber = (
  phoneNumber: string,
  provider?: CLINICAL_COVERAGE_PROVIDERS,
): boolean => {
  const strippedPhoneNumber = phoneNumber.replace(/\D/g, '');

  const regex =
    provider === CLINICAL_COVERAGE_PROVIDERS.CCA
      ? EAP_PHONE_NUMBER_REGEX_CCA
      : EAP_PHONE_NUMBER_REGEX_WPO;
  return regex.test(strippedPhoneNumber);
};

export const isValidCoverageTotal: Validation = (coverageTotal: string) => {
  return new Promise((resolve) => {
    const numericRegex = /^(0|[1-9][0-9]*)$/;

    if (numericRegex.test(coverageTotal)) {
      resolve(undefined);
      return;
    }

    resolve(coverageTotalError);
  });
};
