import { useMemo, FC, useCallback, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { css } from '@emotion/react';
import {
  useActiveBreakpoint,
  Breakpoint,
  rem,
} from '@headspace/web-ui-components';
import { LandingCardSet } from '../VerificationPage/components/LandingCardSet';
import { LandingFooter } from '../VerificationPage/components/LandingFooter';
import { MemberLogin } from '../../MemberLoginPage';
import {
  columnStyle,
  contentContainerStyle,
  pageContainerStyle,
} from '../styles/pageStyles';
import { V3_LOGIN_PAGE } from '../../../constants';
import { useUnifiedEligibilityBasePath } from '../utils/routeUtils';
import {
  ENROLLMENT_CDN_URL,
  getUnifiedEligibilityRoutes,
} from '../../../constants/routes';
import { ExistingAccountCardLabels } from '../../MemberLoginPage/LoginView/ExistingAccountCard';
import { useTranslate } from '../../../../../hooks/useTranslate';
import {
  getEnrollmentVerificationIsVerified,
  getVerificationMethod,
} from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import { getHsUserId } from '../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { PrivacyNotice } from './components/PrivacyNotice';
import { TermsOfService } from './components/TermsOfService';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';

export const SignUpPage: FC = () => {
  const navigate = useNavigate();
  const { translate } = useTranslate();
  const activeBreakpoint = useActiveBreakpoint();
  const isEnrollmentVerified = useSelector(getEnrollmentVerificationIsVerified);
  const hsUserId = useSelector(getHsUserId);
  const shouldShowLandingCardSet =
    useSelector(getVerificationMethod) === EnrollmentVerification.KP_JWT;
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const { slug } = useUnifiedEligibilityBasePath();
  const { VERIFY_PATH, ONBOARDING_PATH } = getUnifiedEligibilityRoutes(slug);

  const [isShownPrompt, setIsShowPrompt] = useState(true);

  const onShowPromptHandler = useCallback(
    (promptState) => {
      setIsShowPrompt(promptState);
    },
    [setIsShowPrompt],
  );

  const existingAccountCardLabelOverrides: ExistingAccountCardLabels = {
    titleOverride: translate('enrollment.auth.header'),
    yesButtonOverride: translate('enrollment.auth.yes'),
    noButtonOverride: translate('enrollment.auth.no'),
  };
  // check if user id verified on the previous page, if not kick them back to the verification page
  useEffect(() => {
    if (!isEnrollmentVerified) {
      navigate(VERIFY_PATH);
    }
  }, []);

  // If user is already logged in redirect to onboarding page
  useEffect(() => {
    if (isEnrollmentVerified && hsUserId) {
      navigate(ONBOARDING_PATH);
    }
  }, []);

  return (
    <div>
      <div
        css={css(pageContainerStyle(), {
          width: '100vw',
          marginTop: 0,
        })}
      >
        <div
          css={css(contentContainerStyle, {
            marginTop: 0,
          })}
        >
          {isMobileView ? null : (
            <div css={[columnStyle]}>
              {isShownPrompt ? (
                <img
                  src={`${ENROLLMENT_CDN_URL}/umd-login-circle-prompt.svg`}
                  alt=""
                  role="presentation"
                />
              ) : (
                <img
                  src={`${ENROLLMENT_CDN_URL}/umd-login.svg`}
                  alt=""
                  role="presentation"
                />
              )}
            </div>
          )}

          <div css={css(columnStyle, { flexDirection: 'column' })}>
            <MemberLogin
              showMobileMessage={false}
              pageName={V3_LOGIN_PAGE}
              onShowPrompt={onShowPromptHandler}
              termsComponentOverride={<TermsOfService />}
              existingAccountCardLabelOverrides={
                existingAccountCardLabelOverrides
              }
            />

            {isMobileView && isShownPrompt ? (
              <img
                css={css({ width: rem(15) })}
                src={`${ENROLLMENT_CDN_URL}/umd-login-circle-prompt.svg`}
                alt=""
                role="presentation"
              />
            ) : null}

            <PrivacyNotice />
          </div>
        </div>
      </div>
      {!isMobileView && shouldShowLandingCardSet ? (
        <div css={css({ width: '100vw' })}>
          <LandingCardSet isMobileView={isMobileView} />
          <LandingFooter isMobile={isMobileView} />
        </div>
      ) : null}
    </div>
  );
};
