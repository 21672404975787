import * as React from 'react';
import { TextLink } from '@headspace/web-ui-components';
import { titleStyle } from '../styles/assetsStyle';
import { PRIMARY_PRODUCT } from '../../../../constants';
import { CLINICAL_COVERAGE_TYPE } from '../../../../../../types/organization';
import { useTranslate } from '../../../../../../hooks/useTranslate';

const assets = {
  carePackage: 'https://headspace.highspot.com/viewer/6560b612cf50170ddc230a0a',
  corePackage: 'https://headspace.highspot.com/viewer/6564f005f437de55cac62fa6',
  eapPackage: 'https://headspace.highspot.com/viewer/64f008d9519c823bd462dba3',
};

function getAssetsLink(
  primaryProduct: string,
  clinicalCoverageType: CLINICAL_COVERAGE_TYPE,
): string {
  if (primaryProduct === PRIMARY_PRODUCT.FUSION) {
    if (clinicalCoverageType === CLINICAL_COVERAGE_TYPE.NONE) {
      return assets.carePackage;
    }
    return assets.eapPackage;
  }
  return assets.corePackage;
}

export interface AssetsProps {
  primaryProduct: string;
  clinicalCoverageType: CLINICAL_COVERAGE_TYPE;
}

export const Assets: React.FC<AssetsProps> = ({
  primaryProduct,
  clinicalCoverageType,
}) => {
  const { translate } = useTranslate();
  const assetsLink = getAssetsLink(primaryProduct, clinicalCoverageType);

  return (
    <>
      <h4 css={titleStyle}>{translate('toolkit.assetsTitle')}</h4>
      {translate('toolkit.assetsText', {
        link: (children) => (
          <TextLink
            to={assetsLink}
            isNewWindow={true}
            dataTestIdPrefix="assets"
          >
            {children}
          </TextLink>
        ),
      })}
    </>
  );
};
