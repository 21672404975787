import { useCallback, useState, FC, ChangeEvent } from 'react';
import * as React from 'react';
import {
  distanceInWords,
  format,
  formatDateAndRemoveZ,
} from '../../../../../../utils/dates';
import { Input } from '../../../../../../shared-components/Input';
import { Panel } from '../../../../../../shared-components/Panel';
import { PrimaryProductInput } from '../shared-components/PrimaryProductInput/PrimaryProductInput';
import { Contract } from '../../../../../../state/models/organization/Contract';
import { panelTwoColumnStyle } from '../styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { PanelWrapper } from '../shared-components/SettingsPanel/PanelWrapper';
import { PanelTitle } from '../shared-components/SettingsPanel/PanelTitle';
import { DependentsInputGroup } from '../../../../../../shared-components/DependentsInputGroup';
import { isZeroInput } from '../../../../utils/manage-organization';
import { AddOnOptionsInput } from '../../../../../../shared-components/AddOnInput/AddOnOptionsInput';

export interface OrganizationExpiredContractPanelProps {
  expiredContracts: Contract[];
}

export const ExpiredContractsList: FC<OrganizationExpiredContractPanelProps> = ({
  expiredContracts,
}) => {
  const term = 'expired';
  const { translate } = useTranslate();

  const [contractIndex, setContractIndex] = useState(0);

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const selected = expiredContracts
        .map((item: any, i: number) => ({
          index: i,
          value: `${formatDateAndRemoveZ(item.endDate)}`,
        }))
        .filter((item: any) => item.value === value);

      setContractIndex(selected[0].index);
    },
    [expiredContracts],
  );

  const selectedContract = expiredContracts[contractIndex];
  const {
    opportunityId,
    primaryProduct,
    startDate,
    seats,
    isPilot,
    partialPayVoucher: voucher,
    dependentSeats,
    dependentsPerMember,
    elfDependentsEnabled,
    clinicalCoverageType,
  } = selectedContract;

  const endDates = expiredContracts.map((item: any, i: number) => ({
    index: i,
    value: `${formatDateAndRemoveZ(item.endDate)}`,
  }));

  return (
    <Panel>
      <PanelWrapper dataTestId="organization-settings-expired-contract">
        <PanelTitle>Expired Contract Details</PanelTitle>
        <div css={panelTwoColumnStyle}>
          <div className="inputGroup">
            <PrimaryProductInput
              contractStateTerm="expired"
              showReview={true}
              providedPrimaryProduct={primaryProduct}
            />
            <Input
              type="select"
              name="expired_contract_end_date"
              label={translate(`organizationSettings.contract.endLabel`)}
              value={`${formatDateAndRemoveZ(endDates[contractIndex].value)}`}
              onChange={handleChange}
              options={endDates}
            />
            <Input
              type="review"
              name="expired_contract_start_date"
              label={translate(`organizationSettings.contract.startLabel`)}
              value={`${format(startDate, 'MM/DD/YYYY')} ( UTC )`}
              subtext={distanceInWords(new Date(), new Date(startDate), true)}
            />
            <Input
              type="review"
              name="expired_contract_opportunity_id"
              label={translate(
                `organizationSettings.contract.opportunityIdLabel`,
              )}
              value={opportunityId}
            />
            <AddOnOptionsInput
              type="review"
              term="expired"
              value={clinicalCoverageType}
              disable={true}
            />
          </div>
          <div className="inputGroup">
            <Input
              type="review"
              name="expired_contract_seats"
              label={translate(`organizationSettings.contract.seatsLabel`)}
              value={seats}
            />
            <DependentsInputGroup
              name="dependent-setting"
              inviteBasedDependents={!isZeroInput(dependentSeats)}
              eligibilityFileBasedDependents={elfDependentsEnabled}
              isViewMode={true}
              isDisabled={true}
              contract={term}
              showTooltip={false}
            />
            {!!dependentSeats && (
              <div className="inputGroup">
                <Input
                  type="review"
                  name="contract_number_of_family_members_seats"
                  label={translate(
                    `organizationSettings.contract.familyMembersSeatsLabel`,
                  )}
                  value={dependentSeats}
                />
                <Input
                  type="review"
                  name="contract_family_members_per_employee"
                  label={translate(
                    `organizationSettings.contract.familyMembersPerEmployeeLabel`,
                  )}
                  value={dependentsPerMember}
                />
              </div>
            )}
            <Input
              type="review"
              name="expired_contract_payment_type"
              label={translate(`organizationSettings.contract.paymentLabel`)}
              value={voucher ? 'Member Pay' : 'Employer Pay'}
            />
            <Input
              type="review"
              name="expired_contract_is_pilot"
              label={translate(`organizationSettings.contract.pilotLabel`)}
              value={
                isPilot
                  ? (translate('yes') as string)
                  : (translate('no') as string)
              }
            />
            {voucher && (
              <Input
                type="review"
                name="expired_contract_voucher"
                label={translate(`organizationSettings.contract.voucherCode`)}
                value={voucher}
              />
            )}
          </div>
        </div>
      </PanelWrapper>
    </Panel>
  );
};
