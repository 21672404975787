import { css } from '@emotion/react';
import { FC, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Red, Divider, Size, fontSizeXS } from '@headspace/web-ui-components';
import reactHtmlParser from 'react-html-parser';

import { Input } from '../../../../shared-components/Input';
import { RestrictByInput } from '../../../../shared-components/RestrictByInput';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { domainSafe } from '../../../../utils/validations';
import { HOST, NODE_ENV } from '../../../../utils/constants';
import { shouldShowCampaignDisplayFields } from '../../utils/manage-organization';
import {
  getOrgManagement,
  getAddDomainDisabledStatus,
  getRestrictByChanged,
} from '../../../../state/selectors/organizationSelectors';
import {
  deleteDomain,
  handleChange as handleChangeAction,
  updateImplicitState,
  newDomain,
} from '../../../../state/actions/organizationActions';
import {
  CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES,
  RestrictBy,
  delimiterOptions,
} from '../../../../types/organization';
import { ChildRestrictedInput } from '../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { useTranslate } from '../../../../hooks/useTranslate';
import { SetByParentLabel } from '../../OrganizationView/SettingsView/components/EnrollmentSettingsPanel/components/SetByParentLabel';
import { isCSM as getIsCSM } from '../../../../state/selectors/userSelectors';
import { CampaignDisplayNameInput } from '../../OrganizationView/SettingsView/components/EnrollmentSettingsPanel/components/CampaignDisplayNameInput';
import { clearCampaignFields } from '../../../../state/actions/organizationActions/clearCampaignFields';
import { useCsvDelimiter } from '../../../../featureFlags/csvDelimiter';

const domainNudgeStyle = css({
  color: Red[100],
  fontSize: fontSizeXS,
});

export const Enrollment: FC = () => {
  const {
    add_domain,
    add_domainError,
    campaignLaunchDate,
    campaignLaunchDateAfterError,
    campaignLaunchDateError,
    campaignLaunchDatePastError,
    current_contract_primary_product,
    overflow_url,
    overflow_urlError,
    overflowDisabled,
    restrictBy,
    restrictedDomains,
    slug,
    slugError,
    workIdFieldPlaceholderText,
    workIdFieldPlaceholderTextError,
    parentOrg,
    csvDelimiter,
  } = useSelector(getOrgManagement);
  const addDomainIsDisabled = useSelector(getAddDomainDisabledStatus);
  const isCSM = useSelector(getIsCSM);
  const isRestrictByChanged = useSelector(getRestrictByChanged);
  const domainCount = restrictedDomains.length;
  const host = HOST ? HOST.replace(/(https|http):\/\/(www\.)?/, '') : '';
  const dispatch = useDispatch();
  const { translate } = useTranslate();

  const handleDomainAdd = useCallback(() => dispatch(newDomain()), [dispatch]);

  const handleChange = useCallback(
    (event: ExtendedEvent, supressValidation?: boolean) =>
      dispatch(handleChangeAction(event, supressValidation)),
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  const handleDomainDropdownDelete = useCallback(
    (event: ExtendedEvent) => dispatch(deleteDomain(event)),
    [dispatch],
  );

  const isRestrictedByEmailDomain = restrictBy === RestrictBy.EMAIL_DOMAIN;
  const isRestrictedByEmpId = restrictBy === RestrictBy.EMPLOYEE_ID;
  const isRestrictedByEmail = restrictBy === RestrictBy.EMAIL_ELIGIBILITY_FILE;

  const updatedOverflowUrl = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.overflowUrl
    : overflow_url;

  const updatedSlug = parentOrg?.useSingleEnrollmentLink
    ? parentOrg?.enrollment?.slug
    : slug;

  const showCampaignDisplayFields = shouldShowCampaignDisplayFields(
    restrictBy,
    isCSM,
  );

  const hasCampaignLaunchDateError =
    campaignLaunchDateError.error ||
    campaignLaunchDateAfterError.error ||
    campaignLaunchDatePastError.error;
  const campaignLaunchDateErrorMessage =
    campaignLaunchDateError.message ||
    campaignLaunchDateAfterError.message ||
    campaignLaunchDatePastError.message;

  // Remove campaign data if restrictby changes from SSO or Elf restrict by types
  useEffect(() => {
    if (
      isRestrictByChanged &&
      !CAMPAIGN_DISPLAY_SUPPORTED_RESTRICTBY_TYPES.includes(restrictBy)
    ) {
      dispatch(clearCampaignFields());
    }
  }, [isRestrictByChanged, dispatch, restrictBy]);

  const csvDelimiterFeatureEnabled = useCsvDelimiter();

  return (
    <div data-testid="enrollment-page" css={formContainerStyle}>
      <div>
        <Input
          label={translate(`organizationSettings.enrollment.slugLabel`)}
          type="url"
          onChange={handleChange}
          onBlur={handleError}
          hasError={slugError.error}
          errorMessage={slugError.message}
          name="slug"
          value={updatedSlug.toLocaleLowerCase()}
          hintLeft={`${host}/`}
          hintRight="/member-enroll"
          autoFocus={true}
          disabled={parentOrg?.useSingleEnrollmentLink}
        />
        <SetByParentLabel display={parentOrg?.useSingleEnrollmentLink} />
      </div>
      <div>
        <Input
          label={translate(`organizationSettings.enrollment.overflowLabel`)}
          placeholder={translate(
            `organizationSettings.enrollment.overflowPlaceholder`,
          )}
          type="url"
          onChange={handleChange}
          onBlur={handleError}
          name="overflow_url"
          value={updatedOverflowUrl}
          hasError={overflow_urlError.error}
          errorMessage={overflow_urlError.message}
          disabled={overflowDisabled || parentOrg?.useSingleEnrollmentLink}
        />
        <SetByParentLabel display={parentOrg?.useSingleEnrollmentLink} />
      </div>
      {showCampaignDisplayFields && (
        <div data-testid="enrollment-campaign-container" css={rowStyle}>
          <CampaignDisplayNameInput
            displayDescription={true}
            editMode={true}
            onChange={handleChange}
            onError={handleError}
          />
          <Input
            label={translate(
              `organizationSettings.enrollment.campaignLaunchDateLabel`,
            )}
            placeholder={translate(
              `organizationSettings.enrollment.campaignLaunchDatePlaceholder`,
            )}
            name="campaignLaunchDate"
            type="date"
            onChange={handleChange}
            onBlur={handleError}
            value={campaignLaunchDate}
            hasError={hasCampaignLaunchDateError}
            errorMessage={campaignLaunchDateErrorMessage}
            css={{ cursor: 'pointer' }}
          />
        </div>
      )}
      <Divider size={Size.M} />
      <div css={rowStyle}>
        <RestrictByInput env={NODE_ENV} />

        {isRestrictedByEmpId && (
          <ChildRestrictedInput
            label={translate(
              `organizationSettings.enrollment.customWorkIdFieldPlaceholderTextLabel`,
            )}
            placeholder={translate(
              `organizationSettings.enrollment.customWorkIdFieldPlaceholderTextPlaceHolder`,
            )}
            type="text"
            onChange={handleChange}
            onBlur={handleError}
            name="workIdFieldPlaceholderText"
            value={workIdFieldPlaceholderText}
            hasError={workIdFieldPlaceholderTextError.error}
            errorMessage={workIdFieldPlaceholderTextError.message}
          />
        )}
        {isRestrictedByEmailDomain && (
          <div>
            <Input
              label={translate(
                `organizationSettings.enrollment.restrictedDomainsLabel`,
              )}
              type="list"
              name="add_domain"
              onChange={handleChange}
              onBlur={handleError}
              disabled={addDomainIsDisabled}
              coverValue={reactHtmlParser(
                translate(
                  `organizationSettings.enrollment.domainCount${
                    domainCount === 1 ? '' : '_plural'
                  }`,
                  {
                    domainCount,
                  },
                ),
              )}
              value={add_domain.replace(domainSafe, '')}
              placeholder={translate(
                `organizationSettings.enrollment.addDomainPlaceholder`,
              )}
              addItem={handleDomainAdd}
              addItemText={translate(
                `organizationSettings.enrollment.addDomain`,
              )}
              list={restrictedDomains}
              errorMessage={add_domainError.message}
              hasError={add_domainError.error}
              deleteItem={handleDomainDropdownDelete}
            />
            <span css={domainNudgeStyle}>
              {reactHtmlParser(
                translate(`organizationSettings.enrollment.domainNudge`),
              )}
            </span>
          </div>
        )}
      </div>
      {csvDelimiterFeatureEnabled &&
      (isRestrictedByEmail || isRestrictedByEmpId) ? (
        <div css={rowStyle}>
          <ChildRestrictedInput
            dataTestId="csvDelimiterSelect"
            name="csvDelimiter"
            type="select"
            label="delimiter"
            options={delimiterOptions}
            value={csvDelimiter}
            onChange={handleChange}
          />
        </div>
      ) : null}
    </div>
  );
};
