import { useInfiniteQuery } from '@tanstack/react-query';
import { getOrganizations } from '../../../rest';
import type { FetchOrganizationsQuery } from '../../../state/types/organizations';
import { FETCH_ORGANIZATION_LIMIT } from '../../../utils/constants';

export const queryKey = 'searchOrganization';

export const buildSearchParams = (
  query: FetchOrganizationsQuery,
): URLSearchParams => {
  const {
    search = '',
    orgTypes,
    orgSubscriptionStatus,
    onlyRootOrgs = true,
    primaryProduct,
  } = query;
  const params = new URLSearchParams();
  params.set('limit', `${FETCH_ORGANIZATION_LIMIT}`);
  params.set('onlyRootOrgs', `${onlyRootOrgs}`);

  if (search) params.set('name', search);
  if (orgTypes?.length) {
    orgTypes.forEach((type, idx) => {
      idx === 0
        ? params.set('orgTypes', type)
        : params.append('orgTypes', type);
    });
  }
  if (orgSubscriptionStatus)
    params.set('orgSubscriptionStatus', orgSubscriptionStatus);

  if (primaryProduct?.length) {
    primaryProduct.forEach((type, idx) => {
      idx === 0
        ? params.set('primaryProduct', type)
        : params.append('primaryProduct', type);
    });
  }

  return params;
};

export const useSearchOrganizations = (query: FetchOrganizationsQuery) => {
  const params = buildSearchParams(query);

  return useInfiniteQuery({
    queryKey: [queryKey, query],
    queryFn: async ({ pageParam = 0 }) => {
      params.set('offset', `${pageParam}`);
      return getOrganizations(`?${params.toString()}`);
    },
  });
};
