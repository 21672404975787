import { useMutation } from '@tanstack/react-query';
import {
  KpPostIdentityVerificationsPayload,
  PostIdentityVerificationsPayload,
  PostIdentityVerificationsResponse,
  postIdentityVerifications,
} from '../rest-calls/postIdentityVerifications';
import {
  VpEnrollmentVerificationPayload,
  postVpEnrollmentVerification,
} from '../rest-calls';
import { ParsedError } from '../../../utils/parseError';

export const usePostVpEnrollmentVerification = (onSuccess, onError) =>
  useMutation({
    mutationFn: (payload: VpEnrollmentVerificationPayload) =>
      postVpEnrollmentVerification(payload),
    onError,
    onSuccess,
  });

export const usePostIdentityVerifications = (
  slug: string,
  onSuccess,
  onError: (error: ParsedError) => void,
) =>
  useMutation({
    mutationFn: (
      payload:
        | PostIdentityVerificationsPayload
        | KpPostIdentityVerificationsPayload,
    ) => postIdentityVerifications(slug, payload),
    onError,
    onSuccess,
  });
