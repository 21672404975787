import type { FC } from 'react';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../hooks/useTranslate';
import { useWindowResize } from '../../../hooks/useWindowResize';
import { Icon } from '../../../shared-components/Icon';
import { SpinnerWithBackGround } from '../../../shared-components/Spinner';
import { Toast } from '../../../shared-components/Toast';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import { Container } from '../../../shared-components/container/Container';
import { TableFilterBar } from '../../../shared-components/tables/FilterBar';
import { TableSection } from '../../../shared-components/tables/TableSection';
import { tableStyle } from '../../../shared-components/tables/styles';
import { TableSectionProps } from '../../../shared-components/tables/types';
import { rendererRow } from '../../../shared-components/tables/utils';
import { drain } from '../../../state/actions/organizationActions';
import {
  createOrganizationAction,
  selectOrganizationAction,
} from '../../../state/actions/organizationsActions';
import { getChallengeCreated } from '../../../state/selectors/challengesSelectors';
import { resetMembers } from '../../../state/slices/members/membersSlice';
import {
  COLUMN_WIDTH_OFFSET,
  HEIGHT_OFFSET,
  PRIMARY_PRODUCT,
} from '../constants';
import { OrganizationsTitle } from './OrganizationsTitle';
import { generateHeaderRow } from './generateHeaderRow';
import { generateRows } from './generateRows';
import {
  actionsStyle,
  noResultsFoundStyle,
} from './styles/organizationsPageStyle';
import { AP_CDN_URL } from '../constants/routes';
import {
  OrganizationTypes,
  SubscriptionStatus,
  Organization,
} from '../../../types/organization';
import { FilterDropdown } from '../../../shared-components/tables/FilterDropdown';
import type { FetchOrganizationsQuery } from '../../../state/types/organizations';
import { useSearchOrganizations } from '../hooks/useSearchOrganizations';
import { useLocalStorage } from '../../../hooks/useLocalStorage';

enum HierarchyFilterOptions {
  OnlyRootOrgs = 'Only Root Orgs',
}

const buildQuery = (
  search: string,
  primaryProduct: PRIMARY_PRODUCT[],
  onlyRootOrgs: boolean,
  orgSubscriptionStatus: SubscriptionStatus[],
  orgTypes?: OrganizationTypes[],
): FetchOrganizationsQuery => ({
  search,
  primaryProduct,
  onlyRootOrgs,
  orgSubscriptionStatus:
    orgSubscriptionStatus.length > 0 ? orgSubscriptionStatus[0] : undefined,
  orgTypes,
});

const primaryProductFilterProps = {
  columnKey: 'offering',
  coverText: 'Offering',
  defaultSelected: [],
  options: [PRIMARY_PRODUCT.FUSION].map((value: string) => ({
    title: value.charAt(0) + value.slice(1).toLowerCase(),
    value,
  })),
};
const statusFilterProps = {
  columnKey: 'status',
  coverText: 'Status',
  defaultSelected: [],
  options: Object.values(SubscriptionStatus).map((value: string) => ({
    title: value.charAt(0) + value.slice(1).toLowerCase(),
    value,
  })),
};
const hierarchyFilterProps = {
  columnKey: 'hierarchy',
  coverText: 'Hierarchy',
  defaultSelected: [],
  options: Object.values(HierarchyFilterOptions).map((value: string) => ({
    title: value.charAt(0) + value.slice(1).toLowerCase(),
    value,
  })),
};
const orgTypeFilterProps = {
  columnKey: 'orgType',
  coverText: 'Org Type',
  defaultSelected: [
    OrganizationTypes.B2B,
    OrganizationTypes.HEALTH,
    OrganizationTypes.HEALTH_PARTNERSHIP,
    OrganizationTypes.PARTNERSHIP,
    OrganizationTypes.SOCIAL_IMPACT,
  ],
  options: Object.values(OrganizationTypes).map((value: string) => ({
    title: value.charAt(0) + value.slice(1).toLowerCase().replace('_', ' '),
    value,
  })),
};

export const Organizations: FC = () => {
  const { windowInnerHeight } = useWindowResize();
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const challengeCreated = useSelector(getChallengeCreated);

  const [search, setSearch] = useLocalStorage<string>('org-search-query', '');
  const [primaryProduct, setFilterSelectedValues] = useLocalStorage<
    PRIMARY_PRODUCT[]
  >('org-search-primary-product', primaryProductFilterProps.defaultSelected);
  const [subscriptionStatus, setSubscriptionStatus] = useLocalStorage<
    SubscriptionStatus[]
  >('org-search-subscription-status', statusFilterProps.defaultSelected);
  const [hierarchy, setHierarchy] = useLocalStorage<HierarchyFilterOptions[]>(
    'org-search-hierarchy',
    hierarchyFilterProps.defaultSelected,
  );
  const [orgTypes, setOrgTypes] = useLocalStorage<OrganizationTypes[]>(
    'org-search-org-types',
    orgTypeFilterProps.defaultSelected,
  );
  const onlyRootOrgs = hierarchy?.[0] === HierarchyFilterOptions.OnlyRootOrgs;

  useEffect(() => {
    dispatch(drain());
    dispatch(resetMembers());
  }, []);

  const query = buildQuery(
    search,
    primaryProduct,
    onlyRootOrgs,
    subscriptionStatus,
    orgTypes,
  );
  const { isFetching, data, fetchNextPage } = useSearchOrganizations(query);
  const organizations: Organization[] = (data?.pages || []).reduce(
    (acc: Organization[], page) => acc.concat(page?.data?.orgs ?? []),
    [],
  );
  const totalOrgCount = data?.pages[0]?.data?.total ?? 0;

  const handleSearch = useCallback(
    (event) => {
      setSearch(event?.target?.value || '');
    },
    [dispatch, setSearch],
  );
  const handleSubscriptionStatus = useCallback(
    (event) => {
      // Janky toggle
      let update = event;
      if (subscriptionStatus.length > 0) {
        update = event.filter((status) => status !== subscriptionStatus[0]);
      }

      setSubscriptionStatus(update);
    },
    [subscriptionStatus],
  );
  const handleCreateOrganization = useCallback(
    () => dispatch(createOrganizationAction()),
    [dispatch],
  );

  const handleSelectOrganization = useCallback(
    (orgId: number) => dispatch(selectOrganizationAction(orgId)),
    [dispatch],
  );

  const loadMoreRows = useCallback(
    ({ startIndex: offset }: { startIndex: number }) => {
      fetchNextPage({ pageParam: offset });
    },
    [dispatch, fetchNextPage],
  );

  const rows = generateRows({
    handleSelectOrganization,
    organizations,
    t: translate,
  });
  const rowRenderer = (data: any) => rendererRow({ ...data });

  const tableSectionProps: TableSectionProps = {
    columnWidthOffset: COLUMN_WIDTH_OFFSET,
    headerRow: generateHeaderRow(translate),
    loadMoreRows,
    rowRenderer,
    rows,
  };

  return (
    <div className="organizations" data-testid="organizations-container">
      <OrganizationsTitle
        title={translate('organizations.title')}
        subtitle={`${totalOrgCount} ${translate('organizations.total')}`}
      />
      <Container data-testid="organizations-table">
        <div css={actionsStyle}>
          <TableFilterBar
            placeholder={translate('organizations.searchOrganizations')}
            handleSearch={handleSearch}
            searchValue={search}
            dataTestId="organizations-filter-input-bar"
          />

          <FilterDropdown
            filterProps={primaryProductFilterProps}
            filterSelectedValues={primaryProduct}
            setFilterSelectedValues={setFilterSelectedValues}
            testId="organization-table-filter-primary-product"
          />
          <FilterDropdown
            filterProps={statusFilterProps}
            filterSelectedValues={subscriptionStatus}
            setFilterSelectedValues={handleSubscriptionStatus}
            testId="organization-table-filter-status"
          />
          <FilterDropdown
            filterProps={hierarchyFilterProps}
            filterSelectedValues={hierarchy}
            setFilterSelectedValues={setHierarchy}
            testId="organization-table-filter-hierarchy"
          />
          <FilterDropdown
            filterProps={orgTypeFilterProps}
            filterSelectedValues={orgTypes}
            setFilterSelectedValues={setOrgTypes}
            testId="organization-table-filter-hierarchy"
          />

          <TextButton
            reg={true}
            icon={
              <Icon
                src={`${AP_CDN_URL}/plus.svg`}
                width={14}
                height={14}
                fill="white"
              />
            }
            value="new"
            onClick={handleCreateOrganization}
            dataTestId="create-organization-button"
          />
        </div>
        <div
          className="table"
          css={tableStyle({ height: windowInnerHeight - HEIGHT_OFFSET })}
        >
          {isFetching && <SpinnerWithBackGround height="70px" width="70px" />}
          {rows.length || isFetching ? (
            <TableSection {...tableSectionProps} />
          ) : (
            <div css={noResultsFoundStyle}>{translate('noResultsFound')}</div>
          )}
        </div>
      </Container>
      {challengeCreated && <Toast text={challengeCreated} />}
    </div>
  );
};
