import * as React from 'react';

import { TitleXS, UIRegularM } from '../../../../../../shared-components/text';
import {
  surveyContainerStyle,
  surveySectionStyle,
  titleSectionStyle,
} from '../../styles/styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { GenerateNewSurvey } from './GenerateNewSurvey';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';

interface TitleSectionPropType {
  hasSurveys?: boolean;
}

export const PanelTitleSection: React.FC<TitleSectionPropType> = ({
  hasSurveys = false,
}) => {
  const { translate } = useTranslate();
  const isSurveysEnable = useFeatureFlag(FEATURE_FLAG.ENABLE_SURVEY);
  const showGenerateNewSurveyComponent = !hasSurveys && isSurveysEnable;

  return (
    <div css={titleSectionStyle}>
      <div css={surveySectionStyle}>
        <TitleXS>{translate('organizationSettings.survey.survey')}</TitleXS>
        <div css={surveyContainerStyle}>
          <UIRegularM>
            {hasSurveys
              ? translate('organizationSettings.survey.surveyTableMessage')
              : translate('organizationSettings.survey.surveyMessage')}
          </UIRegularM>
          <div>{showGenerateNewSurveyComponent && <GenerateNewSurvey />}</div>
        </div>
      </div>
    </div>
  );
};
