import { css } from '@emotion/react';
import type { ChangeEvent, FC, KeyboardEvent } from 'react';
import { useCallback, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import {
  getOrgManagement,
  useChangeOfEnrollmentWithHierarchy,
} from '../../../../../../../state/selectors/organizationSelectors';
import { selectDataUploadFinished } from '../../../../../../../state/slices/restrictByMigration';
import { uploadELFFile } from '../../../../../../../state/slices/restrictByMigration/uploadELFileThunk';
import { visuallyHidden } from '../../../../../styles';

const restrictByUploadButtonStyle = css({
  whiteSpace: 'nowrap',
});

export interface RestrictByUploadButtonProps {
  className?: string;
  text: string;
  onUpload?: () => void;
  propagateChangeOfEnrollmentFlag?: boolean;
}

export const RestrictByUploadButton: FC<RestrictByUploadButtonProps> = ({
  className,
  text,
  onUpload,
  propagateChangeOfEnrollmentFlag = false,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const dispatch = useDispatch();
  const { restrictBy, orgId } = useSelector(getOrgManagement);
  const isLoadingFinished = useSelector(selectDataUploadFinished);
  const canChangeEnrollment = useSelector(useChangeOfEnrollmentWithHierarchy);

  const handleClick = useCallback(() => {
    inputRef.current?.click();
  }, [inputRef]);
  const onKeyDown = useCallback(
    (event: KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        inputRef.current?.click();
      }
    },
    [inputRef],
  );
  const handleFileInput = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { files } = event.target;

      if (files && files[0]) {
        dispatch(
          uploadELFFile({
            eligibilityFile: files[0],
            isChangeOfEnrollment: propagateChangeOfEnrollmentFlag,
            orgId,
            restrictBy,
            useChangeOfEnrollmentWithHierarchy: canChangeEnrollment,
          }),
        );

        if (inputRef && inputRef.current) {
          inputRef.current.value = '';
        }
      }
    },
    [dispatch, orgId, restrictBy, canChangeEnrollment],
  );

  useEffect(() => {
    if (isLoadingFinished) {
      onUpload?.();
    }
  }, [isLoadingFinished, onUpload]);

  return (
    <>
      <div className={className}>
        <TextButton
          dataTestId="elf-upload-button"
          value={text}
          onClick={handleClick}
          onKeyDown={onKeyDown}
          css={restrictByUploadButtonStyle}
        />
      </div>
      <input
        css={visuallyHidden}
        id="restrict-by-upload-file-input"
        name="restrict-by-upload-file-input"
        data-testid="restrict-by-upload-file-input"
        type="file"
        ref={inputRef}
        onChange={handleFileInput}
      />
    </>
  );
};
