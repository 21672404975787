import { useCallback } from 'react';
import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { rem } from '@headspace/web-ui-components';
import env from '../../../../../../config/env';
import { Container } from '../../../../../../shared-components/container/Container';
import { Input } from '../../../../../../shared-components/Input';
import { FileUploader } from '../../../../../../shared-components/FileUploader';
import { Toast } from '../../../../../../shared-components/Toast';
import { SettingsPanel } from '../shared-components/SettingsPanel/SettingsPanel';
import { ORGANIZATION_DETAILS, VIEW_PANEL_COUNT } from '../../../../constants';
import {
  getOrgManagement,
  getSettingsChangedStatus,
} from '../../../../../../state/selectors/organizationSelectors';
import { isCSM as getIsCSM } from '../../../../../../state/selectors/userSelectors';
import {
  cancelOrganizationChanges,
  handleChange as handleChangeAction,
  updateImplicitState,
  toggleEditPanelView,
  handleLogoFileInput as handleLogoFileInputAction,
} from '../../../../../../state/actions/organizationActions';
import { FileUploadInstructions } from '../shared-components/FileUploadInstructions/FileUploadInstructions';
import { ChildRestrictedInput } from '../../../../../../shared-components/ChildRestrictedInput/ChildRestrictedInput';
import { detailsUploadWrapperStyle } from './styles';
import { panelTwoColumnStyle } from '../styles';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { OrgCountrySelect } from '../shared-components/OrgCountrySelect/OrgCountrySelect';
import { HealthHubSelect } from '../shared-components/HealthHubSelect/HealthHubSelect';
import { useFeatureFlag } from '../../../../../../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../../../../../../constants/featureFlags';
import { LinkTo } from '../../../components/LinkTo';
import { saveOrganizationBasic } from '../../../../../../state/actions/organizationActions/saveOrganizationBasic';
import { OrganizationTypes } from '../../../../../../types/organization';

const noPromoFlagged = env.FEATURE_FLAGS.includes('NO_PROMO_EMAILS');

const displayHealthHubExternalId = (healthHubExternalId, healthHub) => {
  return healthHubExternalId && healthHub ? healthHubExternalId : '';
};

export const DetailsPanel: React.FC = () => {
  const {
    cachedOrg,
    gingerId,
    gingerIdError,
    overlappedCareId,
    overlappedCareIdError,
    headspaceHealthId,
    healthHub,
    healthHubExternalId,
    healthHubExternalIdError,
    logoFileError,
    logoFileName,
    logoFilePreview,
    no_promo_emails,
    org_name,
    org_nameError,
    org_type,
    viewPanels = new Array(VIEW_PANEL_COUNT).fill(true),
    externalOrgIdError,
    phoneNumberError,
    org_salesforce_id,
    org_salesforce_idError,
    parentOrgId,
  } = useSelector(getOrgManagement);
  const hasHealthHubExternalIdError =
    (!!healthHub && healthHubExternalId === null) ||
    (!!healthHub && healthHubExternalIdError?.error);

  const hasHealthHubBeenEditedToNone =
    cachedOrg?.healthHub !== '' &&
    cachedOrg?.healthHub !== null &&
    cachedOrg?.healthHub !== healthHub;
  const hasErrors =
    org_nameError.error ||
    logoFileError.error ||
    gingerIdError.error ||
    externalOrgIdError.error ||
    phoneNumberError.error ||
    hasHealthHubExternalIdError ||
    org_salesforce_idError.error;
  const isCSM = useSelector(getIsCSM);
  const settingsChanged = useSelector(getSettingsChangedStatus);
  const isPanelInView = viewPanels[ORGANIZATION_DETAILS];
  const showBundlingToast =
    isPanelInView && !gingerIdError.error && gingerIdError.validated;
  const { translate } = useTranslate();
  const isOverlappedOrgsUpgradeEnabled = useFeatureFlag(
    FEATURE_FLAG.OVERLAPPED_ORGS_UPGRADE,
  );
  const dispatch = useDispatch();

  const handleChange = useCallback((e) => dispatch(handleChangeAction(e)), [
    dispatch,
  ]);

  const handleError = useCallback((e) => dispatch(updateImplicitState(e)), [
    dispatch,
  ]);

  const handleLogoFileInput = useCallback(
    (files: FileList) => dispatch(handleLogoFileInputAction(files)),
    [dispatch],
  );

  const handleCancel = useCallback(
    () => dispatch(cancelOrganizationChanges()),
    [dispatch],
  );

  const handleSaveChanges = useCallback(
    () => dispatch(saveOrganizationBasic()),
    [dispatch],
  );

  const handleToggleView = React.useCallback(
    (index) => {
      dispatch(toggleEditPanelView(index));
    },
    [dispatch],
  );

  return (
    <Container>
      <SettingsPanel
        dataTestId="details-panel"
        title={translate('organizationSettings.basic.title')}
        view={isPanelInView}
        toggleView={handleToggleView}
        cancelChanges={handleCancel}
        saveChanges={handleSaveChanges}
        index={ORGANIZATION_DETAILS}
        displayEditButton={viewPanels.indexOf(false) < 0}
        settingsChanged={settingsChanged}
        hasErrors={hasErrors}
        customSaveChangesMessage={
          hasHealthHubBeenEditedToNone
            ? translate('saveChangesHealthHubMessage')
            : undefined
        }
      >
        <div css={panelTwoColumnStyle}>
          <Input
            type={isPanelInView ? 'review' : 'text'}
            name="org_name"
            placeholder={translate(
              `organizationSettings.basic.namePlaceholder`,
            )}
            label={translate(`organizationSettings.basic.nameLabel`)}
            value={org_name}
            onChange={handleChange}
            onBlur={handleError}
            hasError={org_nameError.error}
            errorMessage={org_nameError.message}
          />
          <OrgCountrySelect editMode={!isPanelInView} onChange={handleChange} />
        </div>
        <div>
          <FileUploader
            css={detailsUploadWrapperStyle}
            fileError={logoFileError}
            view={isPanelInView}
            label={translate('organizationSettings.basic.logoLabel')}
            requirements={translate('organizationSettings.basic.logoDetails')}
            instructions={
              <FileUploadInstructions
                text={translate('organizationSettings.basic.uploadAFile')}
                disabled={false}
              />
            }
            handleFileInput={handleLogoFileInput}
            fileName={logoFileName}
            filePreview={logoFilePreview}
            disabled={isPanelInView}
            options={{
              height: rem(7),
            }}
          />
        </div>
        {isCSM ? (
          <div css={panelTwoColumnStyle}>
            <div>
              <>
                <Input
                  type={isPanelInView ? 'review' : 'text'}
                  name="org_salesforce_id"
                  placeholder={translate(
                    `organizationSettings.basic.salesforceIdPlaceholder`,
                  )}
                  label={translate(
                    `organizationSettings.basic.salesforceIdLabel`,
                  )}
                  value={
                    isPanelInView
                      ? org_salesforce_id || 'N/A'
                      : org_salesforce_id
                  }
                  onChange={handleChange}
                  onBlur={handleError}
                  hasError={org_salesforce_idError.error}
                  errorMessage={org_salesforce_idError.message}
                />

                <Input
                  hasError={false}
                  label={translate(
                    `organizationSettings.basic.headspaceHealthIdLabel`,
                  )}
                  name="headspaceHealthId"
                  placeholder=""
                  type={isPanelInView ? 'review' : 'text'}
                  disabled={true}
                  value={headspaceHealthId}
                />
                <ChildRestrictedInput
                  name="org_type"
                  type={isPanelInView ? 'review' : 'select'}
                  label={translate(`organizationSettings.basic.typeLabel`)}
                  value={
                    isPanelInView
                      ? (translate(
                          `organizationSettings.basic.types.${org_type}`,
                        ) as string)
                      : org_type
                  }
                  onChange={handleChange}
                  options={Object.values(OrganizationTypes).map(
                    (value: string) => {
                      return {
                        title: `${translate(
                          `organizationSettings.basic.types.${value}`,
                        )}`,
                        value,
                      };
                    },
                  )}
                />
                {isOverlappedOrgsUpgradeEnabled ? (
                  <Input
                    type={isPanelInView ? 'review' : 'number'}
                    name="overlappedCareId"
                    placeholder={translate(
                      `organizationSettings.basic.overlappedCareIdPlaceholder`,
                    )}
                    label={translate(
                      `organizationSettings.basic.overlappedCareIdLabel`,
                    )}
                    value={overlappedCareId ?? undefined}
                    onChange={handleChange}
                    onBlur={handleError}
                    hasError={overlappedCareIdError.error}
                    errorMessage={overlappedCareIdError.message}
                    link={
                      overlappedCareId && (
                        <LinkTo
                          href={`${env.GINGER_WEB_API.url}/admin/employer/employer/${overlappedCareId}/`}
                          dataTestId="django-overlapped-org-link"
                          isVisible={true}
                          text="Django"
                        />
                      )
                    }
                    tooltip={{
                      message: translate(
                        `organizationSettings.basic.overlappedCareIdTooltip`,
                      ),
                    }}
                  />
                ) : null}
              </>
            </div>
            <div>
              <>
                <ChildRestrictedInput
                  type={isPanelInView ? 'review' : 'text'}
                  disabled={true}
                  name="gingerId"
                  placeholder={translate(
                    `organizationSettings.basic.gingerIdPlaceholder`,
                  )}
                  label={translate(`organizationSettings.basic.gingerIdLabel`)}
                  value={gingerId || ''}
                  onChange={handleChange}
                  onBlur={handleError}
                  hasError={gingerIdError.error}
                  errorMessage={gingerIdError.message}
                  showAsTooltip={true}
                  link={
                    !Number.isNaN(parseInt(gingerId, 10)) ? (
                      <LinkTo
                        href={`${env.GINGER_WEB_API.url}/admin/employer/employer/${gingerId}/change/`}
                        dataTestId="django-org-link"
                        isVisible={true}
                        text="Django"
                      />
                    ) : null
                  }
                />
                {showBundlingToast ? (
                  <Toast
                    text={translate(
                      'organizationSettings.basic.connectedEligibleToast',
                    )}
                  />
                ) : null}
              </>
              <div>
                <HealthHubSelect
                  onChange={handleChange}
                  value={healthHub}
                  editMode={!isPanelInView}
                  disabled={!!healthHub}
                />
                {!!healthHub && (
                  <ChildRestrictedInput
                    type={isPanelInView ? 'review' : 'text'}
                    name="healthHubExternalId"
                    placeholder="ID"
                    label="HEALTH HUB EXTERNAL ID"
                    hasError={healthHubExternalIdError?.error}
                    errorMessage={healthHubExternalIdError?.message}
                    onChange={handleChange}
                    onBlur={handleError}
                    value={
                      isPanelInView
                        ? healthHubExternalId || 'N/A'
                        : displayHealthHubExternalId(
                            healthHubExternalId,
                            healthHub,
                          )
                    }
                    disabled={!!healthHub && !!parentOrgId}
                    showAsTooltip={true}
                  />
                )}
              </div>
              <Input
                disabled={noPromoFlagged}
                label={translate(
                  `organizationSettings.basic.noPromoEmailsLabel`,
                )}
                name="no_promo_emails"
                onChange={handleChange}
                type={isPanelInView ? 'review' : 'checkbox'}
                value={
                  isPanelInView
                    ? `${translate(no_promo_emails ? 'yes' : 'no')}`
                    : no_promo_emails
                }
              />
            </div>
          </div>
        ) : null}
      </SettingsPanel>
    </Container>
  );
};
