import * as React from 'react';
import { useTranslate } from '../../hooks/useTranslate';
import { CLINICAL_COVERAGE_TYPE } from '../../types/organization';
import { ExtendedInputType, Input } from '../Input';
import { ADD_ON_OPTIONS } from './constants';

type AddOnOptionsInputProps = {
  value: CLINICAL_COVERAGE_TYPE;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  disable?: boolean;
  term: string;
  type?: ExtendedInputType;
};

export const AddOnOptionsInput = ({
  value = CLINICAL_COVERAGE_TYPE.NONE,
  onChange,
  disable,
  term,
  type = 'select',
}: AddOnOptionsInputProps) => {
  const { translate } = useTranslate();
  const name = `${term}_contract_clinical_coverage_type`;
  const label = translate('organizationSettings.enrollment.addOnsLabel');

  const addOnOptions = ADD_ON_OPTIONS.map((option) => ({
    ...option,
    title: translate(option.titleKey),
  }));

  return (
    <div>
      <Input
        name={name}
        type={type}
        label={label}
        value={value}
        onChange={onChange}
        options={addOnOptions}
        disabled={disable}
      />
    </div>
  );
};
