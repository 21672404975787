import { createSlice } from '@reduxjs/toolkit';
import {
  emailCheck,
  isValidDob,
  isValidEapPhoneNumber,
} from '../../../../utils/validations';
import { addDays } from '../../../../utils/dates';
import { OPTIONAL_INPUT_TYPES } from '../../constants/eapServiceRequestConfig';
import { CLINICAL_COVERAGE_PROVIDERS } from '../../constants/eapMemberDetails';

export interface ServiceRequestState {
  formData: {
    clientServiceId: number;

    // demographic
    company: string;
    dob: string;
    firstName: string;
    gender: string;
    lastName: string;
    maritalStatus: string;
    preferredLanguage: string;

    // address
    street: string;
    city: string;
    state: string;
    zipcode: string;
    distanceToTravel: string;

    // contact
    phoneNumber: string;
    email: string;
    voicemailPreference: string;
    dayPreference: Date[];
    weekdayTimePref: string;
    weekendTimePref: string;
    timePreference: string; // in person therapy weekend/weekday

    // wellness questions
    wellnessQuestion1: number;
    wellnessQuestion2: number;
    wellnessQuestion3: number;
    wellnessQuestion4: number;

    // needs
    needsTopic: string;

    // therapist preferences
    therapistPreference: string;
  };
  errors: {
    [key: string]: string;
  };
  touched: {
    [key: string]: boolean;
  };
  isSubmitting: boolean;
  submitSuccess: boolean;
}

export const initialState: ServiceRequestState = {
  errors: {},

  formData: {
    city: '',
    clientServiceId: 0,
    company: '',
    dayPreference: [addDays(new Date(), 5)],
    distanceToTravel: '',
    dob: '',
    email: '',
    firstName: '',
    gender: '',
    lastName: '',
    maritalStatus: '',
    needsTopic: '',
    phoneNumber: '',
    preferredLanguage: '',
    state: '',
    street: '',
    therapistPreference: '',
    timePreference: '',
    voicemailPreference: '',
    weekdayTimePref: '',
    weekendTimePref: '',
    wellnessQuestion1: 5,
    wellnessQuestion2: 5,
    wellnessQuestion3: 5,
    wellnessQuestion4: 5,
    zipcode: '',
  },
  isSubmitting: false,
  submitSuccess: false,
  touched: {},
};

const formSlice = createSlice({
  initialState,
  name: 'eapRequestForm',
  reducers: {
    resetServiceRequestForm: (state) => {
      state.formData = initialState.formData;
      state.errors = initialState.errors;
    },
    updateFormField: (state, action) => {
      const { key, value, provider } = action.payload;
      state.touched[key] = true; // Mark the field as touched
      state.errors[key] = validateField(key, value, provider);
      state.formData[key] = value;
    },
  },
});

const isNonEmptyString = (value: string | boolean | number) => {
  return typeof value === 'string' && value.trim() !== '';
};

const isNumber = (value: any): boolean => {
  return typeof value === 'number';
};

const isArrayOfValidDateObjects = (value: any): boolean => {
  if (!Array.isArray(value)) {
    return false;
  }
  return value.every(
    (val) => val instanceof Date && !Number.isNaN(val.getTime()),
  );
};

export const validateField = (
  key: string,
  value: string | boolean | number,
  provider?: CLINICAL_COVERAGE_PROVIDERS,
): string => {
  if (
    key !== OPTIONAL_INPUT_TYPES.THERAPIST_PREFERENCE &&
    !isNonEmptyString(value) &&
    !isNumber(value) &&
    !isArrayOfValidDateObjects(value)
  ) {
    return `headspaceHub.forms.validation.required.${key}`;
  }

  if (key === 'dob' && typeof value === 'string' && !isValidDob(value)) {
    return 'enrollment.verifyIdentity.dob.invalidDob';
  }

  if (
    key === 'phoneNumber' &&
    typeof value === 'string' &&
    !isValidEapPhoneNumber(value, provider)
  ) {
    return 'headspaceHub.forms.validation.invalid.phoneNumber';
  }

  if (key === 'email' && typeof value === 'string' && !emailCheck(value)) {
    return 'headspaceHub.forms.validation.invalid.email';
  }

  return '';
};

export const { updateFormField, resetServiceRequestForm } = formSlice.actions;
export default formSlice.reducer;
