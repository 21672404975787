import axios, { AxiosPromise } from 'axios';
import { TaxMode } from '@headspace/web-payments';
import { API_HOST, POST, GET } from '../../../utils/constants';
import { SSOOrgs } from '../state/actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';
import { EnrollmentVerification } from '../constants/enrollmentVerification';
import { HealthProvider } from '../constants/healthProvider';
import { SupportedLanguageCode } from '../../../types/language';
import { PostIdentityVerificationsResponse } from './postIdentityVerifications';

const B2B_API = {
  CHECK_MEMBER_ENROLLMENT_STATUS: `${API_HOST}/b2b/v2/org/enroll/check-member-enrollment-status`,
  SSO_ENROLLMENT: `${API_HOST}/b2b/v2/org/enroll/get-sso-enrollment-url`,
  SSO_COMPLETE: `${API_HOST}/b2b/member/sso/complete`,
};

const PARTNERSHIP_API = {
  BASE_URL: `${API_HOST}/health-partnership/v1`,
  SOLERA_CONNECT: `${API_HOST}/health-partnership/v1/partnership/solera/connect`,
};

const getHealthPartnershipUrl = (partnerId: string) => ({
  BASE_URL: `${API_HOST}/health-partnership/v1`,
  LOOKUP_CONNECT: `${API_HOST}/health-partnership/v1/users/lookup/${partnerId}/connect`,
});

const getB2BUrl = (slug: string) => {
  const ENROLL_URL = `${API_HOST}/b2b/v2/org/enroll/${slug}`;

  return {
    VERIFY_BY_ID: `${ENROLL_URL}/enroll-by-id`,
    VERIFY_BY_EMAIL: `${ENROLL_URL}/verify-by-email`,
    LINK_MEMBER: `${ENROLL_URL}/link-member`,
    PAY_COMPLETE: `${API_HOST}/b2b/org/${slug}/paycomplete`,
  };
};

const SUBSCRIPTION_API = {
  USER_SUBSCRIPTION_STATUS: `${API_HOST}/subscription/user-subscriptions`,
  USER_PAYMENT_METHOD: `${API_HOST}/subscription/payments/web/payment-methods/default`,
  CANCEL_V2_AUTO_RENEW: `${API_HOST}/subscription/user-subscriptions/cancel`,
  CANCEL_V3_AUTO_RENEW: `${API_HOST}/subscription/payments/web/subscriptions`,
  SUBSCRIPTION_API_PAYMENTS: `${API_HOST}/subscription/payments/web/subscriptions`,
};

const USER_SETTINGS_API = {
  GET_LANGUAGE: `${API_HOST}/user/user-settings`,
  UPDATE_LANGUAGE: `${API_HOST}/user/user-settings/save`,
  PAYMENT_INFO: `${API_HOST}/user/users`,
};

export const createHeaders = (JWT?: string) => ({
  ...(JWT ? { authorization: `Bearer ${JWT}` } : undefined),
  'content-type': 'application/json; charset=utf-8',
});

export const postLinkMember = (
  JWT: string,
  verificationToken: string,
  slug: string,
) =>
  axios({
    method: POST,
    url: getB2BUrl(slug).LINK_MEMBER,
    data: { token: verificationToken },
    headers: createHeaders(JWT),
  });

export const postVerifyByEmail = async (
  JWT: string,
  dataPayload: {
    registeredEmail: string;
    workEmail: string;
    language: SupportedLanguageCode;
  },
  slug: string,
) =>
  axios({
    method: POST,
    url: getB2BUrl(slug).VERIFY_BY_EMAIL,
    data: dataPayload,
    headers: createHeaders(JWT),
  });

export const postVerifyById = async (
  JWT: string,
  dataPayload: { id: string; name: string },
  slug: string,
) =>
  axios({
    method: POST,
    url: getB2BUrl(slug).VERIFY_BY_ID,
    data: dataPayload,
    headers: createHeaders(JWT),
  });

export const getEnrollmentStatus = (JWT: string) =>
  axios({
    method: GET,
    url: B2B_API.CHECK_MEMBER_ENROLLMENT_STATUS,
    headers: createHeaders(JWT),
  });

export const getCheckAutoRenewStatus = (JWT: string, userId: string) =>
  axios({
    method: GET,
    url: `${SUBSCRIPTION_API.USER_SUBSCRIPTION_STATUS}?userId=${userId}`,
    headers: createHeaders(JWT),
  });

export const postCancelV3AutoRenew = (JWT: string, subId: string) =>
  axios({
    method: POST,
    url: `${SUBSCRIPTION_API.CANCEL_V3_AUTO_RENEW}/${subId}/cancellation`,
    data: {
      cancellationType: 'Immediate',
    },
    headers: createHeaders(JWT),
  });

export const postCancelV2AutoRenew = (JWT: string, userId: string, subId) =>
  axios({
    method: POST,
    url: `${SUBSCRIPTION_API.CANCEL_V2_AUTO_RENEW}?userId=${userId}`,
    data: {
      userSubscriptionId: subId,
      immediate: false,
    },
    headers: createHeaders(JWT),
  });

export const getLanguagePreference = (JWT: string, userId: string) =>
  axios({
    method: GET,
    url: `${USER_SETTINGS_API.GET_LANGUAGE}?settingCategory=I18N&settingKey=LANGUAGE_PREFERENCE&userId=${userId}`,
    headers: createHeaders(JWT),
  });

export const postChangeLanguage = (
  JWT: string,
  userId: string,
  languageCode: string,
) =>
  axios({
    method: POST,
    url: USER_SETTINGS_API.UPDATE_LANGUAGE,
    data: {
      userId,
      userSettings: [
        {
          settingCategory: 'I18N',
          settingKey: 'LANGUAGE_PREFERENCE',
          settingValue: languageCode,
        },
      ],
    },
    headers: createHeaders(JWT),
  });

export function fetchVoucherInfo(voucher: string) {
  return axios({
    method: GET,
    url: `${API_HOST}/subscription/vouchers/${voucher}`,
  });
}

export const postPayment = (
  JWT: string,
  data: {
    userId: string;
    taxMode: TaxMode;
    voucherCode: string;
    upgrade: boolean;
  },
) =>
  axios({
    method: POST,
    url: SUBSCRIPTION_API.SUBSCRIPTION_API_PAYMENTS,
    data,
    headers: createHeaders(JWT),
  });

export const linkMemberAfterPayment = (JWT: string, slug: string) =>
  axios({
    url: getB2BUrl(slug).PAY_COMPLETE,
    method: POST,
    data: {
      JWT,
    },
    headers: createHeaders(JWT),
  });

export const getPaymentMethod = (JWT: string, userId) =>
  axios({
    url: `${SUBSCRIPTION_API.USER_PAYMENT_METHOD}?userId=${userId}`,
    method: GET,
    headers: createHeaders(JWT),
  });

export const getUserIP = () =>
  axios({
    method: GET,
    url: 'https://api.ipify.org',
  });

export const getSSOEnrollmentData = (
  JWT: string,
  healthHub,
  healthHubExternalId,
): AxiosPromise<SSOOrgs> =>
  axios({
    method: GET,
    url: `${B2B_API.SSO_ENROLLMENT}?healthHub=${healthHub}&healthHubExternalId=${healthHubExternalId}`,
    headers: createHeaders(JWT),
  });

export const postSSOComplete = (
  JWT: string,
  healthHub: string,
  healthHubExternalId: string,
) =>
  axios({
    method: POST,
    url: `${B2B_API.SSO_COMPLETE}`,
    headers: createHeaders(JWT),
    data: {
      healthHub: String(healthHub),
      healthHubExternalId: String(healthHubExternalId),
    },
  });

export const postHealthProviderConnect = (props: {
  JWT: string;
  data: {
    partnerId: HealthProvider;
    countryCode?: string;
    partnerUserId: string | null;
    email?: string;
    uniqueKey: string | null;
    planId?: string;
  };
}) => {
  const { partnerId, uniqueKey, ...otherProps } = props.data;
  const data = uniqueKey ? { uniqueKey } : { ...otherProps, partnerId };
  const url =
    partnerId === HealthProvider.SOLERA
      ? `${getHealthPartnershipUrl(partnerId).LOOKUP_CONNECT}`
      : `${PARTNERSHIP_API.BASE_URL}/users/provided/${partnerId}/connect`;

  return axios({
    data,
    headers: createHeaders(props.JWT),
    method: POST,
    url,
  });
};

export const postHealthProviderLookupConnect = (
  partnerId: HealthProvider,
  uniqueKey: string,
  JWT?: string,
): AxiosPromise =>
  axios({
    data: { uniqueKey },
    headers: createHeaders(JWT),
    method: POST,
    url: `${getHealthPartnershipUrl(partnerId).LOOKUP_CONNECT}`,
  });

export const postGingerSoleraConnect = (lookupKey: string): AxiosPromise =>
  axios({
    method: POST,
    url: `${PARTNERSHIP_API.SOLERA_CONNECT}`,
    data: {
      lookupKey,
    },
  });

// TODO: Integrate with Create: POST /b2b/v3/orgs/{slug}/enrollment-verifications
export const postEmailEnrollmentVerification = (
  slug: string,
  workEmail: string,
  language: SupportedLanguageCode,
  eapLanguagePreference: string,
) =>
  axios({
    method: POST,
    url: `${API_HOST}/b2b/v3/orgs/${slug}/enrollment-verifications`,
    data: {
      enrollmentType: EnrollmentVerification.EMAIL,
      workEmail,
      language,
      eapLanguagePreference,
    },
  });

// TODO: Integrate with Create: POST /b2b/v3/orgs/{slug}/enrollment-verifications
export const postWorkIdEnrollmentVerification = (
  slug: string,
  workId: string,
  lastName: string,
) =>
  axios({
    method: POST,
    url: `${API_HOST}/b2b/v3/orgs/${slug}/enrollment-verifications`,
    data: {
      enrollmentType: EnrollmentVerification.ID,
      workId,
      lastName,
    },
  });

export type EnrollmentByPiiPayload = {
  firstName: string;
  lastName: string;
  dateOfBirth: string;
  country: string;
  zipCode?: string;
};
export const postPiiEnrollmentVerification = (
  slug: string,
  pii: EnrollmentByPiiPayload,
) =>
  axios({
    method: POST,
    url: `${API_HOST}/b2b/v3/orgs/${slug}/enrollment-verifications`,
    data: {
      enrollmentType: EnrollmentVerification.PII,
      firstName: pii.firstName,
      lastName: pii.lastName,
      preferredDob: pii.dateOfBirth,
      preferredCountry: pii.country,
      zipCode: pii.zipCode,
    },
  });

export interface VpEnrollmentVerificationPayload {
  workId: string;
  lastName: string;
  slug?: string;
  preferredDob?: string;
  preferredCountry?: string;
}

export interface VpEnrollmentVerificationResponse {
  orgId: number;
  slug: string;
  member: PostIdentityVerificationsResponse;
}

export const postVpEnrollmentVerification = (
  payload: VpEnrollmentVerificationPayload,
): AxiosPromise<VpEnrollmentVerificationResponse> =>
  axios({
    data: payload,
    method: POST,
    url: `${API_HOST}/b2b/v3/orgs/vp-enrollment-verifications`,
  });
