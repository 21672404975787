import { FC, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/browser';
import { getCookie } from '@headspace/web-auth';
import {
  postEmailEnrollmentVerification,
  postWorkIdEnrollmentVerification,
} from '../../../../rest-calls';
import { EnrollmentVerification } from '../../../../constants/enrollmentVerification';
import EligibilityVerifyByEmail from './EligibilityVerifyByEmail';
import { setEnrollmentVerificationConfirmation } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import { RestrictBy } from '../../../../../../types/organization';
import EligibilityVerifyByWorkId from './EligibilityVerifyByWorkId';
import { EligibilityVerifyByKpJwt } from './EligibilityVerifyByKpJwt';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { ErrorResponse, parseError } from '../../../../../../utils/parseError';
import { getKPNationalClaims } from '../../../../state/selectors/kpNationalSelector/kpNationalSelector';
import {
  bottomImageStyle,
  verificationPrimaryContainerStyle,
} from '../../styles/pageStyles';
import {
  getOrgRestrictedBy,
  getSlug,
} from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';
import { getLanguage } from '../../../../state/selectors/languageSelector/languageSelector';
import { verificationScreenViewAction } from '../../../../../../analytics/events/enrollmentEvents';
import { SupportedLanguageCode } from '../../../../../../types/language';
import { CookieKeys } from '../../../../../../utils/constants';
import { EMAIL_VERIFICATION_STEPS } from '../../constants';
import { useGetCountryCode } from '../../../../../../hooks/useGetCountryCode';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const { VERIFY_EMAIL, RESEND_EMAIL } = EMAIL_VERIFICATION_STEPS;

export const EligibilityVerifyPrimary: FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const navigate = useNavigate();
  const restrictedBy = useSelector(getOrgRestrictedBy);
  const languageCode = useSelector(getLanguage);
  const kpJWT = useSelector(getKPNationalClaims);
  const slug = useSelector(getSlug);
  const [error, setError] = useState('');
  const [step, setStep] = useState(VERIFY_EMAIL);
  const { data: countryCode } = useGetCountryCode();

  useEffect(() => {
    dispatch(verificationScreenViewAction(restrictedBy));
  }, [dispatch, restrictedBy]);

  const handleEmailVerification = async (email) => {
    try {
      const DEFAULT_LANGUAGE = 'en';
      const langCookie = getCookie(CookieKeys.HUB_LANGUAGE);
      const defaultLanguage = langCookie || DEFAULT_LANGUAGE;
      const language = SupportedLanguageCode[languageCode]
        ? languageCode
        : defaultLanguage;
      const eapLanguagePreferences = `${langCookie}-${countryCode}`;

      await postEmailEnrollmentVerification(
        slug,
        email,
        language,
        eapLanguagePreferences,
      );
    } catch (error) {
      Sentry.addBreadcrumb({
        message: `handleEmailVerification e - postEmailEnrollmentVerification: ${error}`,
      });
      throw error;
    }
  };

  const handleWorkIdVerification = async (employeeId, lastName) => {
    try {
      await postWorkIdEnrollmentVerification(slug, employeeId, lastName);
      dispatch(
        setEnrollmentVerificationConfirmation({
          lastName,
          method: EnrollmentVerification.ID,
          workId: employeeId,
        }),
      );
      navigate(`/${slug}/member-enroll/verified-account`);
    } catch (error) {
      const { message, errorStringCode } = parseError(error as ErrorResponse);

      if (errorStringCode === 'MEMBER_NOT_FOUND') {
        setError(translate('enrollment.errors.NOT_FOUND'));
      }
      Sentry.addBreadcrumb({
        message: `handleWorkIdVerification - postWorkIdEnrollmentVerification : ${message}`,
      });
    }
  };

  const onChangeHandler = () => {
    setError('');
  };

  const bottomImgSrc =
    step === RESEND_EMAIL
      ? `${ENROLLMENT_CDN_URL}/eligibility-verify-by-email-resend-step-footer.png`
      : `${ENROLLMENT_CDN_URL}/eligibility-verify-by-email-input-step-footer.png`;

  return (
    <div
      data-testid="eligibility-verification-primary"
      css={verificationPrimaryContainerStyle}
    >
      {[
        RestrictBy.EMPLOYEE_ID,
        RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
      ].includes(restrictedBy) ? (
        <EligibilityVerifyByWorkId
          serverError={error}
          onChangeHandler={onChangeHandler}
          handleVerify={handleWorkIdVerification}
        />
      ) : [RestrictBy.KP_NATIONAL_JWT].includes(restrictedBy) ? (
        <EligibilityVerifyByKpJwt kpJwt={kpJWT} slug={slug} />
      ) : (
        <EligibilityVerifyByEmail
          handleVerify={handleEmailVerification}
          step={step}
          setVerifyStep={setStep}
        />
      )}
      <img
        css={bottomImageStyle}
        src={bottomImgSrc}
        alt={translate('eligibility.verify.byEmail.bottomImageAltText')}
        data-testid="footer-eligibility-illustration"
        role="presentation"
      />
    </div>
  );
};
