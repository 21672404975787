import { create } from '../../auth/Auth';
import {
  UserActionsTypes,
  UserAuthorizeAction,
  UserFetchInfoAction,
  UserInfoAction,
  UserInfoInterface,
  UserLoginAction,
  UserLoginActionV2,
  UserLoginErrorAction,
  UserLoginErrorResetAction,
  UserLoginSuccessAction,
  UserProcessAction,
  UserProcessActionV2,
  UserProcessIDPAction,
} from '../types/user';
import { ThunkDispatch, ThunkResult } from '../types';

export const userLoginSuccess = (
  authToken: string | null,
): UserLoginSuccessAction => ({
  authToken,
  type: UserActionsTypes.USER_LOGIN_SUCCESS,
});

export const userLoginError = (error: Error): UserLoginErrorAction => ({
  error,
  type: UserActionsTypes.USER_LOGIN_ERROR,
});

export const userLoginErrorReset = (): UserLoginErrorResetAction => ({
  type: UserActionsTypes.USER_LOGIN_ERROR_RESET,
});

export const userLogin = (
  email: string,
  password: string,
): UserLoginAction => ({
  email,
  password,
  type: UserActionsTypes.USER_LOGIN,
});

export const userLoginV2 = (): UserLoginActionV2 => ({
  type: UserActionsTypes.USER_LOGIN_V2,
});

export const userAuthorize = (connection: string): UserAuthorizeAction => ({
  connection,
  type: UserActionsTypes.USER_AUTHORIZE,
});

export const userProcess = (accessToken: string): UserProcessAction => ({
  accessToken,
  type: UserActionsTypes.USER_PROCESS,
});

export const userProcessV2 = (): UserProcessActionV2 => ({
  type: UserActionsTypes.USER_PROCESS_V2,
});

export const userProcessIDP = (connection: string): UserProcessIDPAction => ({
  connection,
  type: UserActionsTypes.USER_PROCESS_IDP,
});

export const userFetchInfo = (): UserFetchInfoAction => ({
  type: UserActionsTypes.USER_FETCH_INFO,
});

export const userInfo = (resp: UserInfoInterface): UserInfoAction => ({
  resp,
  type: UserActionsTypes.USER_INFO,
});

export const userLogout = (featureFlag?: boolean): ThunkResult<void> => {
  return (dispatch: ThunkDispatch) => {
    const auth = create();

    if (featureFlag) {
      auth.logoutV2();
    } else {
      auth.logout();
      dispatch({
        type: UserActionsTypes.USER_LOGOUT,
      });
    }
  };
};
