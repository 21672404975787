import { Action } from 'redux';

import { PRIMARY_PRODUCT } from '../../apps/dashboard/constants';
import {
  Organization,
  SubscriptionStatus,
  OrganizationTypes,
} from '../../types/organization';

export type OrganizationsAction =
  | FetchOrganizationsAction
  | CreateOrganizationAction
  | SelectOrganizationAction;

export enum OrganizationsActionsTypes {
  RECEIVED_ORGANIZATIONS = 'RECEIVED_ORGANIZATIONS',
  UPDATE_ORGANIZATIONS = 'UPDATE_ORGANIZATIONS',
  CREATE_ORGANIZATION = 'CREATE_ORGANIZATION',
  SELECT_ORGANIZATION = 'SELECT_ORGANIZATION',
  IS_FETCHING_ORGANIZATIONS = 'IS_FETCHING_ORGANIZATIONS',
  SEARCH_ORGANIZATIONS = 'SEARCH_ORGANIZATIONS',
}

export interface FetchOrganizationsQuery {
  search?: string;
  primaryProduct?: PRIMARY_PRODUCT[];
  onlyRootOrgs?: boolean;
  orgSubscriptionStatus?: SubscriptionStatus;
  orgTypes?: OrganizationTypes[];
}

export interface FetchOrganizationsAction extends Action {
  type: string;
  payload: { OrganizationList: Organization[] };
}

export interface UpdateOrganizationsAction {
  type: string;
  payload: { OrganizationList: Organization[] };
}

export interface CreateOrganizationAction {
  type: string;
  payload: null;
}

export interface SelectOrganizationAction {
  type: string;
  payload: null;
}
