import { SpacingInRemBySize, WarmGrey } from '@headspace/web-ui-components';
import { FormattedMessage } from 'react-intl';
import { useCallback, useState } from 'react';
import Collapsible from 'react-collapsible';
import { css } from '@emotion/react';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import {
  arrowIcon,
  bundledInfoCopy,
  bundledInfoCopyAltText,
  bundledInfoCopyTitle,
  bundledInfoImage,
  bundledInfoPicture,
  bundledItemRow,
} from '../styles/verificationPageStyleUmdWfm';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { LandingEAPUmdWfm } from './LandingEAPUmdWfm';
import { LandingFAQ } from './LandingFAQ';
import {
  getActiveOrg,
  getClinicalCoverageType,
} from '../../../../../../state/selectors/organizationSelectors';
import { CLINICAL_COVERAGE_TYPE } from '../../../../../../types/organization';
import { ENROLLMENT_CDN_URL } from '../../../../constants/routes';

const subHeaderStyles = css({
  color: WarmGrey[400],
  margin: `${SpacingInRemBySize.L} 0`,
});

export const BundledAppInfoUmdWfm = () => {
  const { translate } = useTranslate();
  const [isExpanded, setExpanded] = useState(false);

  const setTranslate = useCallback(() => {
    return isExpanded
      ? translate('enrollment.landingPage.bundledInfo.hideResources')
      : translate('enrollment.landingPage.bundledInfo.viewResources');
  }, [isExpanded, translate]);

  const handleOpenEAP = useCallback(() => setExpanded(true), []);
  const handleCloseEAP = useCallback(() => setExpanded(false), []);

  const clinicalCoverageType = useSelector(getClinicalCoverageType);
  const isCoverageEap = clinicalCoverageType === CLINICAL_COVERAGE_TYPE.EAP_V2;

  return (
    <>
      <LandingFAQ />
      {isCoverageEap ? (
        <div css={bundledItemRow} data-testid="bundled-app-info">
          <div css={bundledInfoCopy}>
            <div css={bundledInfoCopyTitle}>
              {translate(
                'enrollment.landingPage.eap.globalEnrollment.services.header',
              )}
            </div>
            <p css={subHeaderStyles}>
              <FormattedMessage
                id="enrollment.landingPage.eap.globalEnrollment.subHeader"
                values={{
                  b: (chunks) => <b css={bundledInfoCopyAltText}>{chunks}</b>,
                }}
              />
            </p>

            <Collapsible
              open={isExpanded}
              lazyRender={true}
              transitionTime={300}
              onTriggerOpening={handleOpenEAP}
              onTriggerClosing={handleCloseEAP}
              trigger={
                <button
                  data-testid="collapsible-btn"
                  css={arrowIcon(isExpanded)}
                  type="button"
                >
                  {setTranslate()}
                </button>
              }
            >
              <LandingEAPUmdWfm clinicalResourcesSupported={isCoverageEap} />
            </Collapsible>
          </div>

          <picture css={bundledInfoPicture}>
            <source
              css={bundledInfoImage}
              srcSet={`${ENROLLMENT_CDN_URL}/umd-work-life-resources.webp`}
              type="image/webp"
            />
            <source
              css={bundledInfoImage}
              srcSet={`${ENROLLMENT_CDN_URL}/umd-work-life-resources.svg`}
              type="image/svg"
            />
            <source
              css={bundledInfoImage}
              srcSet={`${ENROLLMENT_CDN_URL}/umd-work-life-resources.png`}
              type="image/png"
            />
            <img
              css={bundledInfoImage}
              src={`${ENROLLMENT_CDN_URL}/umd-work-life-resources.webp`}
              role="presentation"
              alt=""
            />
          </picture>
        </div>
      ) : null}
    </>
  );
};
