import { useSelector } from 'react-redux';
import { PRIMARY_PRODUCT } from '../apps/dashboard/constants';
import {
  AvailableLanguagesType,
  HubSupportedLanguageCode,
  SupportedLanguageCode,
} from '../types/language';
import { getPrimaryProduct } from '../apps/memberEnrollmentV2/state/selectors/orgSectionSelector/orgSectionSelector';
import { hasClinicalCoverage } from '../state/selectors/organizationSelectors';

export const useGetAllAvailableLanguages = (): AvailableLanguagesType => {
  const isOnboarding = /onboarding/.test(window.location.pathname);
  const primaryProduct = useSelector(getPrimaryProduct);
  const hasClinicalCoverages = useSelector(hasClinicalCoverage);

  if (isOnboarding) {
    return SupportedLanguageCode;
  }

  if (
    hasClinicalCoverages &&
    primaryProduct === PRIMARY_PRODUCT.FUSION &&
    !isOnboarding
  ) {
    return HubSupportedLanguageCode;
  }
  return SupportedLanguageCode;
};
