import { FC, useCallback, useEffect, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  White,
  Breakpoint,
  useActiveBreakpoint,
  WarmGrey,
} from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { PageWrapper } from '../onboarding-shared-components/OnboardingPageWrapper';
import {
  twoColumStyle,
  leftPaneStyle,
  rightPaneStyle,
  illustrationStyle,
  squareImageStyle,
  nextButtonWrapperStyle,
  nextButtonDesktopStyle,
} from './styles/greetingViewStyle';
import { OnboardingFooter } from '../onboarding-shared-components/OnboardingFooter';
import {
  ENROLLMENT_CDN_URL,
  getUnifiedEligibilityRoutes,
} from '../../../../constants/routes';
import {
  onboardingButtonClickthrough,
  onboardingFlowStartGreetingsScreenViewAction,
} from '../../../../../../analytics/events/onboardingEvents';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { ImmediateCrisisSupportSection } from '../onboarding-shared-components/ImmediateCrisisSupportSection';
import { useFusionImmediateCrisisSupport } from '../../../../../../featureFlags/fusionImmediateCrisisSupport';
import { getFirstName } from '../../../../state/selectors/memberAuthenticationSelector/memberAuthenticationSelector';
import { getSlug } from '../../../../state/selectors/orgSectionSelector/orgSectionSelector';

/*
 Things to show on the onboarding flow
 1. Loader gif - Breathing animation
 2. Greeting the user - Get Started
 3. Start of the 5 onboarding questions
*/

const INITIAL_FLOW_COMPLETION_PROGRESS = 10;

export const GreetingView: FC = () => {
  const fusionImmediateCrisisSupport = useFusionImmediateCrisisSupport();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const slug = useSelector(getSlug);
  const firstName = (useSelector(getFirstName) || 'there') as string;
  const { ONBOARDING_QUESTIONS_PATH } = getUnifiedEligibilityRoutes(slug);
  const activeBreakpoint = useActiveBreakpoint();
  const isMobileView = useMemo(() => activeBreakpoint === Breakpoint.MOBILE, [
    activeBreakpoint,
  ]);
  const showImmediateCrisisSection =
    isMobileView && fusionImmediateCrisisSupport;

  const { translate } = useTranslate();
  /*
    Things to check
      if page is refreshed
        - check if user is still logged in, if not redirect to initial verify page.
        - refetch org activeOrg data and if there are any error on enrollment conditions redirect to /member-enroll the page will handle org errors eg. seats are full
  */

  const handleNextButtonClick = useCallback(
    (event) => {
      const buttonText = event.target.innerText;
      dispatch(onboardingButtonClickthrough(buttonText));
      navigate(`${ONBOARDING_QUESTIONS_PATH}/1`);
    },
    [ONBOARDING_QUESTIONS_PATH, navigate, dispatch],
  );

  useEffect(() => {
    dispatch(onboardingFlowStartGreetingsScreenViewAction());
  }, [dispatch]);

  return (
    <PageWrapper
      progress={INITIAL_FLOW_COMPLETION_PROGRESS}
      header={translate('onboarding.welcome.header', {
        firstName,
      })}
      subHeader={translate('onboarding.welcome.subHeader')}
      footer={
        <OnboardingFooter
          RightColumn={() => (
            <Button
              css={nextButtonDesktopStyle}
              isBlock={true}
              onClick={handleNextButtonClick}
              fontColor={White['000']}
              backgroundColor={WarmGrey[700]}
              backgroundColorHover={WarmGrey[800]}
              fontColorHover={White['000']}
              dataTestIdPrefix="next"
            >
              {translate('onboarding.navigation.next')}
            </Button>
          )}
        />
      }
    >
      <div css={twoColumStyle} data-testid="greetings-page-container">
        <div css={leftPaneStyle}>
          <div css={illustrationStyle}>
            <img
              src={`${ENROLLMENT_CDN_URL}/nice-to-meet-you.png`}
              alt="panel-illustration"
              width="100%"
            />
          </div>
          {showImmediateCrisisSection && (
            <ImmediateCrisisSupportSection isMobile={true} />
          )}
          <div css={nextButtonWrapperStyle}>
            <Button
              isBlock={true}
              onClick={handleNextButtonClick}
              fontColor={White['000']}
              backgroundColor={WarmGrey[700]}
              backgroundColorHover={WarmGrey[800]}
              fontColorHover={White['000']}
              dataTestIdPrefix="mobile-next"
              css={null}
            >
              {translate('onboarding.navigation.next')}
            </Button>
          </div>
        </div>
        <div css={rightPaneStyle}>
          <div css={squareImageStyle}>
            <img
              src={`${ENROLLMENT_CDN_URL}/umd-smile-greeting.png`}
              alt="panel-illustration"
              width="100%"
            />
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
