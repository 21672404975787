import { StatsigClient } from '@statsig/js-client';

/**
 *  Since we can't use hooks in functions, this approach is needed to check the
 *  feature flag in from a ts/js function.
 *  https://reactjs.org/docs/hooks-rules.html#only-call-hooks-from-react-functions
 *  @param key enum of the statsig gate id (e.g. FEATURE_FLAG.TERMS_AND_CONDITION)
 *  @returns boolean
 */

export const checkFeatureFlag = (key: string) =>
  StatsigClient.instance().checkGate(key);
