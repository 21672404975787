import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useMemo } from 'react';
import { Divider } from '@headspace/web-ui-components';
import {
  formContainerStyle,
  rowStyle,
} from '../styles/createOrganizationStyles';
import { useTranslate } from '../../../../hooks/useTranslate';
import { Input } from '../../../../shared-components/Input';
import {
  CLINICAL_COVERAGE_TYPE,
  CLINICAL_COVERAGE_PROVIDERS,
} from '../../../../types/organization';
import { EAP_SERVICE_TYPES } from '../../constants';
import { getOrgManagement } from '../../../../state/selectors/organizationSelectors';
import {
  handleChange as handleChangeAction,
  updateImplicitState,
} from '../../../../state/actions/organizationActions';
import { dividerStyles, serviceLabelStyles } from './styles';

const TRANSLATION_KEY = 'organizationSettings.supplementalDetails.';
export const SupplementalDetails = () => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const {
    externalOrgId,
    externalOrgIdError,
    phoneNumber,
    phoneNumberError,
    provider,
    serviceType,
    coverageTotal,
    coverageTotalError,
    current_contract_clinical_coverage_type,
    parentOrg,
  } = useSelector(getOrgManagement);

  const isCoverageIntlTherapy =
    current_contract_clinical_coverage_type ===
      CLINICAL_COVERAGE_TYPE.INTL_THERAPY ||
    parentOrg?.contracts[0].clinicalCoverageType ===
      CLINICAL_COVERAGE_TYPE.INTL_THERAPY;

  const handleChange = useCallback(
    (event: ExtendedEvent) => dispatch(handleChangeAction(event)),
    [dispatch],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  const eapTitle = translate(`${TRANSLATION_KEY}eap.title`);
  const clinicalServicesTitle = translate(
    `${TRANSLATION_KEY}clinicalServices.title`,
  );
  const externalOrgIdLabel = translate(
    `${TRANSLATION_KEY}eap.externalOrgIdLabel`,
  );
  const externalOrgIdPlaceholder = translate(
    `${TRANSLATION_KEY}eap.externalOrgIdPlaceholder`,
  );
  const phoneNumberLabel = translate(`${TRANSLATION_KEY}eap.phoneNumberLabel`);
  const providerLabel = translate(
    `${TRANSLATION_KEY}clinicalServices.provider`,
  );
  const serviceTypeLabel = translate(
    `${TRANSLATION_KEY}clinicalServices.serviceType`,
  );
  const coverageTotalLabel = translate(
    'organizationSettings.basic.coverageTotalLabel',
  );

  const serviceTypeOptions = useMemo(() => {
    const options = [
      {
        title: translate(
          `${TRANSLATION_KEY}clinicalServices.serviceType.therapy`,
        ),
        value: EAP_SERVICE_TYPES.THERAPY,
      },
    ];
    if (!isCoverageIntlTherapy) {
      options.push(
        {
          title: translate(
            `${TRANSLATION_KEY}clinicalServices.serviceType.psychiatry`,
          ),
          value: EAP_SERVICE_TYPES.PSYCHIATRY,
        },
        {
          title: translate(
            `${TRANSLATION_KEY}clinicalServices.serviceType.psychiatryTherapy`,
          ),
          value: EAP_SERVICE_TYPES.PSYCHIATRY_THERAPY,
        },
      );
    }
    return options;
  }, [isCoverageIntlTherapy, translate]);

  const providerOptions = useMemo(() => {
    const options = [
      {
        title: CLINICAL_COVERAGE_PROVIDERS.WPO,
        value: CLINICAL_COVERAGE_PROVIDERS.WPO,
      },
    ];
    if (!isCoverageIntlTherapy) {
      options.push({
        title: CLINICAL_COVERAGE_PROVIDERS.CCA,
        value: CLINICAL_COVERAGE_PROVIDERS.CCA,
      });
    }
    return options;
  }, [isCoverageIntlTherapy]);

  return (
    <div data-testid="supplemental-details-page" css={formContainerStyle}>
      <div data-testid="eap-section">
        <h3 css={serviceLabelStyles}>{eapTitle}</h3>
        <div css={rowStyle}>
          <Input
            type="text"
            name="externalOrgId"
            label={externalOrgIdLabel}
            placeholder={externalOrgIdPlaceholder}
            onChange={handleChange}
            onBlur={handleError}
            value={externalOrgId}
            hasError={externalOrgIdError.error}
            errorMessage={externalOrgIdError.message}
            tabIndex="0"
          />
          <Input
            type="text"
            name="phoneNumber"
            label={phoneNumberLabel}
            placeholder="999-999-9999"
            onChange={handleChange}
            onBlur={handleError}
            value={phoneNumber}
            hasError={phoneNumberError.error}
            errorMessage={phoneNumberError.message}
            tabIndex="0"
          />
        </div>
      </div>
      <Divider css={dividerStyles} />
      <div data-testid="clinical-services-section">
        <h3 css={serviceLabelStyles}>{clinicalServicesTitle}</h3>
        <div css={rowStyle}>
          <Input
            type="select"
            name="serviceType"
            label={serviceTypeLabel}
            onChange={handleChange}
            onBlur={handleError}
            value={serviceType}
            tabIndex="0"
            options={serviceTypeOptions}
          />
          <Input
            type="select"
            name="provider"
            label={providerLabel}
            onChange={handleChange}
            onBlur={handleError}
            value={provider}
            tabIndex="0"
            options={providerOptions}
          />
        </div>
        <div css={rowStyle}>
          <Input
            type="text"
            name="coverageTotal"
            placeholder="0"
            label={coverageTotalLabel}
            value={coverageTotal}
            onChange={handleChange}
            onBlur={handleError}
            data-testid="coverageTotal"
            hasError={coverageTotalError.error}
            errorMessage={coverageTotalError.message}
          />
        </div>
      </div>
    </div>
  );
};
