import { AnyAction } from 'redux';
import {
  SSOEnrollmentActionTypes,
  EnrollmentStatusAction,
  EnrollmentSetSlugsAction,
} from '../../actions/ssoEnrollmentActions/ssoEnrollmentActionsTypes';

const INITIAL_STATE = {
  enrolled: null,
  healthHubId: null,
  orgs: null,
};

export type SSOActionTypes = EnrollmentStatusAction | EnrollmentSetSlugsAction;

export const ssoReducer = (
  state = INITIAL_STATE,
  { type, payload }: AnyAction,
) => {
  if (type === SSOEnrollmentActionTypes.CHECK_ENROLLMENT_STATUS) {
    const { enrolled, healthHubId } = payload;
    return {
      ...state,
      enrolled,
      healthHubId,
    };
  }

  if (type === SSOEnrollmentActionTypes.SET_SLUGS) {
    const { orgs } = payload;
    return {
      ...state,
      orgs,
    };
  }

  return state;
};

export type SSOReducerState = ReturnType<typeof ssoReducer>;
