import { FC, useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spinner } from '@headspace/web-ui-components';
import { TextButton } from '../../../../../../../shared-components/buttons/TextButton';
import { copyStyle, wrapperStyle } from './styles';
import { CURRENT, FUTURE, PRIMARY_PRODUCT } from '../../../../../constants';
import { Contract } from '../../../../../types';
import { getOrgManagement } from '../../../../../../../state/selectors/organizationSelectors';
import { saveOrganizationContract } from '../../../../../../../state/actions/organizationActions';
import { addMinutes, nowInUtc } from '../../../../../../../utils/dates';
import { Toast } from '../../../../../../../shared-components/Toast';

interface SplitContractButtonProps {}

export const SplitContractButton: FC<SplitContractButtonProps> = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const {
    current_contract_clinical_coverage_type,
    current_contract_eligibility_file_based_dependents,
    current_contract_end_date,
    current_contract_family_members_per_employee,
    current_contract_id,
    current_contract_is_pilot,
    current_contract_number_of_family_members_seats,
    current_contract_opportunity_id,
    current_contract_primary_product,
    current_contract_payment_type,
    current_contract_seats,
    current_contract_start_date,
    current_contract_voucher,
    future_contract_id,
  } = useSelector(getOrgManagement);
  const dispatch = useDispatch();

  const originalContract: Contract = {
    clinicalCoverageType: current_contract_clinical_coverage_type,
    elfDependentsEnabled: current_contract_eligibility_file_based_dependents,
    end_date: current_contract_end_date,
    family_members_per_employee: current_contract_family_members_per_employee,
    fusionEnabled: current_contract_primary_product === PRIMARY_PRODUCT.FUSION,
    id: current_contract_id,
    is_pilot: current_contract_is_pilot,
    number_of_family_members_seats: current_contract_number_of_family_members_seats,
    opportunity_id: current_contract_opportunity_id,
    payment_type: current_contract_payment_type,
    primary_product: current_contract_primary_product,
    seats: current_contract_seats,
    start_date: current_contract_start_date,
    term: CURRENT,
    voucher: current_contract_voucher,
  };

  const handleClick = useCallback(() => {
    const now = nowInUtc();
    setIsLoading(true);
    dispatch(
      saveOrganizationContract(
        {
          ...originalContract,
          end_date: now.toISOString(),
        },
        true,
      ),
    );
    // Timeout to prevent error with overlapping subscriptions
    setTimeout(() => {
      dispatch(
        saveOrganizationContract(
          {
            ...originalContract,
            id: null,
            start_date: addMinutes(now, 1).toISOString(),
            term: FUTURE,
          },
          true,
        ),
      );
      setIsLoading(false);
    }, 10000);
  }, [originalContract, dispatch]);
  useEffect(() => {
    if (!!future_contract_id && !isLoading) {
      setIsSuccess(true);
    }
  }, [future_contract_id, isLoading]);

  return (
    <div css={wrapperStyle}>
      <TextButton
        value="Split Contract"
        disabled={!!future_contract_id || isLoading}
        onClick={handleClick}
        dataTestId="split-contract"
      />
      <div css={copyStyle}>
        Backdate the current contract&apos;s endDate and create a new future
        contract with the original endDate and the same settings. This should be
        used if you need to force a rollover.
      </div>
      {isLoading ? <Spinner /> : null}
      {isSuccess ? <Toast text="Contract split successfully" /> : null}
    </div>
  );
};
