import { useDispatch, useSelector } from 'react-redux';
import { IIdleTimer, useIdleTimer } from 'react-idle-timer';
import { useEffect } from 'react';
import * as UserSelectors from '../state/selectors/userSelectors';
import {
  closeSessionTimeoutModal,
  openSessionTimeoutModal,
} from '../state/slices/sessionTimeoutModal';
import { userLogout } from '../state/actions/userActions';
import { useHsOktaSSO } from '../featureFlags/useHsOktaSSO';

/** *
 * Time to see the prompt modal for user
 * with actions to keep current session or log out. (14 minutes)
 */
const DEFAULT_IDLE_TIMEOUT = 60 * 1000 * 14;
/**
 * Time to make a decision to keep session or log out. (1 min)
 * If a decision was not made a user would be logged out automatically.
 */
const DEFAULT_PROMPT_TIMEOUT = 60 * 1000;
const SYNC_TIMERS_THROTTLE_DURATION = 5 * 1000;

export const useSessionIdleTimer = (): IIdleTimer => {
  const dispatch = useDispatch();
  const isLoggingIn = useSelector(UserSelectors.isLoggingIn);
  const isHSOktaSSOEnabled = useHsOktaSSO();

  const onPrompt = () => {
    dispatch(openSessionTimeoutModal());
  };
  const onIdle = () => {
    dispatch(closeSessionTimeoutModal());
    dispatch(userLogout(isHSOktaSSOEnabled));
  };

  const idleTimer = useIdleTimer({
    crossTab: true,
    onIdle,
    onPrompt,
    promptTimeout: DEFAULT_PROMPT_TIMEOUT,
    startManually: true,
    syncTimers: SYNC_TIMERS_THROTTLE_DURATION,
    timeout: DEFAULT_IDLE_TIMEOUT,
  });
  const { activate, pause } = idleTimer;

  useEffect(() => {
    if (isLoggingIn) {
      activate();
    } else {
      pause();
    }
  }, [isLoggingIn, activate, pause]);

  return idleTimer;
};
