import { FC } from 'react';
import * as R from 'ramda';
import { css } from '@emotion/react';
import {
  Button,
  White,
  SecondaryButton,
  SpacingInRemBySize,
  rem,
  WarmGrey,
} from '@headspace/web-ui-components';
import { useSelector } from 'react-redux';
import { WebUIComponentsBreakPoint } from '../../../../constants/breakpoint';
import { RootState } from '../../../../../../state/types';
import { QuestionId } from '../../../../models/OnboardingQuestions';
import { getQuestionsAnswer } from '../../../../state/slice/onboarding/onboardingSelector';
import { useTranslate } from '../../../../../../hooks/useTranslate';
import { ImmediateCrisisSupportSection } from '../onboarding-shared-components/ImmediateCrisisSupportSection';
import { useFusionImmediateCrisisSupport } from '../../../../../../featureFlags/fusionImmediateCrisisSupport';

export const navigationStyle = css({
  display: 'flex',
  justifyContent: 'space-between',
  [WebUIComponentsBreakPoint.Tablet]: {
    minWidth: rem(12.5),
    paddingLeft: SpacingInRemBySize.XL,
  },
});

export const disabledButtonStyle = css({
  '&:disabled': {
    backgroundColor: `${WarmGrey[700]} !important`,
    borderColor: `${WarmGrey[200]} !important`,
    color: `${White['000']} !important`,
    opacity: '0.4 !important',
  },
});

export const navigationButtonStyle = css({
  ...disabledButtonStyle,
  [WebUIComponentsBreakPoint.Desktop]: {
    minWidth: rem(12.75),
  },
});

export const backButtonStyle = css({
  [WebUIComponentsBreakPoint.DesktopAndTablet]: {
    marginRight: rem(0.938),
  },
});

interface QuestionNavigationProps {
  onNextClick: (event) => void;
  onPreviousClick: () => void;
  questionId: QuestionId;
}

export const QuestionNavigation: FC<QuestionNavigationProps> = ({
  onNextClick,
  onPreviousClick,
  questionId,
}) => {
  const answers = useSelector((state: RootState) =>
    getQuestionsAnswer(state, questionId),
  );
  const shouldDisableNextButton = R.isEmpty(answers);
  const { translate } = useTranslate();
  return (
    <div css={navigationStyle}>
      <SecondaryButton
        css={[navigationButtonStyle, backButtonStyle]}
        onClick={onPreviousClick}
        borderColor={WarmGrey[200]}
      >
        {translate('onboarding.navigation.back')}
      </SecondaryButton>
      <Button
        css={navigationButtonStyle}
        isDisabled={shouldDisableNextButton}
        onClick={onNextClick}
        fontColor={White['000']}
        backgroundColor={WarmGrey[700]}
        backgroundColorHover={WarmGrey[800]}
        fontColorHover={White['000']}
        dataTestIdPrefix="next-question"
      >
        {translate('onboarding.navigation.next')}
      </Button>
    </div>
  );
};

interface MobileViewQuestionNavigationProp {
  onNextClick: (event) => void;
  onPreviousClick: () => void;
  questionId: QuestionId;
}

const mobileNavigationContainerStyle = css({
  display: 'none',
  [WebUIComponentsBreakPoint.Mobile]: {
    display: 'flex',
    position: 'relative',
    width: '100vw',
  },
});

export const mobileNavigationStyle = css({
  display: 'none',
  [WebUIComponentsBreakPoint.Mobile]: {
    alignItems: 'center',
    backgroundColor: 'rgba(250, 246, 244, 0.8)',
    bottom: 0,
    display: 'flex',
    flexDirection: 'column',
    gap: rem(0.5),
    height: SpacingInRemBySize['8XL'],
    justifyContent: 'center',
    position: 'fixed',
    width: '100%',
    'z-index': '2',
  },
});

export const MobileViewQuestionNavigation: FC<MobileViewQuestionNavigationProp> = ({
  onNextClick,
  onPreviousClick,
  questionId,
}) => {
  const fusionImmediateCrisisSupport = useFusionImmediateCrisisSupport();
  const answers = useSelector((state: RootState) =>
    getQuestionsAnswer(state, questionId),
  );
  const shouldDisableNextButton = R.isEmpty(answers);
  const { translate } = useTranslate();

  return (
    <div css={mobileNavigationContainerStyle}>
      {fusionImmediateCrisisSupport && <ImmediateCrisisSupportSection />}
      <div css={mobileNavigationStyle}>
        <Button
          onClick={onNextClick}
          fontColor={White['000']}
          backgroundColor={WarmGrey[700]}
          backgroundColorHover={WarmGrey[800]}
          borderColor={WarmGrey[800]}
          fontColorHover={White['000']}
          dataTestIdPrefix="mobile-next-question"
          isBlock={true}
          css={disabledButtonStyle}
          isDisabled={shouldDisableNextButton}
        >
          {translate('onboarding.navigation.next')}
        </Button>
        <SecondaryButton
          onClick={onPreviousClick}
          borderColor={WarmGrey[200]}
          isBlock={true}
        >
          {translate('onboarding.navigation.back')}
        </SecondaryButton>
      </div>
    </div>
  );
};
