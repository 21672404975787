import { createSlice } from '@reduxjs/toolkit';
import { merge } from 'ramda';
import { Contract } from '../../../models/organization/Contract';
import { Enrollment } from '../../../models/organization/Enrollment';
import { Admin } from '../../../models/organization/Admin';
import { Details } from '../../../models/organization/Details';
import {
  CLINICAL_COVERAGE_TYPE,
  OrganizationTypes,
  RestrictBy,
} from '../../../../types/organization';

export type AdministratorsFormStateType = Admin[];
export type ContractFormsStateType = Omit<Contract, 'id' | 'status'>; // id and status are generated by the BE after org creation and are readonly
export type DetailsStateFormType = Omit<Details, 'orgId' | 'headspaceHealthId'>; // orgId and headspaceHealthId are generated by the BE after org creation and are readonly
export type EnrollmentStateFormType = Enrollment;

export interface FormState {
  administrators: AdministratorsFormStateType;
  contract: ContractFormsStateType;
  details: DetailsStateFormType;
  enrollment: EnrollmentStateFormType;
}
export type FormType = keyof FormState;
export type ContractIdType = keyof Contract;
export type DetailsIdType = keyof Details;
export type EnrollmentIdType = keyof Enrollment;

export interface ValidationErrors {
  administrators: { email?: string };
  contract: {
    [id in ContractIdType]?: string;
  };
  details: {
    [id in DetailsIdType]?: string;
  };
  enrollment: {
    [id in EnrollmentIdType]?: string;
  };
}

export type CurrentStep = number;

export interface CreateOrgInitialState {
  currentStep: CurrentStep;
  formState: FormState;
  validationErrors: ValidationErrors;
}

export const createOrgInitialState: CreateOrgInitialState = {
  currentStep: 1,
  formState: {
    administrators: [],
    contract: {
      activeMembers: 0,
      clinicalCoverageType: CLINICAL_COVERAGE_TYPE.NONE,
      dependentSeats: 0,
      dependentsPerMember: 0,
      endDate: '',
      isPilot: false,
      opportunityId: '',
      partialPayVoucher: '',
      primaryProduct: '',
      seats: 0,
      startDate: '',
    },
    details: {
      bundleEnabled: false,
      gingerId: null,
      healthHub: null,
      healthHubExternalId: '',
      logoFileName: '',
      logoFilePreview: '',
      name: '',
      noPromoEmails: false,
      salesforceId: '',
      ssoEnabled: false,
      type: OrganizationTypes.B2B,
    },
    enrollment: {
      body: '',
      campaignDisplayName: '',
      campaignLaunchDate: '',
      headline: '',
      learnMoreUrl: '',
      overflowUrl: '',
      restrictBy: RestrictBy.UNRESTRICTED,
      restrictedDomains: [],
      slug: '',
      workIdFieldPlaceholderText: '',
    },
  },
  validationErrors: {
    administrators: {},
    contract: {},
    details: {},
    enrollment: {},
  },
};

export const createOrganizationSlice = createSlice({
  name: 'createOrganization',
  initialState: createOrgInitialState,
  reducers: {
    handleChangeDetails(
      state,
      action: { payload: { id: string; value: string } },
    ) {
      const { id, value } = action.payload;
      state.formState.details[id] = value;
    },

    setValidationError(
      state,
      action: {
        payload: {
          formType: FormType;
          id: string;
          message: string | undefined;
        };
      },
    ) {
      const { formType, id, message } = action.payload;
      state.validationErrors[formType] = merge(
        state.validationErrors[formType],
        { [id]: message },
      );
    },
    handleResetFormState(state) {
      return createOrgInitialState;
    },
    handleCreateOrganization(state, action) {
      // get state and format
      const { enrollment, contract, details, administrators } = state.formState;
      const format = {
        administrators,
        enrollment,
        subscription: contract,
        ...details,
      };
      // and make a post request
      // finally redirect to org settings page and reset form state
    },
    handleLogoFileInput(
      state,
      action: {
        payload: { logoFileName: string; logoFilePreview: string };
      },
    ) {
      const { logoFileName, logoFilePreview } = action.payload;
      state.formState.details.logoFileName = logoFileName;
      state.formState.details.logoFilePreview = logoFilePreview;
    },
  },
});

export const {
  handleChangeDetails,
  setValidationError,
  handleResetFormState,
  handleCreateOrganization,
  handleLogoFileInput,
} = createOrganizationSlice.actions;
