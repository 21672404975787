import { useClientAsyncInit, StatsigProvider } from '@statsig/react-bindings';

import { createStatsigConfig } from '../utils/statsig';
import { store } from '../state/store';
import { SpinnerWithBackGround } from '../shared-components/Spinner';

const Provider: React.FC<{
  children: React.ReactNode;
}> = ({ children }) => {
  const { sdkKey, user, options } = createStatsigConfig(store);
  const { client, isLoading } = useClientAsyncInit(sdkKey, user, options);

  if (isLoading) {
    return <SpinnerWithBackGround height="70px" width="70px" />;
  }

  return <StatsigProvider client={client}>{children}</StatsigProvider>;
};

export default Provider;
