import React, {
  createContext,
  FC,
  memo,
  useEffect,
  PropsWithChildren,
} from 'react';
import { IntlProvider as ReactIntlProvider } from 'react-intl';
import { useSelector } from 'react-redux';
import { useMatch } from 'react-router-dom';
import { dashboardMessages } from '../i18n/dashboardMessages';
import { useGetHubLangCookie } from '../hooks/useGetHubLangCookie';
import { getLocale } from '../state/selectors/getLocale';
import { HubSupportedLanguageCode } from '../types/language';
import { hasClinicalCoverage } from '../state/selectors/organizationSelectors';

type HubLangContextType = {
  hubLangCookie: HubSupportedLanguageCode;
  setHubLangCookie: (lang: string) => void;
};
export const HubLangContext = createContext<HubLangContextType>({
  hubLangCookie: HubSupportedLanguageCode.en,
  setHubLangCookie: () => {},
});

export const IntlProvider: FC<PropsWithChildren> = memo(({ children }) => {
  const [hubLangCookie, setHubLangCookie] = useGetHubLangCookie();
  const isClinicalCovered = useSelector(hasClinicalCoverage);
  const supportedLocale = useSelector(getLocale);
  const isEnrollmentRoute = /\/member-enroll/.test(window.location.pathname);
  const matchHub = useMatch('/hub/*');
  const isHubRoute = matchHub != null;

  const isHubLocale = isHubRoute || (isEnrollmentRoute && isClinicalCovered);

  const locale = isHubLocale ? hubLangCookie : supportedLocale;
  const formattedLocale = locale.replace('_', '-');

  const translationsForLocale = dashboardMessages[locale];

  useEffect(() => {
    document.documentElement.lang = formattedLocale;
  }, [formattedLocale]);

  return (
    <HubLangContext.Provider value={{ hubLangCookie, setHubLangCookie }}>
      <ReactIntlProvider
        locale={formattedLocale}
        messages={translationsForLocale}
      >
        {children}
      </ReactIntlProvider>
    </HubLangContext.Provider>
  );
});
