import { FC, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getOrgManagement } from '../state/selectors/organizationSelectors';
import { handleChange as handleChangeAction } from '../state/actions/organizationActions';
import { useTranslate } from '../hooks/useTranslate';
import { InputProps } from './Input';
import { ChildRestrictedInput } from './ChildRestrictedInput/ChildRestrictedInput';
import { RestrictBy } from '../types/organization';
import { isContractFusionOrUnified } from '../apps/dashboard/utils/manage-organization';
import { useFeatureFlag } from '../hooks/useFeatureFlag';
import { FEATURE_FLAG } from '../constants/featureFlags';

export interface DependentsInputGroupProps
  extends Omit<
    InputProps,
    'dataTestId' | 'name' | 'type' | 'label' | 'options' | 'value' | 'onChange'
  > {
  env?: string;
}

const generateRestrictByOptions = (
  {
    primaryProduct,
    gingerBundlingEnabled,
    elfBasedDependents,
    showJwtVerificationType,
  },
  translateFn,
  env,
) => {
  const availableRestrictByTypes = [
    {
      title: translateFn(
        `organizationSettings.enrollment.${RestrictBy.EMAIL_ELIGIBILITY_FILE}`,
      ),
      value: RestrictBy.EMAIL_ELIGIBILITY_FILE,
    },
    {
      title: translateFn(
        `organizationSettings.enrollment.${RestrictBy.EMPLOYEE_ID}`,
      ),
      value: RestrictBy.EMPLOYEE_ID,
    },
  ];

  if (!isContractFusionOrUnified(primaryProduct) && !elfBasedDependents) {
    availableRestrictByTypes.push({
      title: translateFn(
        `organizationSettings.enrollment.${RestrictBy.EMAIL_DOMAIN}`,
      ),
      value: RestrictBy.EMAIL_DOMAIN,
    });
  }

  if (gingerBundlingEnabled && !elfBasedDependents) {
    availableRestrictByTypes.push(
      {
        title: translateFn(
          `organizationSettings.enrollment.${RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE}`,
        ),
        value: RestrictBy.BUNDLED_EMAIL_ELIGIBILITY_FILE,
      },
      {
        title: translateFn(
          `organizationSettings.enrollment.${RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE}`,
        ),
        value: RestrictBy.BUNDLED_EMPLOYEE_ID_ELIGIBILITY_FILE,
      },
    );
  }

  if (showJwtVerificationType) {
    availableRestrictByTypes.push({
      title: translateFn(
        `organizationSettings.enrollment.${RestrictBy.KP_NATIONAL_JWT}`,
      ),
      value: RestrictBy.KP_NATIONAL_JWT,
    });
  }

  if (env !== 'production') {
    availableRestrictByTypes.push({
      title: translateFn(
        `organizationSettings.enrollment.${RestrictBy.UNRESTRICTED}`,
      ),
      value: RestrictBy.UNRESTRICTED,
    });
  }

  return availableRestrictByTypes;
};

export const RestrictByInput: FC<DependentsInputGroupProps> = ({
  env,
  ...props
}) => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const showJwtVerificationType = useFeatureFlag(
    FEATURE_FLAG.KP_SHOW_VERIFICATION_TYPE,
  );

  const {
    restrictBy,
    gingerBundlingEnabled,
    current_contract_primary_product: primaryProduct,
    current_contract_eligibility_file_based_dependents: elfBasedDependents,
  } = useSelector(getOrgManagement);

  const handleChange = useCallback(
    (event: ExtendedEvent, supressValidation?: boolean) =>
      dispatch(handleChangeAction(event, supressValidation)),
    [dispatch],
  );

  return (
    <ChildRestrictedInput
      {...props}
      dataTestId="restrictByOptions"
      name="restrictBy"
      type="select"
      label={translate(`organizationSettings.enrollment.restrictByLabel`)}
      options={generateRestrictByOptions(
        {
          elfBasedDependents,
          gingerBundlingEnabled,
          primaryProduct,
          showJwtVerificationType,
        },
        translate,
        env,
      )}
      value={restrictBy}
      onChange={handleChange}
    />
  );
};
