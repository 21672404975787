import React, { useCallback, useEffect, useState } from 'react';
import {
  Grey,
  Modal,
  Orange,
  rem,
  SpacingInRemBySize,
  White,
} from '@headspace/web-ui-components';
import { useDispatch, useSelector } from 'react-redux';
import { css, SerializedStyles } from '@emotion/react';
import { useLocation } from 'react-router-dom';
import { requestDeauthenticate } from '@headspace/web-auth';
import { format } from '../../../utils/dates';
import {
  closeSessionTimeoutModal,
  selectSessionTimeoutModal,
} from '../../../state/slices/sessionTimeoutModal';
import { TextButton } from '../../../shared-components/buttons/TextButton';
import { userLogout } from '../../../state/actions/userActions';
import { useHsOktaSSO } from '../../../featureFlags/useHsOktaSSO';
import { useTranslate } from '../../../hooks/useTranslate';
import { headspaceHubRoutes } from '../../../apps/headspaceHub/constants/routes';

interface SessionTimeoutModalProps {
  resetSessionTrackingTimer: () => void;
  getRemainingTime: () => number;
}

interface SessionTimeoutModalBodyProps {
  getRemainingTime: () => number;
}

const modalStyle = css({
  bottom: 0,
  display: 'flex',
  height: '100vh',
  left: 0,
  position: 'fixed',
  right: 0,
  top: 0,
  width: '100%',
});

const keepSessionButtonStyle = css({
  marginRight: rem(0.625),
});

const signOutButtonStyle = css({
  marginRight: 0,
});

const modalBodyContainerStyle = css({
  padding: `${rem(1.875)} ${rem(4.375)} 0`,
  textAlign: 'center',
});

const modalFooterContainerStyle = css({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'center',
});

const panelCancelStyle: SerializedStyles = css({
  ':hover': {
    backgroundColor: `${Grey[100]} !important`,
    color: `${Orange[200]} !important`,
  },
  backgroundColor: `${White['000']} !important`,
  color: `${Orange[200]} !important`,
  marginRight: SpacingInRemBySize.M,
});

const convertToSeconds = (milliseconds: number) =>
  format(milliseconds, 'mm:ss');

const ModalBody: React.FC<SessionTimeoutModalBodyProps> = ({
  getRemainingTime,
}) => {
  const [timeLeftBeforeLogout, setTimeLeftBeforeLogout] = useState(
    convertToSeconds(getRemainingTime()),
  );
  const { translate } = useTranslate();

  useEffect(() => {
    const timeoutInterval = setInterval(() => {
      setTimeLeftBeforeLogout(convertToSeconds(getRemainingTime()));
    }, 1000);

    return () => clearInterval(timeoutInterval);
  }, [getRemainingTime]);

  return (
    <div css={modalBodyContainerStyle}>
      <div>
        <h2>{translate('sessionTimeoutModal.title')}</h2>
      </div>
      <div>
        <p>
          {translate('sessionTimeoutModal.description')}
          <b>{timeLeftBeforeLogout}</b>
        </p>
      </div>
    </div>
  );
};

export const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
  resetSessionTrackingTimer,
  getRemainingTime,
}) => {
  const dispatch = useDispatch();
  const { isOpen } = useSelector(selectSessionTimeoutModal);
  const isHsOktaSSOEnabled = useHsOktaSSO();
  const { translate } = useTranslate();
  const { pathname } = useLocation();
  const headspaceHubRoutesArray = Object.values(headspaceHubRoutes);

  const handleKeepSessionButtonClick = useCallback(() => {
    dispatch(closeSessionTimeoutModal());
    resetSessionTrackingTimer();
  }, [resetSessionTrackingTimer, dispatch]);

  const handleSignOutButtonClick = useCallback(() => {
    const isHub = headspaceHubRoutesArray.includes(pathname);
    dispatch(closeSessionTimeoutModal());
    if (isHub) {
      dispatch(requestDeauthenticate(headspaceHubRoutes.LOGIN_REDIRECT));
    } else {
      dispatch(userLogout(isHsOktaSSOEnabled));
    }
  }, [dispatch, headspaceHubRoutesArray, isHsOktaSSOEnabled, pathname]);

  const onKeepSessionKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        handleKeepSessionButtonClick();
      }
    },
    [handleKeepSessionButtonClick],
  );

  const onSignOutKeyDown = useCallback(
    (event: React.KeyboardEvent<HTMLElement>) => {
      if (event.key === 'Enter') {
        handleSignOutButtonClick();
      }
    },
    [handleSignOutButtonClick],
  );

  return isOpen ? (
    <div css={modalStyle} data-testid="session-timeout-modal-container">
      <Modal
        body={<ModalBody getRemainingTime={getRemainingTime} />}
        onClose={handleKeepSessionButtonClick}
        footer={
          <div css={modalFooterContainerStyle}>
            <TextButton
              dataTestId="session-timeout-modal-keep-session-button"
              onClick={handleKeepSessionButtonClick}
              onKeyDown={onKeepSessionKeyDown}
              value={translate('sessionTimeoutModal.keepSessionButtonText')}
              css={keepSessionButtonStyle}
            />
            <TextButton
              dataTestId="session-timeout-modal-sign-out-button"
              onClick={handleSignOutButtonClick}
              onKeyDown={onSignOutKeyDown}
              value={translate('sessionTimeoutModal.signOutButtonText')}
              css={[signOutButtonStyle, panelCancelStyle]}
            />
          </div>
        }
      />
    </div>
  ) : null;
};
