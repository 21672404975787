import { useState, useEffect, SetStateAction, Dispatch } from 'react';

export function getStorageValue<T>(key, defaultValue: T): T {
  const saved = localStorage.getItem(key);
  try {
    const initial = JSON.parse(saved ?? '');
    return initial || defaultValue;
  } catch (e) {
    return defaultValue;
  }
}

export const useLocalStorage = <T>(
  key,
  defaultValue: T,
): [T, Dispatch<SetStateAction<T>>] => {
  const [value, setValue] = useState(() => {
    return getStorageValue<T>(key, defaultValue);
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(value));
  }, [key, value]);

  return [value, setValue];
};
