import queryString from 'query-string';
import { FC, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate, useLocation } from 'react-router';

import { AxiosError } from 'axios';
import { useTranslate } from '../../../../../hooks/useTranslate';
import { SpinnerFullBackGround } from '../../../../../shared-components/Spinner/SpinnerFullBackground';
import { ParsedError } from '../../../../../utils/parseError';
import { EnrollmentVerification } from '../../../constants/enrollmentVerification';
import { getUnifiedEligibilityRoutes } from '../../../constants/routes';
import { useGetVerifyDependentInviteToken } from '../../../hooks/useGetVerifyDependentInviteToken';
import { usePostIdentityVerifications } from '../../../hooks/usePostIndentityVerifications';
import {
  PostIdentityVerificationsPayload,
  PostIdentityVerificationsResponse,
} from '../../../rest-calls/postIdentityVerifications';
import {
  setEnrollmentVerificationConfirmation,
  setMemberDobAndCountryCode,
} from '../../../state/actions/memberEnrollActions/memberEnrollActions';
import { getEnrollMentVerificationSection } from '../../../state/selectors/enrollmentVerificationSelector/enrollmentVerificationSelector';
import {
  getOrgId,
  getSlug,
} from '../../../state/selectors/orgSectionSelector/orgSectionSelector';
import {
  clearDependentToken,
  setCohort,
  setCountry,
  setDependentToken,
} from '../../../state/slice/onboarding/onboardingReducer';
import { expiredDependentLinkPageQueryParams } from '../ExceptionPages/ExpiredDependentLinkPage';
import { DobAndCountryForm } from './components/DobAndCountryForm';
import { CountryCode } from '../../../../../constants/country';
import { VerificationPage } from '../VerificationPage';
import { getConfig } from '../../../../../config';

const { EXISTING_ACCOUNT_ORG_ID_ALLOWLIST } = getConfig();

export const IdentityVerificationPage: FC = () => {
  const dispatch = useDispatch();
  const { translate } = useTranslate();
  const { confirmation } = useSelector(getEnrollMentVerificationSection);
  const slug = useSelector(getSlug);
  const orgId = useSelector(getOrgId);
  const { search } = useLocation();
  const { dependent_token } = queryString.parse(search);
  const { status, error } = useGetVerifyDependentInviteToken(dependent_token);

  const handleSuccess = useCallback(
    (
      { cohort }: PostIdentityVerificationsResponse,
      payload: PostIdentityVerificationsPayload,
    ) => {
      const countryCode = CountryCode[payload.preferredCountry];
      dispatch(setCohort(cohort));
      dispatch(setCountry(countryCode));
      if (dependent_token) {
        dispatch(setDependentToken(dependent_token));
        dispatch(
          setEnrollmentVerificationConfirmation({
            method: EnrollmentVerification.EMAIL,
            verificationToken: dependent_token,
          }),
        );
      } else {
        dispatch(clearDependentToken());
      }
    },
    [dispatch, dependent_token],
  );

  const mutation = usePostIdentityVerifications(slug, handleSuccess, () => {});

  const handleConfirm = useCallback(
    ({ countryCode, dob }) => {
      // DoB is in MM/DD/YYYY format
      const [mm, dd, yyyy] = dob.split('/');
      const preferredDob = `${yyyy}-${mm}-${dd}`;

      const payload: PostIdentityVerificationsPayload = {
        enrollmentType: EnrollmentVerification.EMAIL,
        isDependent: !!dependent_token,
        preferredCountry: countryCode,
        preferredDob,
      };

      dispatch(setMemberDobAndCountryCode(preferredDob, countryCode));

      if (dependent_token) {
        payload.verificationToken = dependent_token;
      } else if (confirmation?.method === EnrollmentVerification.EMAIL) {
        payload.verificationToken = confirmation?.verificationToken;
      } else if (confirmation?.method === EnrollmentVerification.ID) {
        payload.enrollmentType = EnrollmentVerification.ID;
        payload.lastName = confirmation?.lastName;
        payload.workId = confirmation?.workId;
      }

      mutation.mutate(payload);
    },
    [confirmation, mutation, dependent_token],
  );

  const {
    VERIFY_IDENTITY_ERROR_PATH,
    EXISTING_ACCOUNT,
    EXPIRED_DEPENDENT_LINK,
    LOADING_PATH,
  } = getUnifiedEligibilityRoutes(slug);

  if (status === 'error') {
    const statusCode = (error as AxiosError)?.response?.status;
    const search = statusCode
      ? `${encodeURIComponent(
          expiredDependentLinkPageQueryParams.error,
        )}=${encodeURIComponent(statusCode)}`
      : '';
    return (
      <Navigate replace={true} to={`${EXPIRED_DEPENDENT_LINK}?${search}`} />
    );
  }

  if (mutation.isLoading || (status === 'loading' && !!dependent_token)) {
    return <SpinnerFullBackGround />;
  }
  if (mutation.isError) {
    const parsedError: ParsedError = mutation.error;
    const search = `code=${encodeURIComponent(
      parsedError.errorStringCode ?? '',
    )}`;
    return (
      <Navigate replace={true} to={`${VERIFY_IDENTITY_ERROR_PATH}?${search}`} />
    );
  }
  if (mutation.isSuccess) {
    if (
      mutation.data.hsUserId &&
      EXISTING_ACCOUNT_ORG_ID_ALLOWLIST.includes(orgId)
    ) {
      return <Navigate to={EXISTING_ACCOUNT} replace={true} />;
    }

    const search = mutation.data.hsUserId ? 'existing=true' : '';
    return <Navigate to={`${LOADING_PATH}?${search}`} replace={true} />;
  }

  return (
    <VerificationPage skipMobileHero={true}>
      <DobAndCountryForm
        title={translate('enrollment.verifyIdentity.title')}
        subtitle={translate('enrollment.verifyIdentity.subtitle')}
        handleConfirm={handleConfirm}
      />
    </VerificationPage>
  );
};
