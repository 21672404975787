import * as R from 'ramda';
import { getOrgManagement, getCachedOrg } from '../../organizationSelectors';
import { makeFriendlyDate } from '../../../../utils/dates';
import type { RootState } from '../../../types';
import { CLINICAL_COVERAGE_TYPE } from '../../../../types/organization';

export const getCachedFutureContract = (state: RootState) => {
  const cachedOrg = getCachedOrg(state);
  const contracts = cachedOrg?.contracts || [];
  const hasContracts = !R.isEmpty(contracts);

  if (hasContracts) {
    return R.find(R.propEq('status', 'INACTIVE'))(contracts) || null;
  }
  return null;
};

export const getEditFutureContractFormState = (state: RootState) => {
  const manageOrganization = getOrgManagement(state);

  return R.pick(
    [
      'future_contract_eligibility_file_based_dependents',
      'future_contract_id',
      'future_contract_opportunity_id',
      'future_contract_opportunity_idError',
      'future_contract_primary_product',
      'future_contract_primary_productError',
      'future_contract_start_date',
      'future_contract_end_date',
      'future_contract_seats',
      'future_contract_is_pilot',
      'future_contract_payment_type',
      'future_contract_voucher',
      'future_contract_start_dateError',
      'future_contract_end_dateError',
      'future_contract_seatsError',
      'future_contract_family_members_per_employee',
      'future_contract_family_members_per_employeeError',
      'future_contract_number_of_family_members_seats',
      'future_contract_number_of_family_members_seatsError',
      'viewPanels',
      'future_contract_status',
      'future_contract_invite_based_dependents',
      'future_contract_add_ons',
      'future_contract_clinical_coverage_type',
      'future_contract_eap_type',
    ],
    manageOrganization,
  );
};

const checkForEmptyValues = (state: RootState) => {
  const {
    future_contract_opportunity_id,
    future_contract_primary_product,
    future_contract_start_date,
    future_contract_end_date,
    future_contract_payment_type,
    future_contract_voucher,
  } = getEditFutureContractFormState(state);
  return (
    R.isEmpty(future_contract_opportunity_id) ||
    R.isEmpty(future_contract_primary_product) ||
    R.isEmpty(future_contract_start_date) ||
    R.isEmpty(future_contract_end_date) ||
    // ensures if a payment type is selected to choose the proper voucher and is not an empty string
    (!R.isEmpty(future_contract_payment_type) &&
      R.isEmpty(future_contract_voucher))
  );
};

export const hasEditFutureContractFormStateErrors = (
  state: RootState,
): boolean => {
  const currentContractFormState = getEditFutureContractFormState(state);
  const currentContractFormErrors = R.pick(
    [
      'future_contract_opportunity_idError',
      'future_contract_start_dateError',
      'future_contract_end_dateError',
      'future_contract_seatsError',
      'future_contract_number_of_family_members_seatsError',
      'future_contract_family_members_per_employeeError',
      'future_contract_primary_productError',
    ],
    currentContractFormState,
  );

  const errorsArr = R.valuesIn(currentContractFormErrors).map(
    (errorObj: any) => errorObj.error,
  );
  const hasError = R.includes(true, errorsArr);
  const hasEmptyValues = checkForEmptyValues(state);

  return hasError || hasEmptyValues;
};

export const getFutureContractChangedStatus = (state: RootState): boolean => {
  const cachedFutureContract = getCachedFutureContract(state);

  const {
    future_contract_id,
    future_contract_opportunity_id,
    future_contract_primary_product,
    future_contract_start_date,
    future_contract_end_date,
    future_contract_seats,
    future_contract_is_pilot,
    future_contract_voucher,
    future_contract_family_members_per_employee,
    future_contract_number_of_family_members_seats,
    future_contract_clinical_coverage_type,
  } = getEditFutureContractFormState(state);

  const formatContractFormState = {
    clinicalCoverageType:
      future_contract_clinical_coverage_type ?? CLINICAL_COVERAGE_TYPE.NONE,
    dependentSeats: parseInt(
      future_contract_number_of_family_members_seats,
      10,
    ),
    dependentsPerMember: parseInt(
      future_contract_family_members_per_employee,
      10,
    ),
    endDate: future_contract_end_date,
    id: future_contract_id,
    isPilot: future_contract_is_pilot,
    opportunityId: future_contract_opportunity_id,
    partialPayVoucher: future_contract_voucher,
    primaryProduct: future_contract_primary_product,
    seats: parseInt(future_contract_seats, 10),
    startDate: future_contract_start_date,
  };

  const formatCachedContract = {
    ...R.omit(['status', 'activeMembers'], cachedFutureContract),
    endDate: makeFriendlyDate(cachedFutureContract?.endDate || '') || '',
    startDate: makeFriendlyDate(cachedFutureContract?.startDate || '') || '',
  };

  return !R.equals(formatCachedContract, formatContractFormState);
};
