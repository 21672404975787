import { FC, useCallback, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import {
  setMParticleIdentity,
  verificationSuccessScreenViewAction,
} from '../../../../../../analytics/events/enrollmentEvents';
import { SpinnerFullBackGround } from '../../../../../../shared-components/Spinner/SpinnerFullBackground';
import { ParsedError } from '../../../../../../utils/parseError';
import { EnrollmentVerification } from '../../../../constants/enrollmentVerification';
import { getUnifiedEligibilityRoutes } from '../../../../constants/routes';
import { usePostIdentityVerifications } from '../../../../hooks/usePostIndentityVerifications';
import { PostIdentityVerificationsResponse } from '../../../../rest-calls/postIdentityVerifications';
import { setEnrollmentVerificationConfirmation } from '../../../../state/actions/memberEnrollActions/memberEnrollActions';
import {
  setCohort,
  setCountry,
} from '../../../../state/slice/onboarding/onboardingReducer';
import { CountryCode } from '../../../../../../constants/country';

interface EligibilityVerifyByKpJwtProps {
  kpJwt: string | null;
  slug: string;
}

export const EligibilityVerifyByKpJwt: FC<EligibilityVerifyByKpJwtProps> = ({
  kpJwt,
  slug,
}) => {
  const dispatch = useDispatch();

  const {
    VERIFY_IDENTITY_ERROR_PATH,
    EXISTING_ACCOUNT,
    LOADING_PATH,
  } = getUnifiedEligibilityRoutes(slug);

  const handleSuccess = useCallback(
    (data: PostIdentityVerificationsResponse) => {
      if (data.hsUserId) {
        setMParticleIdentity(data.hsUserId);
      }
      if (kpJwt) {
        dispatch(
          setEnrollmentVerificationConfirmation({
            cohort: data.cohort,
            hsUserId: data.hsUserId,
            kpJwt,
            method: EnrollmentVerification.KP_JWT,
          }),
        );
      }
      dispatch(setCohort(data.cohort));
      dispatch(setCountry(CountryCode.US)); // assume always US for KP
      dispatch(verificationSuccessScreenViewAction());
    },
    [dispatch, kpJwt],
  );

  const mutation = usePostIdentityVerifications(slug, handleSuccess, () => {});

  useEffect(() => {
    if (kpJwt) {
      const payload = {
        enrollmentType: EnrollmentVerification.KP_JWT,
        jwt: kpJwt,
      };
      if (mutation.isIdle) {
        mutation.mutate(payload);
      }
    }
  }, [kpJwt, mutation]);

  if (!kpJwt) {
    const search = `code=${encodeURIComponent('KP_NATIONAL_JWT_TOKEN_ERROR')}`;
    return (
      <Navigate to={`${VERIFY_IDENTITY_ERROR_PATH}?${search}`} replace={true} />
    );
  }

  if (mutation.isLoading) {
    return <SpinnerFullBackGround />;
  }

  if (mutation.isError) {
    const parsedError: ParsedError = mutation.error;
    const search = `code=${encodeURIComponent(
      parsedError.errorStringCode ?? '',
    )}`;
    return (
      <Navigate replace={true} to={`${VERIFY_IDENTITY_ERROR_PATH}?${search}`} />
    );
  }

  if (mutation.isSuccess) {
    if (mutation.data.hsUserId) {
      return <Navigate to={EXISTING_ACCOUNT} replace={true} />;
    }

    const search = mutation.data.hsUserId ? 'existing=true' : '';
    return <Navigate to={`${LOADING_PATH}?${search}`} />;
  }

  return <SpinnerFullBackGround />;
};
