import { parseTrackableUrl } from '@headspace/web-analytics';
import { AuthEnvironment } from '@headspace/web-auth';
import { get } from 'js-cookie';
import { StatsigOptions, StatsigUser } from '@statsig/react-bindings';
import { Store } from 'redux';
import { StatsigClient } from '@statsig/js-client';
import env from '../config/env';
import { RootState } from '../state/types';

interface StatsigConfig {
  options: StatsigOptions;
  sdkKey: string;
  user: StatsigUser;
}

export const statsigOptions = {
  environment: {
    tier:
      env.NODE_ENV === AuthEnvironment.INTEGRATION
        ? AuthEnvironment.DEVELOPMENT
        : (env.NODE_ENV as Exclude<
            AuthEnvironment,
            AuthEnvironment.INTEGRATION
          >),
  },
};

export const createStatsigConfig = (store: Store): StatsigConfig => {
  const state: RootState = store.getState();

  const { statsigStableId } = parseTrackableUrl(window?.location.href);

  const overrideStableID = statsigStableId ?? get('statsigStableId');

  return {
    options: statsigOptions,
    sdkKey: env.STATSIG_SDK_KEY,
    user: {
      custom: {
        StableID: overrideStableID,
        adminEmail: validateAdminEmail(state.user.info.email ?? null)
          ? state.user.info.email
          : getEmailFromLocalStorage(),
      },
      customIDs: {
        stableID: overrideStableID,
      },
    },
  };
};

// Checking for headspace and ginger emails
export const validateAdminEmail = (email: null | string): boolean =>
  email === null ||
  /@headspace\.com/i.test(email) ||
  /@ginger\.io/i.test(email);

export const getEmailFromLocalStorage = () => {
  const emailFromLocalStorage = localStorage.getItem(
    `persist:${window.location.origin}`,
  );

  if (emailFromLocalStorage) {
    const { user } = JSON.parse(emailFromLocalStorage);
    const { info } = JSON.parse(user);

    return info?.email;
  }

  return null;
};
