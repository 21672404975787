import { css } from '@emotion/react';
import React, { ReactNode } from 'react';

import { SpacingInRemBySize } from '@headspace/web-ui-components';
import { TableFilterDropdownProps } from './FullTableWithScroll/types';
import { TableCheckbox } from './FullTableWithScroll/Checkbox';
import { ActionDropdown } from '../dropdowns/ActionDropdown';

interface FilterDropdownProps<T> {
  filterProps: TableFilterDropdownProps;
  filterSelectedValues: T[];
  setFilterSelectedValues: React.Dispatch<React.SetStateAction<T[]>>;
  testId?: string;
}

const itemCss = css({
  '> div': { marginRight: SpacingInRemBySize['2XS'] },
  display: 'inline-flex',
  alignItems: 'center',
});

export const FilterDropdown = <T extends string>({
  filterProps,
  filterSelectedValues,
  setFilterSelectedValues,
  testId,
}: FilterDropdownProps<T>): ReactNode => {
  const { columnKey, coverText, options } = filterProps;

  const items = options.map(({ title, value }) => {
    const unselectIndex = filterSelectedValues.indexOf(value);
    return {
      action: () =>
        setFilterSelectedValues(
          unselectIndex >= 0
            ? filterSelectedValues
                .slice(0, unselectIndex)
                .concat(filterSelectedValues.slice(unselectIndex + 1))
            : filterSelectedValues.concat(value),
        ),
      display: (
        <span css={itemCss}>
          <TableCheckbox id={value} selected={unselectIndex >= 0} />
          &nbsp;
          {title ?? value}
        </span>
      ),
    };
  });

  const minContentWidth = options.reduce((acc, opt) => {
    const optLength = opt.title ? opt.title.length : opt.value.length;
    return acc > optLength ? acc : optLength;
  }, coverText.length);

  return (
    <ActionDropdown
      key={columnKey}
      position="left"
      text={coverText}
      minContentWidth={`${minContentWidth}em`}
      items={items}
      testId={testId}
    />
  );
};
