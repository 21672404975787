import { FC, useCallback, useEffect, useState, ChangeEvent } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslate } from '../../../../../../../hooks/useTranslate';
import { Input } from '../../../../../../../shared-components/Input';
import { FieldLabel } from '../../../../../../../shared-components/text';
import { updateImplicitState } from '../../../../../../../state/actions/organizationActions';
import { handleParentOrgIdInput } from '../../../../../../../state/actions/organizationActions/handleParentOrgIdInput';
import {
  fetchOrganizationsAction,
  searchOrganizationsAction,
} from '../../../../../../../state/actions/organizationsActions';
import {
  getOrganizations,
  getOrgManagement,
} from '../../../../../../../state/selectors/organizationSelectors';
import { labelStyle } from '../../styles';
import { Modal } from '../../../../../../../shared-components/modal';
import { ParentOrgDisplayName } from './ParentOrgDisplayName';
import { orgHasFutureGingerToFusionUpgrade } from '../../../../../../../utils/validations';
import { Organization } from '../../../../../../../types/organization';

export interface ParentOrgInputProps {
  editMode: boolean;
  optional?: boolean;
  disabled?: boolean;
  editingExistingOrg?: boolean;
  confirmationModal?: boolean;
}

export const ParentOrgInput: FC<ParentOrgInputProps> = ({
  editMode,
  optional = false,
  disabled = false,
  editingExistingOrg = false,
  confirmationModal = false,
}) => {
  const { translate } = useTranslate();
  const dispatch = useDispatch();
  const {
    searchTerm,
    isFetching,
    OrganizationList: organizations = [],
  } = useSelector(getOrganizations);
  const { restrictBy, parentOrg, parentOrgIdError } = useSelector(
    getOrgManagement,
  );
  const [showModal, setShowModal] = useState(false);
  const [selectedParent, setSelectedParent] = useState<Organization | null>(
    null,
  );

  useEffect(() => {
    if (editMode) {
      dispatch(
        fetchOrganizationsAction({
          query: { search: searchTerm },
          refresh: true,
        }),
      );
    }
  }, [dispatch, searchTerm, editMode]);

  const handleSearch = useCallback(
    (event) => {
      const value = event?.target?.value || '';

      dispatch(searchOrganizationsAction(value));
    },
    [dispatch],
  );

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      if (confirmationModal) {
        setShowModal(true);

        const parent = organizations.filter((org) => {
          return org.id === +event.target.value;
        })[0];
        setSelectedParent(parent);
      } else {
        dispatch(
          handleParentOrgIdInput(
            event.target.value,
            restrictBy,
            editingExistingOrg,
          ),
        );
        handleSearch('');
      }
    },
    [dispatch, handleSearch, organizations, parentOrg],
  );

  const handleError = useCallback(
    (event: ExtendedEvent) => dispatch(updateImplicitState(event)),
    [dispatch],
  );

  // clear the search term when the component loads
  useEffect(() => {
    if (editMode) {
      handleSearch('');
    }
  }, [handleSearch, editMode]);

  const label = optional
    ? `${translate('organizationSettings.orgHierarchy.parentOrg')} ${translate(
        'organizationSettings.optional',
      )}`
    : translate('organizationSettings.orgHierarchy.parentOrg');

  const orgOptions: Option[] = (organizations ?? [])
    // Filter out any orgs that have a future ginger -> fusion contract to prevent
    // manual creation of child orgs since child orgs should be imported directly
    .filter((org) => !orgHasFutureGingerToFusionUpgrade(org))
    .map((org) => {
      return { title: org.name, value: org.id };
    });

  if (searchTerm === '' && !editingExistingOrg) {
    orgOptions.unshift({ title: '<No Parent>', value: '' });
  }
  const selectedOrgName = parentOrg?.name ?? '';

  return (
    <div>
      {editMode ? (
        <Input
          name="parentOrgId"
          type="select"
          label={label}
          isLoading={isFetching}
          isSearchable={true}
          searchValue={searchTerm}
          handleSearch={handleSearch}
          searchPlaceholder={translate('organizations.searchOrganizations')}
          noResultsFound={translate('noResultsFound')}
          options={orgOptions}
          onChange={handleChange}
          onBlur={handleError}
          value={selectedOrgName}
          hasError={parentOrgIdError.error}
          errorMessage={parentOrgIdError.message}
          disabled={disabled}
          data-testid="parent-org-input"
        />
      ) : (
        <>
          <div css={labelStyle} data-testid="parent-org-input-header">
            <FieldLabel>{label}</FieldLabel>
          </div>
          <ParentOrgDisplayName />
        </>
      )}
      <Modal
        isOpen={showModal}
        title="Setting a new parent organization?"
        message={`You are about to set ${selectedParent?.name} as the parent which will change this organizations’s contract details.`}
        handleConfirm={() => {
          const parentOrgId = selectedParent?.id
            ? selectedParent?.id.toString()
            : '';

          dispatch(
            handleParentOrgIdInput(parentOrgId, restrictBy, editingExistingOrg),
          );

          setShowModal(false);
          handleSearch('');
          setSelectedParent(null);
        }}
        handleCancel={() => {
          setShowModal(false);
          setSelectedParent(null);
          handleSearch('');
        }}
      />
    </div>
  );
};
